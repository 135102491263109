import type { SbBlokData } from "@storyblok/react";

import DesignerGrid from "@/components/Designer/DesignerGrid";
import { useStories } from "@/lib/centra/useStories/useStories";

import type { ArticleCardBlok } from "../ArticleCard/ArticleCard";

export interface TextBlok extends SbBlokData {
  text: SbBlokData[];
}
export interface TextHeaderProps {
  blok: TextBlok;
}

const Designers = () => {
  const { stories } = useStories<ArticleCardBlok>("designer");
  if (!stories) {
    return null;
  }
  return <DesignerGrid designers={stories} />;
};

export default Designers;
