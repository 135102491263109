import type { RichTextBlok } from "@/bloks/RichText/RichText";
import type { Subcategory } from "@/pages/api/storefront/subcategories";

import UI from "./UI";
/*
const throwCategorySort = (values: Subcategory[]): Subcategory[] => {
  const sortOrder: string[] = ["Large", "Store", "Half", "Halv", "Baby"];

  const getSortOrder = (label: string) => {
    const sortOrderItem = sortOrder.find((item) => label.includes(item));
    if (!sortOrderItem) return -1;
    const index = sortOrder.indexOf(sortOrderItem);
    return index === -1 ? sortOrder.length : index;
  };

  values.sort((a, b) => {
    if (getSortOrder(a.label) < getSortOrder(b.label)) {
      return -1;
    }
    if (getSortOrder(a.label) > getSortOrder(b.label)) {
      return 1;
    }

    return 0;
  });

  return values;
};
*/

type PageIntroProps = {
  content?: RichTextBlok;
  initialSubcategories: Subcategory[];
  filter?: {
    options: string[];
    active: string;
    callback: (value: string) => void;
  };
};

const PageIntro = ({
  content,
  initialSubcategories,
  filter,
}: PageIntroProps) => {
  //const router = useRouter();
  //const categoryArray = router.query.category as string[] | undefined;
  /*const categories = useSubcategories(
    initialSubcategories,
    categoryArray ? `/category/${categoryArray.join("/")}` : undefined
  );*/

  //const sortedCategories = throwCategorySort(categories);
  return (
    <UI content={content} categories={initialSubcategories} filter={filter} />
  );
};

export default PageIntro;
