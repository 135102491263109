import { useRef, useState } from "react";

import useDelayUnmount from "@/lib/hooks/useDelayUnmount";

import UI from "./UI";

interface AccordionProps {
  children: React.ReactNode;
  title: string;
}

const useAccordion = () => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const shouldRender = useDelayUnmount(open);
  return { isOpen: open, toggle, shouldRender };
};

export type AccordionHook = ReturnType<typeof useAccordion>;

const Accordion = ({ children, title }: AccordionProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UI title={title} accordionRef={ref} isOpen={isOpen} setIsOpen={setIsOpen}>
      {children}
    </UI>
  );
};

export default Accordion;
