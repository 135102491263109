import { useQuery } from "@tanstack/react-query";

import getStory from "./getStory";

const useStaticStory = (props: {
  slug: string;
  folder?: string | undefined;
  preview?: boolean | undefined;
  previewData?: any;
  locale?: string;
}) => {
  const { slug, folder, preview = false, previewData, locale = "en" } = props;

  const { data, isLoading } = useQuery(
    [slug, locale, preview],
    () => getStory({ slug, folder, preview, locale }),
    {
      staleTime: Infinity,
    }
  );
  if (previewData && previewData[slug]) {
    return { blok: previewData[slug] };
  }
  const blok = isLoading ? null : data?.content;
  return { data, isLoading, blok };
};

export default useStaticStory;
