import { getStoryblokApi } from "@storyblok/react";

export type StoryPage<T> = {
  data: {
    stories: T[];
  };
};

export type GetStories<T> = StoryPage<T> & { totalStories: number };

export type GetAllStories<T> = { stories: T[] };

export const getStories = async <T>(
  storyType: string,
  { pageParams: { pageParam = 1 } },
  preview?: boolean,
  locale?: string
): Promise<GetStories<T>> => {
  const storyBlokApi = getStoryblokApi();

  const folder = locale ? `${storyType}/` : storyType;

  const { data, total } = await storyBlokApi.get("cdn/stories/", {
    page: pageParam,
    starts_with: folder,
    version: preview ? "draft" : "published",
    sort_by: "first_published_at:desc",
    language: locale,
    per_page: 100,
  });

  if (!data) {
    throw new Error("No articles found");
  }

  return { data, totalStories: total };
};

export const getAllStories = async <T>(
  storyType: string,
  preview: boolean = false
): Promise<StoryPage<T>["data"]> => {
  const perPage = 100;
  const { data, totalStories } = await getStories<T>(
    storyType,
    { pageParams: { pageParam: 1 } },
    preview
  );

  if (totalStories - perPage < 0) {
    return data;
  }

  const totalPages = Math.ceil(totalStories / perPage);
  const pages = Array.from(Array(totalPages - 1).keys());
  const storyPages = await Promise.all(
    pages
      .map((page) => page + 2)
      .map((page) =>
        getStories<T>(storyType, { pageParams: { pageParam: page } }, preview)
      )
  );

  const allData = {
    stories: storyPages.reduce(
      (prev, curr) => [...prev, ...curr.data.stories],
      data.stories
    ),
  };

  return allData;
};
