import type { CentraVoucher } from "../centra/selection/selectionFormatter";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const pageview = (url: string) => {
  window.dataLayer.push({
    event: "pageview",
    url: url,
  });
};

interface GTagEvent {
  action: string;
  category: string;
  label: string;
  value: number;
}

export const event = ({ action, category, label, value }: GTagEvent) => {
  window.dataLayer.push({
    event: action,
    event_category: category,
    event_label: label,
    revenue: value,
  });
};
export interface GTagItem {
  item_id: string; // SKU
  item_name: string; // Product Name
  discount?: number;
  item_category: string; // Top Category /women
  item_category2?: string; // Sub Category /women/!dresses!
  item_category3?: string;
  item_variant: string; // Variant name
  price: number; // Price
  quantity?: number; // How many
}
interface EventCartProps {
  currency: string;
  value?: number;
  items: GTagItem[] | [];
}

export const eventAddToCart = ({ currency, value, items }: EventCartProps) => {
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency,
      value,
      items,
    },
  });
};

export const eventProductDetail = ({ currency, items }: EventCartProps) => {
  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      currency,

      items,
    },
  });
};

export const eventViewCart = ({ currency, value, items }: EventCartProps) => {
  window.dataLayer.push({
    event: "view_cart",
    ecommerce: {
      currency,
      value,
      items,
    },
  });
};

export const eventBeginCheckout = ({
  currency,
  value,
  items,
}: EventCartProps) => {
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      currency,
      value,
      items,
    },
  });
};

interface EventPurchaseProps {
  transaction_id: string;
  tax: number;
  shipping: number;
  coupon: string | CentraVoucher;
  currency: string;
  value: number;
  items: GTagItem[];
}

export const eventPurchase = ({
  transaction_id,
  tax,
  shipping,
  coupon,
  currency,
  value,
  items,
}: EventPurchaseProps) => {
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      affiliation: "Online Store",
      transaction_id: transaction_id,
      tax,
      shipping,
      coupon: coupon,
      currency: currency,
      value: value,
      items,
    },
  });
};
