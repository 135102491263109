import styles from "./EmptyCard.module.scss";

const EmptyCard = ({ className }: { className: string }) => {
  return (
    <article className={`${styles.wrapper} ${className ? className : ""}`}>
      <a className={styles.link}>
        <figure className={`${styles.imageWrapper} ${styles.shimmerBG}`} />
        <div className={`${styles.detailsWrapper} ${styles.shimmerBG}`}>
          <h4 className={styles.title}>&nbsp;</h4>
          <div className={styles.priceVariantContainer}>
            <p className={styles.price}>&nbsp;</p>
          </div>
        </div>
      </a>
    </article>
  );
};

export default EmptyCard;
