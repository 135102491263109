import type { CentraMediaType } from "./types/product";

const priceListId = 28;
const taggedImageSize = {
  width: 300,
  height: 110,
};
const defaultPackshotCount = 3;
const productCardImageSize: CentraMediaType = "thumb-4x5";
const productsPerPage = 16;
const animationDurationContent = 0.8;
const animationDurationModal = 0.3;
const animationDelay = 0.2;
const observerOptions = {
  threshold: 0.2,
  rootMargin: "0px",
  triggerOnce: true,
};

type WishlistType = "local" | "centra";
const wishlist: WishlistType = "local";

type VariantsPerDisplay = "single" | "multi";
const variantsPerDisplay: VariantsPerDisplay = "multi";

const config = {
  priceListId,
  taggedImageSize,
  defaultPackshotCount,
  productCardImageSize,
  productsPerPage,
  animationDurationContent,
  animationDurationModal,
  animationDelay,
  observerOptions,
  wishlist: wishlist as WishlistType,
  variantsPerDisplay: variantsPerDisplay as VariantsPerDisplay,
};

export default config;
