import type { SbBlokData } from "@storyblok/react";

import getNumberStyles from "@/lib/utils/getNumberStyles";

import type { ArticleCardBlok } from "../ArticleCard/ArticleCard";
import type { StoryblokLinkBlok } from "../StoryblokLink/StoryblokLink";
import styles from "./curatedArticles.module.scss";
import UI from "./UI";

export interface CuratedArticlesProps {
  blok: CuratedArticlesBlok;
}

export interface CuratedArticlesBlok extends SbBlokData {
  title: string;
  link: StoryblokLinkBlok[];
  articles: {
    content: ArticleCardBlok;
    uuid: string;
    full_slug: string;
  }[];
}

const CuratedArticles = ({ blok }: CuratedArticlesProps) => {
  const numberStyles = getNumberStyles(blok.articles.length, styles);
  const link = blok.link?.[0];
  return <UI blok={blok} numberStyles={numberStyles} link={link} />;
};

export default CuratedArticles;
