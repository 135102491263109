import type {
  ErrorLevel,
  FormattedObject,
  FormattingError,
  ObjectToFormat,
  PreformattedObject,
} from "@/types/utility";

export const atomicHandler =
  <D, R, Rd>(
    atomicFunction: (arg0: D) => R | Rd,
    errorLevel: ErrorLevel,
    defaultReturn?: Rd
  ): ((arg0: D) => { data?: R | Rd; error?: FormattingError }) =>
  (data) => {
    try {
      return {
        data: atomicFunction(data),
      };
    } catch (e: any) {
      const obj = {
        data: defaultReturn,
        error: {
          level: errorLevel,
          message: e.message,
        },
      };
      if (errorLevel < 2) {
        return obj;
      }
      delete obj.data;
      return obj;
    }
  };

export const atomizeData = <T>(data: T): { data: T } => ({ data });

export const dataErrorBuilder = <T extends PreformattedObject>(
  obj: ObjectToFormat<T>
): FormattedObject<T> => {
  return Object.entries(obj).reduce(
    (prev: FormattedObject<T>, curr: [keyof T, any]): FormattedObject<T> => {
      const key = curr[0];
      const data = "data" in curr[1] ? curr[1].data : null;
      const error = "error" in curr[1] ? curr[1].error : null;
      if (error) {
        // eslint-disable-next-line no-param-reassign
        prev.error[key] = error;
        if (error.level > 1) return prev;
      }

      if (data) {
        // eslint-disable-next-line no-param-reassign
        prev.data[key] = data;
      }
      return prev;
    },
    { data: {}, error: {} }
  );
};
