import { useEffect, useState } from "react";

import useDevice from "@/lib/hooks/useDevice";
import type { IProductCard } from "@/types/product";

import UI from "./UI";

const buildProductCard = (
  product: IProductCard,
  activeId: string | undefined,
  setter: (id: string) => void
) => {
  const productCard = activeId
    ? product.variants?.variants[activeId]
    : undefined;
  if (productCard?.info?.swatches) {
    productCard.info.swatches = productCard.info.swatches.map((swatch) => ({
      ...swatch,
      setActive: () => {
        setter(swatch.id);
      },
      active: activeId === swatch.id,
    }));
  }
  return productCard;
};

const FullProductCard = ({
  product,
  className,
}: {
  product: IProductCard;
  className?: string;
}) => {
  const defaultId = product?.variants?.default;
  const [variantId, setVariantId] = useState(defaultId);

  const { desktop } = useDevice();

  let productCard = buildProductCard(product, variantId, setVariantId);

  useEffect(() => {
    productCard = buildProductCard(product, variantId, setVariantId);
  }, [variantId]);

  return <UI className={className} {...productCard} desktop={desktop} />;
};

export default FullProductCard;
