import type {
  IResolvedStoryblokImage,
  IStoryblokImage,
} from "@/types/storyblok";

import useDevice from "../../hooks/useDevice";
import { isUnresolved } from "../isUnresolved";
import { getStoryblokAlt, getStoryblokFile } from "./mediaFormatters";

const getMediaType = (media: IResolvedStoryblokImage) => {
  const isVideo = media.is_external_url;
  const isImage = !media.is_external_url;
  return {
    isVideo,
    isImage,
  };
};

interface IStoryblokMediaProps {
  desktop: IStoryblokImage;
  mobile: IStoryblokImage;
  id?: string;
}

const useStoryblokMedia = ({ desktop, mobile, id }: IStoryblokMediaProps) => {
  const device = useDevice();

  if (isUnresolved(desktop) || isUnresolved(mobile))
    return {
      isVideo: false,
      isImage: false,
      alt: "",
      file: "",
    };
  if (!desktop?.filename && !mobile?.filename)
    return {
      isVideo: false,
      isImage: false,
      alt: "",
      file: "",
    };

  mobile = mobile?.filename ? mobile : desktop;
  const media = device.mobile ? mobile : (desktop as IResolvedStoryblokImage);

  const file = getStoryblokFile(media);
  const alt = getStoryblokAlt(media);

  const { isVideo, isImage } = getMediaType(media);

  return {
    file,
    alt,
    id,
    isImage,
    isVideo,
  };
};

export default useStoryblokMedia;
