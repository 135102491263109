import { useRouter } from "next/router";
import { useEffect } from "react";

const useGetQueryState = (query: string, setState: (state: any) => void) => {
  const router = useRouter();
  useEffect(() => {
    if (typeof router.query[query] === "string") {
      setState([router.query[query]]);
    }
    if (Array.isArray(router.query[query])) {
      setState(router.query[query]);
    }
  }, [router.query[query]]);
};

export const useGetQuerySearch = (
  query: string,
  setState: (state: any) => void
) => {
  const router = useRouter();
  const queryState = router.query[query];
  useEffect(() => {
    if (typeof queryState === "string") {
      setState(queryState);
    }
    if (Array.isArray(queryState)) {
      setState(queryState[0] || "");
    }
  }, [router.query[query]]);
};

export default useGetQueryState;

export const useSetQueryState = () => {
  const router = useRouter();

  const setQueryState = (queryKey: string, newValues: string[]) => {
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [queryKey]: newValues,
        },
      },
      undefined,
      { shallow: true }
    );
  };
  return setQueryState;
};
