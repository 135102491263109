import type { SbBlokData } from "@storyblok/react";

import getLink from "@/lib/storyblok/getLink";
import type { IStoryblokLink } from "@/types/storyblok";

import UI from "./UI";

export interface StoryblokLinkBlok extends SbBlokData {
  link: IStoryblokLink;
  text: string;
}

export interface StoryblokLinkProps {
  blok: StoryblokLinkBlok;
  className?: string;
}

const StoryblokLink = ({ blok, className }: StoryblokLinkProps) => {
  const link = getLink(blok.link);
  const { text } = blok;
  return <UI text={text} link={link} className={className} />;
};

export default StoryblokLink;
