import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { useState } from "react";

const locales = ["en", "no"] as const;

type Locale = (typeof locales)[number];

const useLanguageCurrency = () => {
  const router = useRouter();

  const [isOpen, setOpen] = useState(false);

  const locale = router.locale;

  const { query } = router;
  // change just the locale and maintain all other route information including href's query

  const setLocale = (nextLocale: Locale) => {
    Cookies.set("NEXT_LOCALE", nextLocale, {
      expires: 365,
    });

    router.push({ pathname: "/", query }, "/", {
      locale: nextLocale,
    });
  };

  const languages = {
    en: {
      name: "English",
      isSelected: locale === "en",
      select: () => {
        setLocale("en");
      },
      locale: "en",
    },

    no: {
      name: "Norsk",
      isSelected: locale === "no",
      select: () => {
        setLocale("no");
      },
      locale: "no",
    },
  };

  const languageArray = Object.keys(languages).map((key) => {
    return {
      ...languages[key as Locale],
      locale: key as Locale,
    };
  });

  const selectedLanguage =
    languages[(router.locale as keyof typeof languages) || "en"];

  const toggleOpenLang = () => {
    setOpen(!isOpen);
  };

  return {
    setLocale,
    locale: router.locale ?? "en",
    languages: languageArray,
    selectedLanguage,
    openLang: isOpen,

    toggleOpenLang,
  };
};

export default useLanguageCurrency;
