import useDevice from "@/lib/hooks/useDevice";
import type { ISubMenu } from "@/types/storyblok";

import UI from "./UI";

export interface SubMenuProps {
  blok: ISubMenu;
}

const SubMenu = ({ blok }: SubMenuProps) => {
  const device = useDevice();
  return <UI blok={blok} device={device} />;
};

export default SubMenu;
