import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import type { ISortOption, ISortOrderValue } from "@/types/centra";

export const useSortOrder = () => {
  const [sortOrder, setSortOrder] = useState<ISortOrderValue[]>([]);
  const router = useRouter();
  const modifySortOrder = (value: ISortOrderValue[]) => {
    setSortOrder(value);
    if (value[0]) {
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            sortField: value[0].field,
            sortOrder: value[0].order,
          },
        },
        undefined,
        { shallow: true }
      );
    } else {
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            sortField: [],
            sortOrder: [],
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };
  const options: ISortOption[] = [
    {
      key: "default",
      value: [],
      setFunction: () => modifySortOrder([]),
      active: _.isEqual(sortOrder, []),
    },
    {
      key: "newest",
      value: [{ field: "createdAt", order: "desc" }],
      setFunction: () =>
        modifySortOrder([{ field: "createdAt", order: "desc" }]),
      active: _.isEqual(sortOrder, [{ field: "createdAt", order: "desc" }]),
    },
    {
      key: "lowestPrice",
      value: [{ field: "priceAsNumber", order: "asc" }],
      setFunction: () =>
        modifySortOrder([{ field: "priceAsNumber", order: "asc" }]),
      active: _.isEqual(sortOrder, [{ field: "priceAsNumber", order: "asc" }]),
    },
    {
      key: "highestPrice",
      value: [{ field: "priceAsNumber", order: "desc" }],
      setFunction: () =>
        modifySortOrder([{ field: "priceAsNumber", order: "desc" }]),
      active: _.isEqual(sortOrder, [{ field: "priceAsNumber", order: "desc" }]),
    },
    {
      key: "nameAsc",
      value: [{ field: "uri", order: "asc" }],
      setFunction: () => modifySortOrder([{ field: "uri", order: "asc" }]),
      active: _.isEqual(sortOrder, [{ field: "uri", order: "asc" }]),
    },
    {
      key: "nameDesc",
      value: [{ field: "uri", order: "desc" }],
      setFunction: () => modifySortOrder([{ field: "uri", order: "desc" }]),
      active: _.isEqual(sortOrder, [{ field: "uri", order: "desc" }]),
    },
  ];
  useEffect(() => {
    const sortOrder = router.query.sortOrder as string;
    const sortField = router.query.sortField as string;
    const sort = options.find(
      (sort) =>
        sort.value &&
        sort?.value[0]?.field === sortField &&
        sort?.value[0]?.order === sortOrder
    );

    setSortOrder(sort?.value || []);
  }, [router.query]);

  const active = options.find((option) => option.active);

  return {
    setSortOrder,
    sortOrder,
    options,
    active,
  };
};
