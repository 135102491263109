import type { SbBlokData } from "@storyblok/react";
import { useEffect, useState } from "react";

import PageIntro from "@/components/PageIntro";
import RetailersList from "@/components/RetailersList";
import { useRehydratedAllStories } from "@/lib/centra/useStories/useRehydratedStories";
import useHasHydrated from "@/lib/hooks/useHasHydrated";
import useLocation from "@/lib/hooks/useLocation";

export interface TextBlok extends SbBlokData {
  text: SbBlokData[];
}
export interface TextHeaderProps {
  blok: TextBlok;
}

const Retailers = ({ blok }: { blok: any }) => {
  const { options, initialRetailers } = blok;
  const { stories: retailers } = useRehydratedAllStories(
    "retailer",
    initialRetailers,
    false
  );

  const filterField = "country";

  const { country: location } = useLocation() as {
    country: string | undefined;
  };

  const validLocation =
    location &&
    options?.find(
      (value: any) => value.toLowerCase() === location.toLowerCase()
    )
      ? location
      : undefined;
  const hydrated = useHasHydrated();

  const [activeFilter, setActiveFilter] = useState({
    key: filterField,
    value: "all",
  });

  useEffect(() => {
    if (validLocation && hydrated) {
      setActiveFilter({ key: filterField, value: validLocation });
    }
  }, [validLocation, hydrated]);
  const filter = {
    options,
    active: activeFilter.value,
    callback: (value: string) => {
      setActiveFilter({
        key: filterField,
        value: value,
      });
    },
  };
  return (
    <>
      <PageIntro initialSubcategories={[]} filter={filter} />
      <RetailersList content={retailers} filter={activeFilter} />
    </>
  );
};

export default Retailers;
