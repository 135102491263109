import UI from "./UI";

interface FadeInProps {
  children: React.ReactNode;
  isVisible: boolean | undefined;
  shouldRender: boolean;
  duration: number;
  delay: number;
}

const FadeInLeft = ({
  children,
  isVisible,
  shouldRender,
  duration,
  delay,
}: FadeInProps) => {
  return (
    <UI
      shouldRender={shouldRender}
      isVisible={isVisible}
      duration={duration}
      delay={delay}
    >
      {shouldRender && children}
    </UI>
  );
};

export default FadeInLeft;
