import type { StateCreator } from "zustand";

import type { IState } from "../store";

export interface IEnvironmentState {
  preview: boolean;
  environment: "development" | "production" | "test" | "preview";
  fakeProduction: boolean;
  fakeProductionToggle: () => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  isDesktop: boolean;
  setIsDesktop: (isDesktop: boolean) => void;
  hasWindow: boolean;
  setHasWindow: (hasWindow: boolean) => void;
  setPreview: (preview: boolean) => void;
  setMainRef: (mainRef: HTMLDivElement | null) => void;
  mainRef: HTMLDivElement | null;
}

const createEnvironmentSlice: StateCreator<
  IState,
  [],
  [],
  IEnvironmentState
> = (set, _get) => ({
  environment: process.env.NODE_ENV,
  preview: false,
  fakeProduction: false,
  fakeProductionToggle: () =>
    set((state) => ({
      fakeProduction: !state.fakeProduction,
      environment: !state.fakeProduction ? "production" : process.env.NODE_ENV,
    })),
  isMobile: false,
  setIsMobile: (isMobile) => set(() => ({ isMobile })),
  isDesktop: false,
  setIsDesktop: (isDesktop) => set(() => ({ isDesktop })),
  hasWindow: false,
  setHasWindow: (hasWindow) => set(() => ({ hasWindow })),
  setMainRef: (mainRef) => set(() => ({ mainRef })),
  mainRef: null,
  setPreview: (preview) =>
    set(() => ({
      preview,
      environment: preview ? "preview" : process.env.NODE_ENV,
    })),
});

export default createEnvironmentSlice;
