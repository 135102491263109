import type { SbBlokData } from "@storyblok/react";
import { useEffect, useState } from "react";

import useDelayUnmount from "@/lib/hooks/useDelayUnmount";

import type { AccordionBlok } from "./AccordionBlok/Accordion";
import UI from "./UI";

export interface AccordionContainerBlok extends SbBlokData {
  title: string;
  accordions: AccordionBlok[];
  backgroundColor: {
    color: string;
  };
  position: "left" | "center" | "right";
}

export interface AccordionContainerProps {
  blok: AccordionContainerBlok;
}

const AccordionContainer = ({ blok }: AccordionContainerProps) => {
  const [showAll, setShowAll] = useState(false);
  const [accordions, setAccordions] = useState<AccordionBlok[]>(
    blok.accordions
  );

  useEffect(() => {
    if (blok?.accordions?.length <= 5) setShowAll(true);
    setAccordions(showAll ? blok.accordions : blok.accordions.slice(0, 5));
  }, [showAll, blok]);

  const toggle = () => {
    setShowAll(!showAll);
  };
  const shouldRenderButton = useDelayUnmount(!showAll);
  const shouldRenderAll = useDelayUnmount(showAll);
  const accordionState = {
    showAll,
    shouldRenderButton,
    shouldRenderAll,
    toggle,
  };

  return (
    <UI blok={blok} accordionState={accordionState} accordions={accordions} />
  );
};

export default AccordionContainer;
