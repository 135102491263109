import type { ICentraFilterGroup } from "@/types/centra";

import type { IFilterValues } from "./filterHooks/useFilterValue";

export interface IFilterObject {
  active: boolean;
  available: boolean;
  value: string;
  label: string;
  filterCount: number;
  toggle: () => void;
  colorCode?: string;
  colorImage?: string;
}

const filterFormatter = (
  centraFilters: ICentraFilterGroup[],
  stateFilters: IFilterValues[] | undefined
) => {
  if (!stateFilters) {
    return [];
  }

  return stateFilters.map((stateFilter) => {
    const allFilters = centraFilters.find(
      (centraFilter) => centraFilter.field === stateFilter.field
    );

    if (stateFilter.callback && allFilters) {
      allFilters.values = stateFilter.callback(allFilters.values);
    }

    const FinishedFilters = allFilters?.values.map((centraFilter) => {
      const { value, filterCount } = centraFilter;
      const active = !!stateFilter.values?.includes(value);
      const available = filterCount > 0;
      const colorCode: string = centraFilter.data.code
        ? centraFilter.data.code
        : null;
      const colorImage: string = centraFilter.data.image?.url
        ? centraFilter.data.image.url
        : null;
      const label: string = stateFilter.labelField
        ? centraFilter.data[stateFilter.labelField]
        : value;

      const filterObject: IFilterObject = {
        active,
        available,
        value,
        filterCount,
        label,
        toggle:
          available || active
            ? () => stateFilter.toggle(centraFilter.value)
            : () => {},
      };

      if (colorCode) filterObject.colorCode = colorCode;
      if (colorImage) filterObject.colorImage = colorImage;

      return filterObject;
    });

    return {
      name: stateFilter.name,
      filters: stateFilter.required || !FinishedFilters ? [] : FinishedFilters,
    };
  });
};

export default filterFormatter;
