import styles from "./icon.module.scss";

type Props = {
  menuToggle: () => void;
  menuOpen: boolean;
};

const Icon: React.FC<Props> = ({ menuToggle, menuOpen }) => {
  return (
    <button
      className={`${styles.icon} ${menuOpen ? styles.active : ""}`}
      onClick={menuToggle}
    >
      <div className={styles.column}></div>
      <div className={styles.column}></div>
      <div className={styles.column}></div>
    </button>
  );
};

export default Icon;
