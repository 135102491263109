import { storyblokEditable } from "@storyblok/react";

import StoryblokImage from "@/components/StoryblokMedia/StoryblokImage";
import type { InfoPosition } from "@/lib/hooks/useTagPosition";
import type { TaggedImageArray } from "@/lib/storyblok/useTaggedImage";

import Tag from "./Tag";
import type { TaggedImageBlok } from "./TaggedImage";
import styles from "./taggedImage.module.scss";

const UI = ({
  blok,
  tags,
  containerRef,
  infoPosition,
}: {
  tags: TaggedImageArray;
  blok: TaggedImageBlok;
  containerRef: React.RefObject<HTMLDivElement>;
  infoPosition: InfoPosition;
}) => {
  if (!tags) return null;

  return (
    <div
      ref={containerRef}
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      {tags &&
        tags.length > 0 &&
        tags?.map((tag, i) => (
          <Tag
            key={`tag-${i}-${tag.id}`}
            tag={tag}
            infoPosition={infoPosition}
          />
        ))}

      {blok.products.media && <StoryblokImage media={blok.products.media} />}
    </div>
  );
};

export default UI;
