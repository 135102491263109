import { storyblokEditable } from "@storyblok/react";

import type { NavigationBlok } from "@/types/storyblok";

import StoryblokMenu from "../StoryblokMenu";
import styles from "./primaryNavigation.module.scss";

export interface UIProps {
  blok: NavigationBlok;
}

const UI = ({ blok }: UIProps) => {
  if (!blok) return null;

  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      {blok?.menus?.map((menu) => (
        <StoryblokMenu
          blok={menu}
          key={menu?._uid}
          {...storyblokEditable(menu)}
        />
      ))}
    </div>
  );
};

export default UI;
