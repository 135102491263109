import type { SbBlokData } from "@storyblok/react";

import getLink from "@/lib/storyblok/getLink";
import useResolvedProductCards from "@/lib/storyblok/useResolvedProductCard";

import type { StoryblokLinkBlok } from "../StoryblokLink/StoryblokLink";
import UI from "./UI";

export interface FeaturedProductsBlok extends SbBlokData {
  products: number[];
  title: string;
  bigCardsOnMobile: boolean;
  link: StoryblokLinkBlok[];
}

export interface FeaturedProductsProps {
  blok: FeaturedProductsBlok;
}

const FeaturedProducts = ({ blok }: FeaturedProductsProps) => {
  const products =
    useResolvedProductCards(blok?.products || [], "single", blok?._uid!) || [];
  const link = getLink(blok?.link[0]?.link);

  return <UI products={products} blok={blok} link={link} />;
};

export default FeaturedProducts;
