import type { StateCreator } from "zustand";

import type { PaymentResultType } from "@/lib/giftcard/src/lib/checkout/payment/useAdyenResult";

import type { IState } from "../store";

export type PaymentResultState = {
  paymentResult: PaymentResultType | undefined;
  setPaymentResult: (order?: PaymentResultType) => void;
};

const createPaymentResultSlice: StateCreator<
  IState,
  [],
  [],
  PaymentResultState
> = (set, _get) => ({
  paymentResult: undefined,
  setPaymentResult: (paymentResult) =>
    set((state: IState) => ({ ...state, paymentResult })),
});

export default createPaymentResultSlice;
