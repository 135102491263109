import { useTranslations } from "next-intl";

import Button from "@/components/Button";
import type { ICart } from "@/types/centra";

import styles from "./cartIcon.module.scss";

interface UIProps {
  cart: ICart;
}

const UI = ({ cart }: UIProps) => {
  const t = useTranslations();
  return (
    <Button className={styles.button} onClick={cart.toggle}>
      {!cart.isEmpty && (
        <span className={styles.cartQuantity}>({cart.quantity || 0}) </span>
      )}
      {t("cart")}
    </Button>
  );
};

export default UI;
