import type { TaggedImageBlok } from "@/bloks/TaggedImage/TaggedImage";
import TaggedImage from "@/bloks/TaggedImage/TaggedImage";
import type { IProductCard } from "@/lib/api/formatting/rorosTweed/productCard";

import ProductCard from "../ProductCard";
import PCSkeleton from "../ProductCard/PCSkeleton";
import styles from "./productGrid.module.scss";

interface UIProps {
  products: IProductCard[] | undefined;
  featured: TaggedImageBlok[];
  mobile: boolean;
  getMore: {
    ref: (node: HTMLElement | null) => void;
    loading: boolean;
  };
}

const getGridStyle = (i: number, mobile: boolean) => {
  let columns = `${i % 2 === 0 ? 7 : 1} / span 6`;
  let rows = `${4 * i + 2} / span 2`;

  if (mobile) {
    columns = "1 / span 4";
    rows = `${i * 16 + 9}`;
  }

  return { gridColumn: columns, gridRow: rows };
};

const UI = ({ products, featured, mobile, getMore }: UIProps) => {
  return (
    <div className={styles.grid}>
      {featured.map((feature, i) => (
        <div
          className={styles.featureContainer}
          key={`featured-tagged-image-${i}`}
          style={getGridStyle(i, mobile)}
        >
          <div className={styles.imageContainer}>
            <div className={styles.imageWrapper}>
              <TaggedImage blok={feature} />
            </div>
          </div>
        </div>
      ))}
      {products ? (
        products?.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))
      ) : (
        <>
          <PCSkeleton />
          <PCSkeleton />
          <PCSkeleton />
          <PCSkeleton />
          <PCSkeleton />
          <PCSkeleton />
        </>
      )}

      <span
        ref={getMore.ref}
        className={`${styles.getMore} ${getMore.loading && styles.loading}`}
      >
        <p> {getMore.loading && "Loading products . . ."}</p>
      </span>
    </div>
  );
};

export default UI;
