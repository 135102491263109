import { useRouter } from "next/router";

import useDevice from "@/lib/hooks/useDevice";
import useStore from "@/lib/stateManagement/store";
import useStaticStory from "@/lib/storyblok/useStaticStory";

import UI from "./UI";

export interface FooterProps {
  previewData?: any;
}

const PrimaryNavigation = ({ previewData }: FooterProps) => {
  const preview = useStore((state) => state.preview);
  const device = useDevice();
  const { locale } = useRouter();
  const { blok } = useStaticStory({
    slug: "footer",
    preview,
    folder: "statics",
    previewData,
    locale,
  });

  return <UI blok={blok} device={device} />;
};

export default PrimaryNavigation;
