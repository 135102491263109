import type { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { useInView } from "react-intersection-observer";

import type { StoryblokLinkBlok } from "@/bloks/StoryblokLink/StoryblokLink";
import StoryblokLink from "@/bloks/StoryblokLink/StoryblokLink";
import type { DeviceType } from "@/lib/hooks/useDevice";
import useFooterVisible from "@/lib/stateManagement/useElementInView";
import LogoSvg from "@/public/svg/Logo.svg";
import type { ISubMenu } from "@/types/storyblok";

import SubMenu from "../../../bloks/Navigation/SubMenu";
import CurrencySelector from "../Snackbar/LanguageCurrencySelector";
import styles from "./footer.module.scss";
interface IFooter extends SbBlokData {
  subMenus: ISubMenu[];
  socialLinks: StoryblokLinkBlok[];
  legalLinks: StoryblokLinkBlok[];
}

export interface UIProps {
  blok: IFooter;
  device: DeviceType;
}

const UI = ({ blok, device }: UIProps) => {
  const { setVisible } = useFooterVisible();
  const { ref } = useInView({
    onChange: (inView) => setVisible(inView),
  });

  if (!blok) return null;

  if (device.mobile) {
    return (
      <div
        ref={ref}
        className={styles.container}
        key={blok?._uid}
        {...storyblokEditable(blok)}
      >
        {blok?.subMenus?.map((subMenu) => (
          <SubMenu
            blok={subMenu}
            key={subMenu?._uid}
            {...storyblokEditable(subMenu)}
          />
        ))}

        <div className={styles.currencySelector}>
          <CurrencySelector />
        </div>
        <div className={styles.subContainer}>
          <div className={styles.socialMedia}>
            {blok?.socialLinks?.map((socialLink) => (
              <StoryblokLink blok={socialLink} key={socialLink?._uid} />
            ))}
          </div>
          <div className={styles.legal}>
            {blok?.legalLinks?.map((legalLink) => (
              <StoryblokLink blok={legalLink} key={legalLink?._uid} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      className={`footer ${styles.container}`}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      <Link href={"/"} className={styles.logo} aria-label="Røros Tweed logo">
        <LogoSvg />
      </Link>
      {blok?.subMenus?.map((subMenu) => (
        <SubMenu
          blok={subMenu}
          key={subMenu?._uid}
          {...storyblokEditable(subMenu)}
        />
      ))}
      <div className={styles.subContainer}>
        <div className={styles.currencySelector}>
          <CurrencySelector />
        </div>
        <div className={styles.socialMedia}>
          {blok?.socialLinks?.map((socialLink) => (
            <StoryblokLink blok={socialLink} key={socialLink?._uid} />
          ))}
        </div>
        <div className={styles.legal}>
          {blok?.legalLinks?.map((legalLink) => (
            <StoryblokLink blok={legalLink} key={legalLink?._uid} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UI;
