import Link from "next/link";

import getIndexStyles from "@/lib/utils/getIndexStyles";
import type { IResolvedProductCard } from "@/types/product";

import Animated from "../Animation/Animated";
import ProductCard from "../Product/ProductCard";
import styles from "./productList.module.scss";

interface UIProps {
  products: IResolvedProductCard[];
  numberStyles: string;
  title: string;
  link?: string;
}

const UI = ({ products, numberStyles, title, link }: UIProps) => {
  return (
    <div className={`${numberStyles} ${styles.container} `}>
      <Animated>
        {link ? (
          <Link href={link} className={styles.title}>
            {title}
          </Link>
        ) : (
          title && <h2 className={styles.title}>{title}</h2>
        )}
      </Animated>
      <div className={`${styles.cardList}  `}>
        {products.map((product, i) => (
          <div
            className={`${styles.productCard}  ${getIndexStyles(i, styles)}`}
            key={product?.id}
          >
            <Animated delay={i + 1} size="fill">
              <ProductCard product={product} />
            </Animated>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UI;
