import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import type { CartItem } from "../centra/selection/selectionFormatter";
import useStore from "../stateManagement/store";
import { optimisticAddToCart } from "../utils/optimisticUpdate";
import useLocation from "./useLocation";

const addProductToCart = async (
  variables: { itemId: string },
  customerToken: string
) => {
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

  const res = await axios.post(
    `${api}/items/${variables.itemId}`,
    {},
    { headers: { "API-token": customerToken || "" } }
  );

  if (res.data === null) throw Error("Error adding product to cart");
  return res.data;
};

export const useAddToCart = () => {
  const queryClient = useQueryClient();
  const customerToken = useStore((state) => state.token);
  const { currency } = useLocation();

  const addToCart = useMutation(
    async (variables: { itemId: string; cartItem: CartItem }) =>
      addProductToCart(variables, customerToken),
    {
      onMutate: async (variables) => {
        if (window.CentraCheckout) window.CentraCheckout.suspend();
        await queryClient.cancelQueries({ queryKey: ["selection"] });
        const oldData = queryClient.getQueryData(["selection"]);

        queryClient.setQueryData(["selection"], (oldCart: any) =>
          optimisticAddToCart(
            oldCart,
            {
              itemId: variables.itemId,
              cartItem: variables.cartItem,
            },
            currency || ""
          )
        );

        return { oldData };
      },

      onError: async (error: Error) => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        console.error("error", error);
        queryClient.invalidateQueries({ queryKey: ["selection"] });
        return error;
      },
      onSettled: () => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        queryClient.invalidateQueries({ queryKey: ["selection"] });
      },
    }
  );

  return { addToCart };
};
