import type { StoryBlokMedia } from "@/types/storyblok";

import UI from "./UI";

interface StoryBlokImageProps {
  media: StoryBlokMedia;
  sizes?: string;
}

const StoryblokImage = ({ media, sizes }: StoryBlokImageProps) => {
  return <UI media={media} sizes={sizes} />;
};

export default StoryblokImage;
