import CentraImage from "@/components/CentraImage";
import Skeleton from "@/components/Skeleton";
import type { IProductCardMedia } from "@/lib/api/formatting/rorosTweed/productCard";

import styles from "./productCardGallery.module.scss";

type UIProps = IProductCardMedia;

const UI = ({ packShot, environmentShot }: UIProps) => {
  const packShotClasses = `${styles.packShot} ${environmentShot}`;

  return (
    <figure>
      <div className={styles.environmentShot}>
        {environmentShot && (
          <CentraImage
            src={environmentShot.uri}
            alt={environmentShot.alt}
            layout="fill"
            objectFit="cover"
          />
        )}
        <Skeleton height="100%" />
      </div>

      <div className={packShotClasses}>
        {packShot && (
          <CentraImage
            src={packShot.uri}
            alt={packShot.alt}
            layout="fill"
            objectFit="cover"
          />
        )}
        <Skeleton height="100%" />
      </div>
    </figure>
  );
};

export default UI;
