import axios from "axios";

import { getProductCard } from "@/lib/api/formatting/rorosTweed/productCard";
import type { ICentraFilterUri, ISortOrderValue } from "@/types/centra";

import type { useFilterValue } from "./filterHooks/useFilterValue";
import type { IFilterResponse } from "./useProductFilter";

type IFilterBodyTypes =
  | boolean
  | number
  | string
  | undefined
  | string[]
  | ISortOrderValue[]
  | ICentraFilterUri;

interface IFilterBody {
  uri?: ICentraFilterUri | undefined;
  relatedProducts: boolean;
  limit: number;
  skipFirst: number;
  search?: string;
  sortOrder: ISortOrderValue[];
  [index: string]: IFilterBodyTypes;
}

interface IGetProducts {
  categoryIds?: string[];
  filters?: ReturnType<typeof useFilterValue>[];
  productIds?: string[] | undefined;
  search?: string;
  sortOrder?: ISortOrderValue[];
  page?: number;
  productsPerPage?: number;
  token?: string;
  language?: string;
}

export const getProducts = async ({
  categoryIds = [],
  filters = [],
  productIds,
  search,
  sortOrder = [],
  page = 0,
  productsPerPage = 8,
  token,
  language,
}: IGetProducts) => {
  const apiUrl = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

  const filterBody: IFilterBody = {
    categories: categoryIds,
    products: productIds,
    relatedProducts: true,
    limit: productsPerPage,
    skipFirst: page * productsPerPage,
    sortOrder: sortOrder,
    language,
  };

  if (search) {
    filterBody.search = search;
  }
  if (filters) {
    filters.forEach((filter) => {
      if (filter.required) filterBody[filter.field] = ["1"];
      else filterBody[filter.field] = filter.values;
    });
  }

  try {
    const res = await axios.post<IFilterResponse>(
      `${apiUrl}/products`,
      {
        ...filterBody,
      },
      {
        headers: {
          "API-token": token || "",
        },
      }
    );
    const formattedProducts = res.data.products.map((product: any) => {
      return getProductCard(product).data;
    });

    const filterFields = filters.map((filter) => filter.field);
    const formattedFilters = res.data.filter.filter(
      (filter) =>
        filterFields.findIndex((field) => field === filter.field) !== -1
    );
    return {
      ...res.data,
      products: formattedProducts,
      filter: formattedFilters,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message);
    } else {
      throw new Error("Unexpected error");
    }
  }
};
