import UI from "./UI";

interface RetailersListProps {
  content: any[] | undefined;
  filter: {
    key: string;
    value: string;
  };
}

const RetailersList = ({ content, filter }: RetailersListProps) => {
  content =
    filter.value === "all"
      ? content
      : content?.filter((story) => {
          return (
            filter.key in story.content &&
            story.content[filter.key] == filter.value
          );
        });

  content
    ?.sort((a, b) => {
      if (a?.name?.toLowerCase() === b?.name?.toLowerCase()) return 0;
      return a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1;
    })
    .sort((a, b) => {
      if (
        a?.content?.country?.toLowerCase() ===
        b?.content?.country?.toLowerCase()
      )
        return 0;
      return a?.content?.country?.toLowerCase() >
        b?.content?.country?.toLowerCase()
        ? 1
        : -1;
    });

  return <UI content={content} />;
};

export default RetailersList;
