import { useEffect } from "react";

import useStore from "../stateManagement/store";
import useDelayUnmount from "./useDelayUnmount";
import type { ICartItemWithHooks } from "./useItems";
import useItems from "./useItems";
import useModal from "./useModal";
import useSelection from "./useSelection";

const useCart = (callback: () => void = () => {}) => {
  const { items: oldItems, isLoading } = useSelection();

  const items = useItems(oldItems);
  const modal = useModal("cart", callback);

  const openCart = modal.openModal;
  const closeCart = modal.closeModal;
  const isOpen = modal.isOpen;
  const toggleCartState = modal.isOpen ? closeCart : openCart;
  const shouldRenderCart = useDelayUnmount(isOpen, 50);
  const cartQuantity = useStore((store) => store.cartQuantity);
  const setCartQuantity = useStore((store) => store.setCartQuantity);

  useEffect(() => {
    let newCartQuantity;

    if (!items || items.length === 0) {
      newCartQuantity = 0;
      return;
    }
    newCartQuantity =
      items.reduce(
        (sumCount: number, item: ICartItemWithHooks) =>
          sumCount + item.quantity,
        0
      ) || cartQuantity;

    if (newCartQuantity !== cartQuantity) {
      setCartQuantity(newCartQuantity);
    }
  }, [items]);

  const cart = {
    isOpen: isOpen,
    openCart: openCart,
    closeCart: closeCart,
    toggle: toggleCartState,
    shouldRender: shouldRenderCart,
    quantity: cartQuantity,
    items,
    itemsNoHooks: oldItems,
    isLoading,
    isEmpty: !items || items.length === 0,
  };

  return cart;
};

export default useCart;
