import { useEffect, useRef, useState } from "react";

import type { IPlayerInterface } from "@/types/storyblok";

const useAspect = (player: IPlayerInterface | null) => {
  const [wrapperAspect, setWrapperAspect] = useState<number | null>(null);
  const [aspectDifference, setAspectDifference] = useState<number | null>(null);
  const [videoAspect, setVideoAspect] = useState<number | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!videoAspect || !wrapperAspect) return;
    if (videoAspect > wrapperAspect) {
      setAspectDifference((videoAspect / wrapperAspect) * 100);
      return;
    }
    setAspectDifference(100);
  }, [videoAspect, wrapperAspect]);

  useEffect(() => {
    if (player !== null) {
      Promise.all([player.getVideoHeight(), player.getVideoWidth()]).then(
        (dimensions) => setVideoAspect(dimensions[1] / dimensions[0])
      );
    }

    if (wrapperRef.current) {
      setWrapperAspect(
        wrapperRef.current.clientWidth / wrapperRef.current.clientHeight
      );
    }
  }, [player]);

  const style = aspectDifference ? { width: `${aspectDifference}%` } : {};

  return {
    wrapperRef,
    aspectStyle: style,
  };
};

export default useAspect;
