import { default as Link } from "next/link";

interface UIProps {
  text: string;
  link: string;
  className?: string;
}

const UI = ({ text, link, className }: UIProps) => {
  if (!text) {
    // eslint-disable-next-line no-console
    console.warn("no text in link", text, link, className);
    return null;
  }
  const optionalAriaLabel =
    text === "Learn more" ? `Learn more about ${link}` : undefined;
  return (
    <Link href={link} legacyBehavior>
      <a className={className} aria-label={optionalAriaLabel}>
        {text}
      </a>
    </Link>
  );
};

export default UI;
