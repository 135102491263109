import { storyblokEditable } from "@storyblok/react";

import type { QuoteBlok } from "./Quote";
import styles from "./quote.module.scss";
interface UIProps {
  blok: QuoteBlok;
}
const UI = ({ blok }: UIProps) => {
  if (!blok) return null;

  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      {blok.text && <p className={styles.text}>{`“${blok.text}”`}</p>}
      <div className={styles.nameContainer}>
        {blok?.name && <p className={styles.name}>{blok.name}</p>}
        {blok?.role && <p className={styles.role}>{blok.role}</p>}
      </div>
    </div>
  );
};

export default UI;
