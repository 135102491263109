import { useRouter } from "next/router";

import { useProductFilter } from "@/lib/centra/useProductFilter/useProductFilter";

import UI from "./UI";

const searchLinks = {
  en: "/products?search=",
  no: "/produkter?search=",
};

const Search = () => {
  const { search } = useProductFilter();
  const router = useRouter();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    router.push(
      `${searchLinks[router.locale as keyof typeof searchLinks]}${search.value}`
    );
  };

  return <UI search={search} handleSubmit={handleSubmit} />;
};

export default Search;
