import type { SbBlokData } from "@storyblok/react";

import generateSBStyles from "@/lib/storyblok/generateSBStyles";

import styles from "./textHeader.module.scss";
import UI from "./UI";

export interface TextBlok extends SbBlokData {
  text: SbBlokData[];
}
export interface TextHeaderProps {
  blok: TextBlok;
}

const TextHeader = ({ blok }: TextHeaderProps) => {
  const { getStyles } = generateSBStyles(styles);

  const style = getStyles(blok, "");

  return <UI blok={blok} style={style} />;
};

export default TextHeader;
