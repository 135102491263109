import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import Animated from "@/components/Animation/Animated";

import StoryblokLink from "../StoryblokLink/StoryblokLink";
import type { SideBySideBlok } from "./SideBySide";
import styles from "./sideBySide.module.scss";

interface UIProps {
  blok: SideBySideBlok;
  getStyles: (blok: SbBlokData, className: string | undefined) => string;
  numberStyles: string;
  defaultStyle: (string | undefined)[];
}

const UI = ({ blok, getStyles, numberStyles, defaultStyle }: UIProps) => {
  if (!blok) return null;

  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      <div className={` ${numberStyles}   ${styles.text}`}>
        <Animated delay={0}>
          {blok?.text}{" "}
          {blok?.cta && blok?.cta[0] && (
            <StoryblokLink blok={blok?.cta[0]} className={styles.link} />
          )}
        </Animated>
      </div>

      {blok?.media?.map((blok, i) => (
        <div className={`${defaultStyle[i]} ${numberStyles}`} key={i}>
          <Animated delay={1} key={i}>
            <div className={getStyles(blok, styles.wrapper)} key={blok?._uid}>
              <StoryblokComponent blok={blok} />
            </div>
          </Animated>
        </div>
      ))}
    </div>
  );
};

export default UI;
