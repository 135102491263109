import getNumberStyles from "@/lib/utils/getNumberStyles";
import type { IResolvedProductCard } from "@/types/product";

import styles from "./productList.module.scss";
import UI from "./UI";

interface ProductListProps {
  products: IResolvedProductCard[];
  title: string;
  link?: string;
}

const ProductList = ({ products, title, link }: ProductListProps) => {
  const numberStyles = getNumberStyles(products.length, styles);
  // const bigCardsStyles = bigCards ? styles?.bigCards || "" : ""; can be uncommented if Tobis wants to use it

  return (
    <UI
      products={products}
      numberStyles={numberStyles}
      title={title}
      link={link}
    />
  );
};

export default ProductList;
