import Skeleton from "@/components/Skeleton";

import styles from "./SingleProductCard/productCard.module.scss";

const PCSkeleton = () => {
  return (
    <article className={styles.container}>
      <div className={styles.link}>
        <div className={styles.wrapper}>
          <div className={styles.imageWrapper}>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <Skeleton height="100%" />
            </div>
          </div>
          <div className={styles.details}>
            <Skeleton height="42px" />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PCSkeleton;
