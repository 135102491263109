import UI from "./UI";

interface SkeletonProps {
  height: string;
}
const Skeleton = ({ height }: SkeletonProps) => {
  return <UI height={height} />;
};

export default Skeleton;
