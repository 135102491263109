import { useRouter } from "next/router";
import { useEffect } from "react";

import useStore from "../stateManagement/store";

const useModal = (id: string, callback: () => void = () => {}) => {
  const router = useRouter();
  const preview = useStore((state) => state.preview);
  const setModal = useStore((state) => state.setModal);
  const clearModal = useStore((state) => state.clearModal);
  const modalId = useStore((state) => state.modalId);
  const addShownModal = useStore((state) => state.addShownModal);
  const shownModals = useStore((state) => state.shownModals);

  useEffect(() => {
    const handleRouteChange = (url: string, options: { shallow: boolean }) => {
      if (options.shallow) return;
      const path = url.split("?")[0];

      const newPath = router.asPath.split("?")[0];

      if (path !== newPath) {
        clearModal();
      }
    };
    return router.events.on("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  const openModal = () =>
    shownModals.includes(id) && !preview ? () => {} : setModal(id, callback);
  const closeModal =
    modalId === id
      ? clearModal
      : () => {
          console.error("Modal is not open");
        };
  const isOpen = modalId === id;

  const toggleModal = isOpen ? closeModal : openModal;
  const setAsShown = () => {
    addShownModal(id);
    closeModal();
  };

  return {
    isOpen,
    openModal,
    closeModal,
    toggleModal,
    currentModal: modalId,
    setAsShown,
  };
};

export type IModal = ReturnType<typeof useModal>;

export default useModal;
