import type { SbBlokData } from "@storyblok/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import styles from "@/components/Favourites/favourites.module.scss";
import ProductGrid from "@/components/Product/ProductGrid";
import useWishlist from "@/lib/hooks/useWishlist";
import useStore from "@/lib/stateManagement/store";
import type { IVariantCard } from "@/types/product";

export interface TextBlok extends SbBlokData {
  text: SbBlokData[];
}
export interface TextHeaderProps {
  blok: TextBlok;
}

const getProducts = (ids: string[]) => async () => {
  if (ids.length > 0) {
    return axios
      .post<IVariantCard[]>("/api/storefront/product-cards", {
        ids,
        type: "single",
      })
      .then((res) => res.data);
  }
  return [];
};

const Favourites = ({ blok }: any) => {
  const ids = useStore((store) => store.wishlistIds);
  const { addWishlistToVariantCards } = useWishlist();
  const { isLoading, isError, data } = useQuery(
    ["wishlist", ids],
    getProducts(ids)
  );

  const products = addWishlistToVariantCards(data);

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1 className={styles.title}>
            {blok.title}
            {!isError && (
              <span>{isLoading ? "(...)" : `(${products?.length})`}</span>
            )}
          </h1>
        </section>
        <section className={styles.grid}>
          {!isError && <ProductGrid products={products} />}
        </section>
      </div>
    </>
  );
};

export default Favourites;
