import getPriceList from "@/lib/centra/getPriceList";
import type { CartItem } from "@/lib/centra/selection/selectionFormatter";
import { useAddToCart } from "@/lib/hooks/useAddToCart";
import useCart from "@/lib/hooks/useCart";
import useSelection from "@/lib/hooks/useSelection";
import useWishlist from "@/lib/hooks/useWishlist";
import type { MobileTagType } from "@/lib/storyblok/useTaggedImage";
import { useTagStore } from "@/lib/storyblok/useTaggedImage";
import stopPropagation from "@/lib/utils/stopPropagation";

import UI from "./UI";

const convertProductToCartItem = (
  product: MobileTagType["product"],
  price: string
): CartItem => {
  const info = product?.info;
  const media = product?.media;

  if (!info || !media || !info.sizes) return {} as CartItem;

  return {
    id: info?.sizes[0]?.id || "",
    displayId: info?.sizes[0]?.id || "",
    name: info?.name || "",
    price: price || "",
    priceAsNumber: +price || 0,
    quantity: 1,
    media: media.packShot || {
      alt: "",
      uri: "",
    },
    variant: info.variantName || "",
    type: info.type || "",
    uri: info?.swatches?.[0]?.uri || "",
    itemId: info?.sizes[0]?.id || "",
  };
};

const MobileTag = () => {
  const tag = useTagStore((state) => state.tag);
  const { add, includes } = useWishlist();
  const addToWishlist = (e: any) => {
    stopPropagation(e);

    add(tag?.product?.id || "")();
  };
  const isWishlisted = includes(tag?.product?.id || "");
  const { addToCart: addToCartFunc } = useAddToCart();
  const { priceList } = useSelection();
  const prices = getPriceList(tag?.product?.info?.prices || [], +priceList);
  const cart = useCart();
  const id = ((tag?.product?.info?.sizes && tag?.product?.info?.sizes[0]?.id) ||
    "") as string;
  const addToCart = () => {
    addToCartFunc.mutate({
      itemId: id,
      cartItem: convertProductToCartItem(tag?.product, prices?.price),
    });
    cart.openCart();
  };

  return (
    <UI
      tag={tag}
      addToCart={addToCart}
      addToWishlist={addToWishlist}
      isWishlisted={isWishlisted}
    />
  );
};

export default MobileTag;
