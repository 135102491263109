import { create } from "zustand";

interface IDevStore {
  overlayDisplay: boolean;
  overlayDisplayToggle: () => void;
  overlayInvert: boolean;
  overlayInvertToggle: () => void;
  overlayEdge: boolean;
  overlayEdgeToggle: () => void;
}

export const useDevStore = create<IDevStore>((set) => ({
  overlayDisplay: false,
  overlayDisplayToggle: () =>
    set((state) => ({ overlayDisplay: !state.overlayDisplay })),
  overlayInvert: false,
  overlayInvertToggle: () =>
    set((state) => ({ overlayInvert: !state.overlayInvert })),
  overlayEdge: false,
  overlayEdgeToggle: () =>
    set((state) => ({ overlayEdge: !state.overlayEdge })),
}));
