import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

import type { SubmenuType } from "@/lib/storyblok/useSubmenu";
import type { IStoryBlokMenu } from "@/types/storyblok";

import styles from "./storyblokMobileMenu.module.scss";

const UI = ({
  blok,
  submenu,
}: {
  blok: IStoryBlokMenu;
  submenu: SubmenuType;
}) => {
  const label = blok?.label[0];
  if (!label) return null;

  return (
    <div
      key={blok?._uid}
      data-key={blok?._uid}
      {...storyblokEditable(blok)}
      className={styles.container}
    >
      {submenu.links.length > 0 && (
        <div className={styles.submenu}>
          {submenu?.links?.map((link) => {
            if (link.space) {
              return <span key={link?._uid} className={styles.space} />;
            }
            return (
              <Link
                href={link.link}
                key={link?._uid}
                className={`${link.selected && styles.selected}`}
              >
                {link.text}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UI;
