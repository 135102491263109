import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

import { getAllStories } from "./getStories";

const updateStories = async <T>(
  storyType: string,
  preview: boolean,
  setStories: Dispatch<SetStateAction<T[]>>
) => {
  const { stories: newStories } = await getAllStories<T>(storyType, preview);
  setStories(newStories);
};

export const useRehydratedAllStories = <T>(
  storyType: string,
  intialStories: T[],
  preview: boolean = false
) => {
  const [stories, setStories] = useState(intialStories);
  const [refresh, setRefresh] = useState(true);
  const triggerRefresh = () => setRefresh(true);

  useEffect(() => {
    if (refresh) {
      updateStories(storyType, preview, setStories);
      setRefresh(false);
    }
  }, [refresh]);

  return { stories, triggerRefresh };
};
