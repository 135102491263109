interface HeadingProps {
  children: React.ReactNode;
  level: number;
}

const Heading = ({ children, level }: HeadingProps) => {
  if (level === 1) {
    return <h1>{children}</h1>;
  }
  if (level === 2) {
    return <h2>{children}</h2>;
  }
  if (level === 3) {
    return <h3>{children}</h3>;
  }
  if (level === 4) {
    return <h4>{children}</h4>;
  }

  return <h1>{children}</h1>;
};

export default Heading;
