import type { SbBlokData } from "@storyblok/react";
import Link from "next/link";
import { useTranslations } from "next-intl";

import MobileNavigation from "@/bloks/Navigation/MobileNavigation";
import PrimaryNavigation from "@/bloks/Navigation/PrimaryNavigation";
import SecondaryNavigation from "@/bloks/Navigation/SecondaryNavigation";
import CartIcon from "@/components/Cart/CartIcon";
import type { IMobileMenu } from "@/lib/hooks/useMobileMenu";
import LogoSvg from "@/public/svg/Logo.svg";
import MobileLogo from "@/public/svg/Mobile logo.svg";
import type { ICart } from "@/types/centra";

import styles from "./header.module.scss";

interface UIProps {
  cart: ICart;
  previewData?: {
    [key: string]: SbBlokData;
  };
  device: { [key: string]: boolean };
  mobileMenu: IMobileMenu;
  hide: boolean;
}

const UI = ({ previewData, device, mobileMenu, hide }: UIProps) => {
  const t = useTranslations();
  if (device.mobile) {
    return (
      <>
        <div className={`${styles.container} ${hide ? styles.hide : ""}`}>
          <button
            className={styles.menuIcon}
            onClick={mobileMenu.modal.toggleModal}
          >
            {t("Menu")}
          </button>
          <MobileNavigation mobileMenu={mobileMenu} />
          <Link
            href="/"
            className={styles.logoWrapper}
            aria-label="Røros Tweed logo"
          >
            <MobileLogo />
          </Link>
          <CartIcon />
        </div>
      </>
    );
  }
  return (
    <div className={`header ${styles.container} ${hide ? styles.hide : ""}`}>
      <PrimaryNavigation previewData={previewData} />
      <Link
        href="/"
        className={styles.logoWrapper}
        aria-label="Røros Tweed logo"
      >
        <LogoSvg />
      </Link>
      <SecondaryNavigation previewData={previewData} />
    </div>
  );
};

export default UI;
