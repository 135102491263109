import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRouter } from "next/router";

import type {
  IProductCard,
  IResolvedProductCard,
  IVariantCard,
  ProductCardTypes,
} from "@/types/product";

import useWishlist from "../hooks/useWishlist";

const getProductCards = async (
  ids: number[],
  type: ProductCardTypes,
  locale = "en"
) => {
  if (ids.length === 0) return [];

  const res = await axios.post<IProductCard[] | IVariantCard[]>(
    "/api/storefront/product-cards",
    {
      ids,
      type,
      locale,
    }
  );

  return res.data;
};

const useResolvedProductCards = <T extends ProductCardTypes>(
  ids: number[],
  type: T,
  key: string
): (T extends "single" ? IVariantCard[] : IProductCard[]) | null => {
  const { addWishlistToProductCards, addWishlistToVariantCards } =
    useWishlist();
  const { locale } = useRouter();

  const addWishlist =
    type === "single" ? addWishlistToVariantCards : addWishlistToProductCards;
  const { data, isLoading } = useQuery([key, ids, type, locale], () =>
    getProductCards(ids, type, locale)
  );
  if (isLoading) return null;
  if (data?.[0]?.id) {
    data?.sort(
      (a: IResolvedProductCard, b: IResolvedProductCard) =>
        ids.indexOf(+(a.id || 0)) - ids.indexOf(+(b.id || 0))
    );
  }

  const formattedData = data ? addWishlist(data) : data;

  return type === "single"
    ? (formattedData as IVariantCard[])
    : (formattedData as IProductCard[]);
};

export default useResolvedProductCards;
