import { useState } from "react";
import { createPortal } from "react-dom";

import config from "@/config";
import useDelayUnmount from "@/lib/hooks/useDelayUnmount";
import useLocation from "@/lib/hooks/useLocation";
import animations from "@/lib/utils/animations";
import ArrowSvg from "@/public/svg/Arrow.svg";
import CheckSvg from "@/public/svg/check.svg";
import type { ICountry } from "@/types/centra";

import styles from "./locationSelector.module.scss";

const CurrencySelector = () => {
  const location = useLocation();

  const countries = location?.countries;

  const [show, setShow] = useState(false);
  const toggleMenu = () => setShow(!show);
  // const closeMenu = () => setShow(false);
  const shouldRender = useDelayUnmount(
    show,
    config.animationDurationModal * 1000
  );

  return (
    <div className={styles.container}>
      <div className={styles.sortMenu}>
        <div onClick={toggleMenu} className={`${styles.button}`}>
          {location.currency}
          <ArrowSvg />
        </div>
        {shouldRender && (
          <>
            {createPortal(
              <div className={styles.overlay} onClick={toggleMenu} />,
              document.body
            )}
            <span
              data-open={show}
              className={`${styles.dropdown}`}
              style={animations("fade", show)}
            >
              {countries?.map((country: ICountry) => (
                <button
                  key={country.country}
                  onClick={() => {
                    country.changeLocation();
                    toggleMenu();
                  }}
                  className={`${styles.item} ${
                    country.selected && styles.selected
                  }`}
                >
                  {country.currency} {country.selected && <CheckSvg />}
                </button>
              ))}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default CurrencySelector;
