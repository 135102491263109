import config from "@/config";

import type { ICentraProductNoRelations } from "./productPage";

export const getDesignerName = (product: ICentraProductNoRelations) => {
  return product.pr_designer?.name || null;
};

export const getDesignerSlug = (product: ICentraProductNoRelations) => {
  return product.pr_designer?.slug || null;
};

export const getCountryNameInLocale = (
  product: ICentraProductNoRelations,
  locales: string[] = ["no", "en"]
) => {
  const countries = locales.map((locale) => {
    const intl = new Intl.DisplayNames([locale], { type: "region" });
    return {
      locale,
      country: intl.of(product.countryOrigin),
    };
  });

  return countries;
};

export const getDesigner = (product: ICentraProductNoRelations) => {
  return {
    name: getDesignerName(product) || null,
    slug: getDesignerSlug(product) || null,
  };
};

export const getQuoteText = (product: ICentraProductNoRelations) => {
  return product.dis_quote_quote;
};

export const getQuoteByline = (product: ICentraProductNoRelations) => {
  return product.dis_quote_byline;
};

export const getQuote = (product: ICentraProductNoRelations) => {
  const quote = getQuoteText(product) || null;
  const byline = getQuoteByline(product) || null;

  return {
    quote,
    byline,
  };
};

export const getPackshotCount = (product: ICentraProductNoRelations) => {
  const packshotCount = product.pr_packshots_count_value
    ? +product.pr_packshots_count_value
    : config.defaultPackshotCount;

  return packshotCount;
};

export const getProductType = (product: ICentraProductNoRelations) => {
  const type = product.categoryName[product.categoryName.length - 1];

  return type;
};

export const getDownloadLink = (product: ICentraProductNoRelations) => {
  const link = product.dis_downloads_value;

  return link;
};

export const getDisplayWeight = (product: ICentraProductNoRelations) => {
  const weight = product.pr_display_weight_value;

  return weight;
};
