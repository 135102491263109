import UI from "./UI";

interface ArchiveGridProps {
  children: React.ReactNode;
}

const ArchiveGrid = ({ children }: ArchiveGridProps) => {
  return <UI>{children}</UI>;
};

export default ArchiveGrid;
