import type { SbBlokData } from "@storyblok/react";

import UI from "./UI";

export interface AccordionBlok extends SbBlokData {
  title: string;
  text: string;
}

export interface AccordionBlokProps {
  blok: AccordionBlok;
}

const Accordion = ({ blok }: AccordionBlokProps) => {
  return <UI blok={blok} />;
};

export default Accordion;
