import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { useTranslations } from "next-intl";

import CartIcon from "@/components/Cart/CartIcon";
import LanguageSelector from "@/components/Layout/Snackbar/LanguageCurrencySelector/LanguageSelector";
import useStore from "@/lib/stateManagement/store";
import getLink from "@/lib/storyblok/getLink";

import StoryblokMenu from "../StoryblokMenu";
import styles from "./secondaryNavigation.module.scss";

export interface UIProps {
  blok: any;
}

const UI = ({ blok }: UIProps) => {
  const ids = useStore((store) => store.wishlistIds);
  const t = useTranslations();
  if (!blok) return null;
  const searchLink = blok?.searchPage ? getLink(blok.searchPage) : "#";
  const favouritesLink = blok?.favouritesPage
    ? getLink(blok.favouritesPage)
    : "#";

  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      <LanguageSelector />
      {blok?.menus?.map((menu: any) => (
        <StoryblokMenu
          blok={menu}
          key={menu?._uid}
          {...storyblokEditable(menu)}
        />
      ))}
      <Link href={favouritesLink}>
        {ids.length > 0 && `(${ids.length})`}
        {t("favourites")}
      </Link>
      <Link href={searchLink}>{t("search")}</Link>
      <CartIcon />
    </div>
  );
};

export default UI;
