import { useState } from "react";

import type {
  IStoryBlokMenu,
  NavigationBlok,
  NavigationProps,
} from "@/types/storyblok";

import UI from "./UI";

export interface MobileNavigationProps extends NavigationProps {
  mobileMenu: {
    modal: {
      toggleModal: () => void;
      isOpen: boolean;
      currentModal: string;
    };
    primaryContent: any;
    secondaryContent: any;
  };
}

const MobileNavigation = ({ mobileMenu }: MobileNavigationProps) => {
  const [currentSubmenuId, setCurrentSubmenuId] = useState("");
  const currentSubmenu =
    currentSubmenuId !== ""
      ? mobileMenu.primaryContent?.menus?.filter(
          (menu: any) => menu?._uid === currentSubmenuId
        )[0]
      : mobileMenu.primaryContent?.menus[0];

  const showMobileMenu =
    !!mobileMenu.primaryContent?.menus?.find(
      (menu: IStoryBlokMenu) => menu?._uid === mobileMenu.modal.currentModal
    ) || mobileMenu.modal.isOpen;

  return (
    <UI
      blok={mobileMenu.primaryContent as NavigationBlok}
      showMobileMenu={showMobileMenu}
      toggleModal={mobileMenu.modal.toggleModal}
      currentSubmenu={currentSubmenu}
      toggleSubmenu={setCurrentSubmenuId}
    />
  );
};

export default MobileNavigation;
