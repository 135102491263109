import type { VariantPage } from "@/lib/api/formatting/rorosTweed/productPage";
import { getThumbnails } from "@/lib/product/productsFormatters";
import type { ICentraMedia } from "@/types/product";

export interface ISelectionItem {
  priceEachBeforeDiscountAsNumber: number;
  size: string;
  line: string;
  priceEach: string;
  product: ISelectionItemProduct;
  quantity: number;
  item: string;
}

export interface ISelectionItemProduct {
  productSku: any;
  name: string;
  uri: string;
  variantName: string;
  media: ICentraMedia;
  categoryName: string[];
  product: string;
}

export interface ISelectionDiscount {
  code: string;
  amount: string;
  anyDiscount?: boolean;
}

interface ISelectionPrices {
  total: string;
  shipping: string;
  shippingAsNumber: number;
  itemsTotal: string;
}

export interface ISelectionSummary {
  prices: ISelectionPrices;
  discounts: ISelectionDiscount;
  quantity: number;
}

export interface CentraSelection {
  totals: CentraTotals;
  discounts: CentraDiscounts;
  address: CentraAddress;
}

export interface CentraOrder extends CentraSelection {
  order: string;
  currency: string;
  date: string;
  items: ISelectionItem[];
  statusDescription: string;
}

export interface CentraAddress {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  vatNumber?: string;
  company: string;
}

export interface CentraTotals {
  taxAsNumber: number;
  shippingAsNumber: number;
  grandTotalPrice: string;
  shippingPrice: string;
  itemsTotalPrice: string;
  totalQuantity: number;
  grandTotalPriceAsNumber: number;
  itemsTotalPriceAsNumber: number;
}

export interface CentraDiscounts {
  anyDiscount: boolean;
  vouchers: CentraVoucher[];
  discount: string;
  discountAsNumber: number;
}

export interface CentraVoucher {
  voucher: string;
  priceOff: string;
}

export type CartItem = ReturnType<typeof generateCartItem>;

const getSelectionItemName = (item: ISelectionItem) => {
  return item.product.name;
};

const getSelectionItemUri = (item: ISelectionItem) => {
  return (
    item.product.uri +
    "?variant=" +
    item.product.variantName.replace(" ", "-").toLocaleLowerCase()
  );
};

const getSelectionItemVariantName = (item: ISelectionItem) => {
  return item.product.variantName;
};

// const getSelectionItemSize = (item: ISelectionItem) => {
//   return item.size;
// };

const getSelectionItemType = (item: ISelectionItem) => {
  return item?.product?.categoryName[item?.product?.categoryName?.length - 1];
};

const getSelectionItemLine = (item: ISelectionItem) => {
  return item.line;
};

const getSelectionItemId = (item: ISelectionItem) => {
  return item.item;
};

const getSelectionDisplayId = (item: ISelectionItem) => {
  return item.product.product;
};

const getSelectionItemPriceEach = (item: ISelectionItem) => {
  return item.priceEach;
};

const getSelectionItemPriceEachAsNumber = (item: ISelectionItem) => {
  return item.priceEachBeforeDiscountAsNumber;
};

const getSelectionItemQuantity = (item: ISelectionItem) => {
  return item.quantity;
};

const getSelectionItemMedia = (item: ISelectionItem) => {
  return getThumbnails(item.product);
};

const getFirstImage = (item: ISelectionItem) => {
  const media = getSelectionItemMedia(item);
  return media && media.length > 0 ? media[0] : null;
};

const getTotalPrice = (selection: CentraSelection) => {
  return selection.totals?.grandTotalPrice;
};

const getItemsTotalPrice = (selection: CentraSelection) => {
  return selection.totals?.itemsTotalPrice;
};

const getShippingPrice = (selection: CentraSelection) => {
  return selection.totals?.shippingPrice;
};

const getPrices = (selection: CentraSelection): ISelectionPrices => {
  const prices = {
    total: getTotalPrice(selection),
    shipping: getShippingPrice(selection),
    shippingAsNumber: selection.totals?.shippingAsNumber,
    itemsTotal: getItemsTotalPrice(selection),
  };
  return prices;
};

const getDiscounts = (selection: CentraSelection) => {
  return selection.discounts?.vouchers;
};

const getDiscountAmount = (selection: CentraSelection) => {
  const vouchers = getDiscounts(selection);
  return vouchers[0]?.priceOff || "0";
};

const getFirstDiscountCode = (selection: CentraSelection) => {
  const vouchers = getDiscounts(selection);
  return vouchers[0]?.voucher || "";
};

const getAnyDiscount = (selection: CentraSelection) => {
  return selection.discounts?.anyDiscount;
};

const getDiscount = (selection: CentraSelection) => {
  const discount = {
    code: getFirstDiscountCode(selection),
    amount: getDiscountAmount(selection),
    anyDiscount: getAnyDiscount(selection),
  };
  return discount;
};

const getProductQuantity = (selection: CentraSelection) => {
  return selection.totals?.totalQuantity;
};

export const generateCartSummary = (
  cart: CentraSelection
): ISelectionSummary => {
  const prices = getPrices(cart);
  const discounts = getDiscount(cart);
  const quantity = getProductQuantity(cart);

  return {
    prices,
    discounts,
    quantity,
  };
};

export const generateCartItem = (item: ISelectionItem) => {
  const name = getSelectionItemName(item);
  const uri = getSelectionItemUri(item);
  const variant = getSelectionItemVariantName(item);
  const type = getSelectionItemType(item);
  const id = getSelectionItemLine(item);
  const price = getSelectionItemPriceEach(item);
  const priceAsNumber = getSelectionItemPriceEachAsNumber(item);
  const quantity = getSelectionItemQuantity(item);
  const media = getFirstImage(item);
  const itemId = getSelectionItemId(item);
  const displayId = getSelectionDisplayId(item);

  return {
    name,
    priceAsNumber,
    uri,
    variant,
    type,
    id,
    displayId,
    price,
    itemId,
    quantity,
    media,
  };
};

export const convertProductToCartItem = (product: VariantPage) => {
  const name = product.name;
  const uri = product.uri;
  const variant = product.variantName;
  const type = product.type;
  const id = product.id;
  const quantity = 1;
  const media = product.packShotGallery?.media?.[0];

  const displayId = product.id;

  return (size: unknown, price: number) => {
    return {
      name,
      uri,
      variant,
      type,
      id,
      displayId,
      price,
      itemId: size,
      quantity,
      media,
    };
  };
};

export type PriceAndSize = ReturnType<typeof convertProductToCartItem>;
