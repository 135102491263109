import useCart from "@/lib/hooks/useCart";

import UI from "./UI";

const CartIcon = () => {
  const cart = useCart();
  return <UI cart={cart} />;
};

export default CartIcon;
