import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import Animated from "@/components/Animation/Animated";

import type { HighlightedMediaBlok } from "./HighlightedMedia";

interface UIProps {
  blok: HighlightedMediaBlok;
  getStyles: (styles: SbBlokData, baseStyles?: string) => string;
  baseStyles: (string | undefined)[];
  containerStyles: string | undefined;
  margin: string | undefined;
}
const UI = ({
  blok,
  getStyles,
  baseStyles,
  containerStyles,
  margin,
}: UIProps) => {
  return (
    <Animated direction="fade">
      <div
        className={`${containerStyles} ${margin}`}
        key={blok?._uid}
        {...storyblokEditable(blok)}
        style={{
          backgroundColor: blok.color.color,
        }}
      >
        {blok?.media?.map((blok, i) => (
          <div className={baseStyles[i]} key={blok?._uid}>
            <Animated delay={i}>
              <div className={getStyles(blok)}>
                <StoryblokComponent blok={blok} />
              </div>
            </Animated>
          </div>
        ))}
      </div>
    </Animated>
  );
};

export default UI;
