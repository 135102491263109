import config from "@/config";

import UI from "./UI";

export type animationDirections =
  | "left"
  | "right"
  | "top"
  | "bottom"
  | "fade"
  | "none";

interface AnimationWrapperProps {
  direction?: animationDirections;
  isVisible: boolean | undefined;
  children: React.ReactNode;
  shouldRender: boolean;
  duration?: number;
  delay?: number;
}

const AnimationWrapper = ({
  delay = 0,
  direction,
  isVisible,
  children,
  shouldRender,
  duration = config.animationDurationModal,
}: AnimationWrapperProps) => {
  return (
    <UI
      direction={direction}
      isVisible={isVisible}
      shouldRender={shouldRender}
      duration={duration}
      delay={delay}
    >
      {children}
    </UI>
  );
};

export default AnimationWrapper;
