import type { InfiniteData } from "@tanstack/react-query";

import type { StoryPage } from "./getStories";

const storyReducer = <T>(data: InfiniteData<StoryPage<T>>): T[] => {
  const stories = data.pages.reduce(
    (previousValue: T[], currentValue: StoryPage<T>): T[] => {
      if (currentValue.data.stories.length || 0 > 0) {
        return [...previousValue, ...(currentValue?.data.stories || [])];
      }
      return previousValue;
    },
    []
  );

  return stories;
};

export default storyReducer;
