import type { StoryBlokMedia } from "@/types/storyblok";

import StoryblokImage from "./StoryblokImage";
import styles from "./storyblokMedia.module.scss";
import Video from "./Video";

interface UIProps {
  media: StoryBlokMedia;
  sizes?: string;
}

const UI = ({ media, sizes }: UIProps) => {
  return (
    <div className={styles.wrapper}>
      {media.isImage && (
        <StoryblokImage media={media} key={media.file} sizes={sizes} />
      )}
      {media.isVideo && <Video media={media} key={media.file} />}
    </div>
  );
};

export default UI;
