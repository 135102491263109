import styles from "./archiveGrid.module.scss";

interface ArchiveGridUI {
  children: React.ReactNode;
}

const UI = ({ children }: ArchiveGridUI) => {
  return (
    <div className={styles.grid}>
      <div className={styles.innerGrid}>{children}</div>
    </div>
  );
};

export default UI;
