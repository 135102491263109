import { useInView } from "react-intersection-observer";

import type { TaggedImageBlok } from "@/bloks/TaggedImage/TaggedImage";
import type { IProductCard } from "@/lib/api/formatting/rorosTweed/productCard";
import useDevice from "@/lib/hooks/useDevice";

import UI from "./UI";

interface ProductGridProps {
  products?: IProductCard[] | undefined;
  featured?: TaggedImageBlok[] | undefined;
  loadMore?: {
    callback: () => void;
    loading: boolean;
  };
}

const ProductGrid = ({ products, featured, loadMore }: ProductGridProps) => {
  const { mobile } = useDevice();
  const featuredCount = Math.max(
    Math.floor(((products?.length || 0) + 8) / 16),
    0
  );
  const observer = useInView({
    onChange(inView) {
      if (inView && loadMore) {
        loadMore.callback();
      }
    },
    rootMargin: "300px 0px",
  });

  const getMore = {
    ref: observer.ref,
    loading: loadMore?.loading || false,
  };

  const trimmedFeatured = featured?.slice(0, featuredCount) || [];

  return (
    <UI
      products={products}
      featured={trimmedFeatured}
      mobile={mobile}
      getMore={getMore}
    />
  );
};

export default ProductGrid;
