import type { IProductCardMedia } from "@/lib/api/formatting/rorosTweed/productCard";

import UI from "./UI";

type IProductCardGallery = IProductCardMedia;

const ProductCardGallery = (media: IProductCardGallery) => {
  return <UI {...media} />;
};

export default ProductCardGallery;
