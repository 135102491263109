import { useMutation, useQueryClient } from "@tanstack/react-query";

import useStore from "../stateManagement/store";
const setLocation = async (
  variables: {
    locationId: string;
    stateId?: string;
  },
  customerToken: string
) => {
  if (window.CentraCheckout) window.CentraCheckout.suspend();
  const { locationId, stateId } = variables;
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const url = stateId
    ? `${api}/countries/${locationId}/states/${stateId}`
    : `${api}/countries/${locationId}`;
  const res = fetch(url, {
    method: "PUT",
    headers: { "API-token": customerToken || "" },
  })
    .then((res) => {
      if (!res.ok) throw Error("Error updating location");
      return res.json();
    })
    .then((data) => {
      if (data === null) throw Error("Error updating location");
      return data;
    })
    .catch((err) => {
      throw err;
    });

  return res;
};

const useChangeLocation = () => {
  const queryClient = useQueryClient();

  const customerToken = useStore((state) => state.token);
  const changeLocation = useMutation(
    (variables: { locationId: string; stateId?: string }) =>
      setLocation(variables, customerToken),
    {
      onMutate: async (variables) => {
        await queryClient.cancelQueries(["selection"]);
        const previousSelection = queryClient.getQueryData(["selection"]);

        queryClient.setQueryData(["selection"], (old: any) => {
          return {
            ...old,
            location: {
              ...old.location,
              name: old.countries.find(
                (country: any) => country.country === variables.locationId
              )?.name,
              country: variables.locationId,
              state: variables.stateId,
            },
          };
        });
        return { previousSelection };
      },
      onError: async (error: Error) => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        return error;
      },

      onSettled: async () => {
        queryClient.invalidateQueries(["selection"]);
      },
    }
  );

  return { changeLocation };
};

export default useChangeLocation;
