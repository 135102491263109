import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_LINK,
  NODE_HEADING,
  NODE_LI,
  NODE_UL,
  render,
} from "storyblok-rich-text-react-renderer";

import Accordion from "../AccordionContainer/AccordionBlok";
import type { AccordionBlok } from "../AccordionContainer/AccordionBlok/Accordion";
import type { StoryblokLinkBlok } from "../StoryblokLink/StoryblokLink";
import StoryblokLink from "../StoryblokLink/StoryblokLink";
import Bold from "./resolvedBloks/Bold";
import Heading from "./resolvedBloks/Heading";
import Italic from "./resolvedBloks/Italic";
import ListItem from "./resolvedBloks/ListItem";
import type { RichTextBlok } from "./RichText";
import styles from "./richText.module.scss";

interface UIProps {
  text: string;
  blok: RichTextBlok;
  productPage?: boolean;
}

const UI = ({ text, blok, productPage }: UIProps) => {
  return (
    <div
      key={blok?._uid}
      {...storyblokEditable(blok)}
      className={`${styles.container} ${productPage ? styles.productPage : ""}`}
    >
      {render(text, {
        markResolvers: {
          [MARK_BOLD]: (children) => <Bold>{children}</Bold>,
          [MARK_ITALIC]: (children) => <Italic>{children}</Italic>,
          [MARK_LINK]: (children, props) => {
            const { href, target, linktype } = props;

            if (href?.match(/^(https?:)?\/\//)) {
              // External links: map to <a>
              return (
                <a href={href} target={target}>
                  {children}
                </a>
              );
            }

            // Email links: map to <a>

            if (href?.match(/^mailto:/) || linktype === "email") {
              return (
                <a href={`mailto:${href}`} target={target}>
                  {children}
                </a>
              );
            }

            // Internal links: map to <Link>
            return <Link href={href || ""}>{children}</Link>;
          },
        },
        blokResolvers: {
          ["link"]: (blok) => (
            <StoryblokLink
              blok={blok as StoryblokLinkBlok}
              className={`${styles.button} ${
                productPage ? styles.productPage : ""
              }`}
            />
          ),
          ["space"]: () => (
            <div
              className={`${styles.space} ${
                productPage ? styles.productPage : ""
              }`}
            />
          ),
          ["accordion"]: (blok) => <Accordion blok={blok as AccordionBlok} />,
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, level) => (
            <Heading level={level.level}>{children}</Heading>
          ),
          [NODE_UL]: (children) => (
            <ul
              className={`${styles.list} ${
                productPage ? styles.productPage : ""
              }`}
            >
              {children}
            </ul>
          ),
          [NODE_LI]: (children) => <ListItem>{children}</ListItem>,
        },
      })}
    </div>
  );
};

export default UI;
