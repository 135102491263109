import type { SbBlokData } from "@storyblok/react";

import generateSBStyles from "@/lib/storyblok/generateSBStyles";

import styles from "./textContainer.module.scss";
import UI from "./UI";

export interface TextBlok extends SbBlokData {
  text: SbBlokData[];
  backgroundColor: {
    color: string;
  };
  position: "left" | "right" | "center";
}
export interface QuoteProps {
  blok: TextBlok;
}

const Quote = ({ blok }: QuoteProps) => {
  const { getStyles } = generateSBStyles(styles);
  const baseStyles = styles[blok?.position] || styles.left;
  const style = getStyles(blok, baseStyles);

  return <UI blok={blok} style={style} />;
};

export default Quote;
