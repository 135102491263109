import type { SbBlokData } from "@storyblok/react";

// The purpose of this funtion is to return a function that will return a string of classes, to be used in the className attribute of a component.
// The function takes a single argument, styles, which is an object containing the styles.module.scss file for the component.
// The function returns an object with a single property, getStyles, which is a function that takes two arguments, blok and baseStyle.
// The blok argument is the blok object from Storyblok, and the baseStyle argument is the base style for the component.

const generateSBStyles = (styles: { [key: string]: any }) => {
  const getStyles = (blok: SbBlokData, baseStyle: string | undefined) => {
    let style = [baseStyle];
    const blokStyle = styles[blok.component!];
    style.push(blokStyle);
    return style.join(" ");
  };

  return {
    getStyles,
  };
};

export default generateSBStyles;
