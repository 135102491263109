import type { IResolvedStoryblokImage } from "@/types/storyblok";

export const getStoryblokFile = (media: IResolvedStoryblokImage) => {
  const file = media.filename;
  return file;
};

export const getStoryblokAlt = (media: IResolvedStoryblokImage) => {
  const alt = media.alt;
  return alt;
};

export const getStoryblokId = (media: IResolvedStoryblokImage) => {
  const id = media.id;
  return id;
};
