/** @type {import('next').NextConfig} */
module.exports = {
  i18n: {
    locales: ["en", "no"],
    defaultLocale: "en",
    domains: [
      {
        domain: "www.rorostweed.com",
        defaultLocale: "en",
      },
      {
        domain: "www.rorostweed.no",
        defaultLocale: "no",
      },
    ],
    localeDetection: false,
  },
  react: { useSuspense: false }, //this line is important
};
