import Image from "next/legacy/image";

import type { StoryBlokMedia } from "@/types/storyblok";

import styles from "./storyblokImage.module.scss";
interface UIProps {
  media: StoryBlokMedia;
  sizes?: string;
}

const UI = ({ media, sizes }: UIProps) => {
  return (
    <div className={styles.container}>
      <Image
        src={media.file}
        alt={media.alt}
        layout="fill"
        objectFit="cover"
        sizes={
          sizes ? sizes : `(max-width: 1024px) 100vw, (max-width: 2560px) 90vw`
        }
      />
    </div>
  );
};

export default UI;
