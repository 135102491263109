import type { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";

import Animated from "@/components/Animation/Animated";
import StoryblokMedia from "@/components/StoryblokMedia";
import type { IStoryBlokMedia } from "@/components/StoryblokMedia/StoryblokMedia";

import type { RichTextBlok } from "../RichText/RichText";
import RichText from "../RichText/RichText";
import type { DetailsBlok } from "./Details";
import styles from "./details.module.scss";
interface UIProps {
  blok: DetailsBlok;
  getStyles: (styles: SbBlokData, baseStyles?: string) => string;
  orderStyle: (string | undefined)[];
  media?: IStoryBlokMedia;
  richText?: RichTextBlok;
  title: string;
  subtitle: string;
}

const UI = ({
  blok,
  getStyles,
  subtitle,
  media,
  orderStyle,
  richText,
  title,
}: UIProps) => {
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: blok.color.color }}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      {media && (
        <div className={`${styles.mediaWrapper} ${orderStyle[0]}`}>
          <Animated>
            <div className={getStyles(media)}>
              <StoryblokMedia blok={media} />
            </div>
          </Animated>
        </div>
      )}
      <Animated delay={1} size="fill">
        <div className={`${styles.textWrapper} ${orderStyle[1]}`}>
          {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
          {title && <h2 className={styles.title}>{title}</h2>}

          {richText && <RichText blok={richText} />}
        </div>
      </Animated>
    </div>
  );
};

export default UI;
