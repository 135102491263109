import { useTranslations } from "next-intl";
import { useEffect, useRef } from "react";

import type { SearchHook } from "@/lib/centra/useProductFilter/filterHooks/useSearch";

import styles from "./search.module.scss";

interface UIProps {
  search: SearchHook;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const UI = ({ search, handleSubmit }: UIProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const t = useTranslations();
  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, [ref]);
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label>
          <input {...search} className={styles.input} ref={ref} />
          {t("pressEnterToSearch")}
        </label>
      </form>
    </div>
  );
};

export default UI;
