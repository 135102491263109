import { getCookie } from "@/lib/Analytics/getCookie";
import useCart from "@/lib/hooks/useCart";

import UI from "./UI";

type Props = {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const cart = useCart();
  const cookie = getCookie("trackMe");

  return (
    <UI cart={cart} cookie={cookie}>
      {children}
    </UI>
  );
};

export default Layout;
