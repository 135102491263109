import { useEffect, useState } from "react";

import config from "@/config";

const useDelayUnmount = (
  isMounted: boolean,
  delayTime: number = config.animationDurationContent * 200
) => {
  const [shouldRender, setShouldRender] = useState(false);

  // Function to handle component mounting
  const handleMount = () => {
    setShouldRender(true);
  };

  // Function to handle component unmounting
  const handleUnmount = () => {
    const timeoutId = window.setTimeout(
      () => setShouldRender(false),
      delayTime
    );
    return () => clearTimeout(timeoutId);
  };

  // Use useEffect to call the appropriate function based on the value of isMounted
  useEffect(() => {
    if (isMounted) {
      handleMount();
    } else {
      handleUnmount();
    }
  }, [isMounted, delayTime]);

  return shouldRender;
};
export default useDelayUnmount;
