import type { StateCreator } from "zustand";

import type { IState } from "../store";

type Media = {
  uri: string;
  alt: string;
};

export interface IMediaState {
  selectedMedia: number | null;
  setSelectedMedia: (selectedMedia: number | null) => void;
  allMedia: { name: string; media: Media[] | undefined }[];
  setAllMedia: (media: { name: string; media: Media[] | undefined }[]) => void;
  selectedArray: number;
  setSelectedArray: (selectedArray: number) => void;
}

const createMediaSlice: StateCreator<IState, [], [], IMediaState> = (
  set,
  _get
) => ({
  selectedMedia: 0,
  setSelectedMedia: (selectedMedia: number | null) =>
    set((oldState) => ({ ...oldState, selectedMedia })),
  allMedia: [],
  setAllMedia: (allMedia) => set((oldState) => ({ ...oldState, allMedia })),
  selectedArray: 0,
  setSelectedArray: (selectedArray: number) =>
    set((oldState) => ({ ...oldState, selectedArray })),
});

export default createMediaSlice;
