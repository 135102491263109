import type { CartItem } from "../centra/selection/selectionFormatter";
import { useChangeQuantity } from "./useSelection";
import useWishlist from "./useWishlist";

export type ICartItemWithHooks = ReturnType<typeof formatItem>;
export type ICartItems = ReturnType<typeof useItems>;

const formatItem = (
  item: CartItem,
  remove: (item: CartItem) => void,
  decrement: (item: CartItem) => void,
  increment: (item: CartItem) => void,
  moveToWishlist: (item: CartItem) => void
) => {
  return {
    ...item,
    remove: () => remove(item),
    increment: () => increment(item),
    decrement: () => decrement(item),
    moveToWishlist: () => moveToWishlist(item),
    inCart: item.quantity > 0,
  };
};

const useItems = (items: CartItem[] | undefined) => {
  const { changeQuantity } = useChangeQuantity();
  const { add } = useWishlist();
  if (!items?.length) return null;

  const remove = (item: CartItem) =>
    changeQuantity.mutate({
      line: item.id,
      newQuantity: 0,
    });
  const increment = (item: CartItem) =>
    changeQuantity.mutate({
      line: item.id,
      newQuantity: item.quantity + 1,
    });
  const decrement = (item: CartItem) =>
    changeQuantity.mutate({
      line: item.id,
      newQuantity: item.quantity - 1,
    });
  const moveToWishlist = (item: CartItem) => {
    remove(item);
    add(item.displayId)();
  };

  const newItems = items.map((item) =>
    formatItem(item, remove, decrement, increment, moveToWishlist)
  );
  return newItems;
};

export default useItems;
