import type { SbBlokData } from "@storyblok/react";

import useTagPosition from "@/lib/hooks/useTagPosition";
import type { ITag } from "@/lib/storyblok/useTaggedImage";
import useTaggedImage from "@/lib/storyblok/useTaggedImage";
import type { StoryBlokMedia } from "@/types/storyblok";

import UI from "./UI";

export interface TaggedImageBlok extends SbBlokData {
  products: {
    tags: ITag[];
    selected: number[];
    media: StoryBlokMedia;
  };
}

export interface TaggedImageProps {
  blok: TaggedImageBlok;
}

const TaggedImage = ({ blok }: TaggedImageProps) => {
  const tags = useTaggedImage(blok);
  const { containerRef, infoPosition } = useTagPosition();

  return (
    <UI
      tags={tags}
      blok={blok}
      containerRef={containerRef}
      infoPosition={infoPosition}
    />
  );
};

export default TaggedImage;
