import type {
  ICentraProductNoRelationsSinglePrices,
  ICentraProductSinglePrices,
  ProductPricesWithDiscount,
} from "@/types/product";

import type {
  ICentraProduct,
  ICentraProductNoRelations,
} from "../rorosTweed/productPage";

const isSinglePrice = (
  product: ICentraProduct | ICentraProductNoRelationsSinglePrices
): product is
  | ICentraProductSinglePrices
  | ICentraProductNoRelationsSinglePrices => {
  const isSingle = "price" in product;
  return isSingle;
};

const calculateDiscountPercentage = (original: number, current: number) => {
  const difference = original - current;
  const proportion = difference / original;
  const percentage = Math.round(proportion * 100);

  return percentage;
};

export const getDisplayPricesWithSale = (
  product: ICentraProduct | ICentraProductNoRelations
): ProductPricesWithDiscount[] => {
  if (!isSinglePrice(product)) {
    const pricelists = Object.entries(product.prices).map((price) => ({
      currency: price?.[1]?.price?.match(/[a-zA-Z]+$/)?.[0],

      pricelistId: price[0],
      price: price[1].price,
      priceAsNumber: price[1].priceAsNumber,
      priceBeforeDiscount: price[1].priceBeforeDiscount,
      priceBeforeDiscountAsNumber: price[1].priceBeforeDiscountAsNumber,
      discountPercent: calculateDiscountPercentage(
        price[1].priceBeforeDiscountAsNumber,
        price[1].priceAsNumber
      ),
      productOnSale: price[1].showAsOnSale,
    }));
    return pricelists;
  }

  const price = {
    currency: product.price?.match(/[a-zA-Z]+$/)?.[0],
    price: product.price,
    priceAsNumber: product.priceAsNumber,
    priceBeforeDiscount: product.priceBeforeDiscount,
    priceBeforeDiscountAsNumber: product.priceBeforeDiscountAsNumber,
    discountPercent: calculateDiscountPercentage(
      product.priceBeforeDiscountAsNumber,
      product.priceAsNumber
    ),
    productOnSale: product.showAsOnSale,
  };
  return [price];
};
