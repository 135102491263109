import { useState } from "react";

import useDevice from "@/lib/hooks/useDevice";
import { useDevStore } from "@/lib/stateManagement/devState/devStore";

import Overlay from "../Overlay";
import EnvironmentMenu from "./EnvironmentMenu";
import Icon from "./Icon";
import OverlayMenu from "./OverlayMenu";
import styles from "./toolkit.module.scss";

type Props = {
  children?: React.ReactNode;
};

const Toolkit: React.FC<Props> = ({ children }) => {
  const development = process.env.NODE_ENV === "development";

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const { overlayDisplay, overlayInvert, overlayEdge } = useDevStore(
    (state) => ({
      overlayDisplay: state.overlayDisplay,
      overlayInvert: state.overlayInvert,
      overlayEdge: state.overlayEdge,
    })
  );

  const { desktop } = useDevice();

  return (
    <>
      {development && (
        <div className={styles.toolkit}>
          <div className={styles.menu}>
            {menuOpen && (
              <>
                <EnvironmentMenu />
                <OverlayMenu />
              </>
            )}
            <Icon menuToggle={toggleMenu} menuOpen={menuOpen} />
          </div>
          <Overlay
            visible={overlayDisplay}
            invert={overlayInvert}
            edge={overlayEdge}
            desktop={desktop}
          />
        </div>
      )}
      {children}
    </>
  );
};

export default Toolkit;
