import AnimationWrapper from "../AnimationWrapper";
import type { animationDirections } from "../AnimationWrapper/AnimationWrapper";
import type { InViewHook } from "./Animated";
import styles from "./animated.module.scss";
interface UIProps {
  observer: InViewHook;
  children: React.ReactNode;
  duration: number;
  delay: number;
  direction?: animationDirections;
  size: "fit" | "fill";
}

const UI = ({
  observer,
  children,
  duration,
  delay,
  size,
  direction = "bottom",
}: UIProps) => {
  const { ref, inView } = observer;

  if (!duration) return <>{children}</>;

  return (
    <div ref={ref} className={styles[size]} style={{ opacity: inView ? 1 : 0 }}>
      <AnimationWrapper
        direction={direction}
        isVisible={inView}
        duration={duration}
        delay={delay}
        shouldRender={true}
      >
        {children}
      </AnimationWrapper>
    </div>
  );
};

export default UI;
