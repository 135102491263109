import StoryblokMedia from "@/components/StoryblokMedia";

import AccordionContainer from "./AccordionContainer";
import AccordionBlok from "./AccordionContainer/AccordionBlok";
import Article from "./Article";
import CuratedArticles from "./CuratedArticles";
import CuratedGrid from "./CuratedGrid";
import Designers from "./Designers/Designers";
import Details from "./Details";
import Discover from "./Discover/Discover";
import Favourites from "./Favourites";
import FeaturedProducts from "./FeaturedProducts";
import HeroWithText from "./HeroWithText";
import HighlightedMedia from "./HighlightedMedia";
import MobileNavigation from "./Navigation/MobileNavigation";
import PrimaryNavigation from "./Navigation/PrimaryNavigation";
import SecondaryNavigation from "./Navigation/SecondaryNavigation";
import SubMenu from "./Navigation/SubMenu";
import Page from "./Page";
import Quote from "./Quote";
import Retailers from "./Retailers/Retailers";
import RichText from "./RichText/RichText";
import Search from "./Search";
import SideBySide from "./SideBySide/SideBySide";
import SideBySideProduct from "./SideBySideProduct/SideBySideProduct";
import StoryblokLink from "./StoryblokLink/StoryblokLink";
import TaggedImage from "./TaggedImage";
import TextContainer from "./TextContainer";
import TextHeader from "./TextHeader/TextHeader";

const components = {
  hero: HeroWithText,
  page: Page,
  link: StoryblokLink,
  media: StoryblokMedia,
  "primary-navigation": PrimaryNavigation,
  "secondary-navigation": SecondaryNavigation,
  "mobile-navigation": MobileNavigation,
  richText: RichText,
  subMenu: SubMenu,
  taggedImage: TaggedImage,
  highlightedMedia: HighlightedMedia,
  sideBySide: SideBySide,
  details: Details,
  curatedArticles: CuratedArticles,
  quote: Quote,
  textContainer: TextContainer,
  accordion: AccordionBlok,
  accordionContainer: AccordionContainer,
  featuredProducts: FeaturedProducts,
  article: Article,
  sideBySideProduct: SideBySideProduct,
  textHeader: TextHeader,
  archive: Page,
  retailers: Retailers,
  designers: Designers,
  curatedArchive: Page,
  discover: Discover,
  favourites: Favourites,
  search: Search,
  curatedGrid: CuratedGrid,
};

export { components };
