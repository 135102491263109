import { useEffect, useRef, useState } from "react";

import config from "@/config";

import type { TaggedImageArray } from "../storyblok/useTaggedImage";

const useTagPosition = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<DOMRect | null | undefined>(null);

  useEffect(() => {
    window?.addEventListener("resize", () =>
      setContainer(containerRef?.current?.getBoundingClientRect())
    );
    setContainer(containerRef?.current?.getBoundingClientRect());
  }, []);

  const infoPosition = (tag: TaggedImageArray[number]) => {
    const { x, y } = tag.position;
    const tagsize = config.taggedImageSize;
    const { width, height } = container || {
      width: 0,
      height: 0,
    };
    const onePercentW = width / 100;
    const onePercentH = height / 100;

    const getY = () => {
      let position = 25;
      if (y * onePercentH + tagsize.height > height) {
        position = -115;
      }

      return position;
    };

    const getX = () => {
      let position = 5;
      if (onePercentW * x + tagsize.width > width) {
        position = -295;
      }
      if (
        onePercentW * x + tagsize.width > width &&
        tagsize.width > onePercentW * x
      ) {
        position = -150;
      }

      return position;
    };

    return {
      x: getX(),
      y: getY(),
    };
  };

  return {
    containerRef,
    infoPosition,
  };
};

export default useTagPosition;

export type TagPosition = ReturnType<typeof useTagPosition>;
export type InfoPosition = TagPosition["infoPosition"];
