import Image from "next/legacy/image";

import type { StoryBlokVideo } from "@/types/storyblok";

import styles from "./video.module.scss";

interface UIProps {
  video: StoryBlokVideo;
}

const UI = ({ video }: UIProps) => {
  return (
    <div
      className={`${styles.container} ${styles.video} `}
      key={video.playerId}
    >
      <div ref={video.wrapperRef} className={styles.videobg}>
        {video.thumbnail && (
          <Image
            src={video.thumbnail}
            alt={video?.playerId}
            layout="fill"
            objectFit="cover"
            style={{
              opacity: video.loaded ? 0 : 1,
              transition: "opacity 0.2s ease-out",
              zIndex: 10,
            }}
          />
        )}
        <div
          className={`${styles.videobgWidth} ${
            video.loaded ? styles.fadeIn : ""
          }`}
          ref={video.playerRef}
          id={video.playerId}
          style={video.style}
        ></div>
      </div>
    </div>
  );
};

export default UI;
