import type { ImageProps } from "next/legacy/image";

import UI from "./UI";

export interface CentraImageProps extends Omit<ImageProps, "src"> {
  src: string | undefined;
}

const CentraImage = (props: CentraImageProps) => {
  return <UI {...props} />;
};

export default CentraImage;
