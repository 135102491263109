import ArticleCard from "@/bloks/ArticleCard";
import type { ArticleStory } from "@/bloks/ArticleCard/ArticleCard";
import ArchiveGrid from "@/components/ArchiveGrid";
import getRowIndex from "@/lib/utils/getRowIndex";

interface UIProps {
  designers?: ArticleStory[];
}

const UI = ({ designers }: UIProps) => {
  if (!designers) return null;
  return (
    <ArchiveGrid>
      {designers?.map((designer, i) => (
        <ArticleCard
          key={designer.content?._uid}
          story={designer}
          index={getRowIndex(i, 3)}
        />
      ))}
    </ArchiveGrid>
  );
};

export default UI;
