import Image from "next/legacy/image";

import type { CentraImageProps } from "./CentraImage";

const UI = (props: CentraImageProps) => {
  if (!props.src) return null;
  return (
    <Image
      {...props}
      src={props.src}
      unoptimized={true}
      layout="fill"
      objectFit="cover"
      alt={props.alt || ""}
    />
  );
};

export default UI;
