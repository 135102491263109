import axios from "axios";
import { useEffect, useState } from "react";

import type { StoryBlokMedia } from "@/types/storyblok";

import getVideoId from "./getVideoId";

const getThumbnail = async (media: StoryBlokMedia) => {
  const videoId = getVideoId(media);
  const thumbnail = `/api/vimeo-thumbnail?video=${videoId}`;
  if (!videoId) return;
  const res = await axios.get(thumbnail);
  const data = await res.data;
  return data;
};

const useThumbnail = (video: StoryBlokMedia) => {
  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!video) return;
    getThumbnail(video).then((data) => setThumbnail(data));
  }, [video]);

  return thumbnail;
};

export default useThumbnail;
