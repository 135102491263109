import { storyblokEditable } from "@storyblok/react";
import { useTranslations } from "next-intl";

import Accordion from "@/bloks/AccordionContainer/AccordionBlok";
import Button from "@/components/Button";

import type { AccordionBlok } from "./AccordionBlok/Accordion";
import type { AccordionContainerBlok } from "./AccordionContainer";
import styles from "./accordionContainer.module.scss";

interface UIProps {
  blok: AccordionContainerBlok;
  accordionState: {
    showAll: boolean;
    toggle: () => void;
  };
  accordions: AccordionBlok[];
}

const UI = ({ blok, accordionState, accordions }: UIProps) => {
  const t = useTranslations();
  return (
    <div
      className={styles.grid}
      style={{ backgroundColor: blok?.backgroundColor?.color || "" }}
    >
      <div className={`${styles.container}  ${styles[blok?.position]}`}>
        <h4 className={styles.title}>{blok?.title}</h4>
        {accordions?.map((accordionBlok) => (
          <Accordion
            blok={accordionBlok}
            key={accordionBlok?._uid}
            {...storyblokEditable(accordionBlok)}
          />
        ))}

        {!accordionState.showAll && (
          <Button onClick={accordionState.toggle} className={styles.button}>
            {t("Showall")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default UI;
