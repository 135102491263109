import { useRouter } from "next/router";

import useStore from "@/lib/stateManagement/store";
import useStaticStory from "@/lib/storyblok/useStaticStory";
import type { NavigationBlok, NavigationProps } from "@/types/storyblok";

import UI from "./UI";

const PrimaryNavigation = ({ previewData }: NavigationProps) => {
  const { locale } = useRouter();
  const preview = useStore((state) => state.preview);

  const { blok } = useStaticStory({
    slug: "primary-navigation",
    preview,
    folder: "statics",
    previewData,
    locale,
  });

  return <UI blok={blok as NavigationBlok} />;
};

export default PrimaryNavigation;
