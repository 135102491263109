import type { InfiniteData } from "@tanstack/react-query";

import type { IFilterResponse } from "./useProductFilter";

const productReducer = <T>(data: InfiniteData<IFilterResponse>): T[] => {
  const products = data.pages.reduce(
    (previousValue: T[], currentValue: IFilterResponse): T[] => {
      if (currentValue.products.length > 0) {
        return [...previousValue, ...currentValue.products];
      }
      return previousValue;
    },
    []
  );

  return products;
};

export default productReducer;
