import Link from "next/link";

import Skeleton from "@/components/Skeleton";
import type { IProductCardVariant } from "@/lib/api/formatting/rorosTweed/productCard";

import ProductCardDetails from "../ProductCardDetails";
import ProductCardGallery from "../ProductCardGallery";
import styles from "./productCard.module.scss";

type ProductCardUI = IProductCardVariant & {
  desktop: boolean;
};

const UI = ({
  id,
  uri,
  media,
  info,

  desktop,
}: ProductCardUI) => {
  return (
    <article className={`${styles.container} product-card`} key={id}>
      <Link href={`/product/${uri}`} className={styles.link}>
        <div className={styles.wrapper}>
          <div className={styles.imageWrapper}>
            <ProductCardGallery {...media} />
            <Skeleton height="100%" />
          </div>
          <div className={styles.details}>
            <ProductCardDetails {...info} desktop={desktop} />
          </div>
        </div>
      </Link>
    </article>
  );
};

export default UI;
