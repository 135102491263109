import type { ISBInternalLink, IStoryblokLink } from "@/types/storyblok";

import config from "../../../next-i18next.config";

const { i18n } = config;

const formatUrlLink = (link: IStoryblokLink) => {
  return link.url;
};

const checkIfInfoPage = (link: string) => {
  const infoPagesRegex = /category(\/.+)$/i;
  const isInfoPage = link.match(infoPagesRegex);

  const url = isInfoPage && isInfoPage[1] ? `${isInfoPage[1]}` : null;

  return url;
};

const checkIfHome = (link: string) => {
  const homeRegex = /\/?home\/?/i;
  const isHome = link.match(homeRegex);
  const url = isHome && isHome[0] ? "/" : null;

  return url;
};
const getStoryUrl = (link: ISBInternalLink) => {
  return link?.story?.full_slug || "";
};

const internalFormatStoryLink = (link: ISBInternalLink) => {
  const url = getStoryUrl(link);
  if (checkIfInfoPage(url)) return checkIfInfoPage(url) as string;
  if (checkIfHome(url)) return checkIfHome(url) as string;

  return `/${url}`;
};

const stripLocale = (url: string) => {
  const localeInUrl = i18n?.locales?.find((locale) =>
    url.startsWith(`/${locale}`)
  );
  if (localeInUrl) {
    const urlWithoutLocale = url.replace(`/${localeInUrl}`, "");

    return urlWithoutLocale;
  }

  return url;
};

const stripDeadSlugs = (url: string) => {
  const slugsToStrip = ["/info-pages/"];

  return slugsToStrip.reduce((acc, slug) => {
    if (url.startsWith(slug)) {
      return url.replace(slug, "/");
    }
    return acc;
  }, url);
};

const formatStoryLink = (link: ISBInternalLink) => {
  const url = internalFormatStoryLink(link);
  const urlWithoutLocale = stripLocale(url);
  const urlWithoutDeadSlugs = stripDeadSlugs(urlWithoutLocale);

  return urlWithoutDeadSlugs;
};

const getLink = (link: IStoryblokLink | undefined) => {
  if (link?.linktype === "url") return formatUrlLink(link);
  if (link?.linktype === "story") return formatStoryLink(link);

  return "";
};

export default getLink;
