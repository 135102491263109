import type { InfoPosition } from "@/lib/hooks/useTagPosition";
import type { TaggedImageType } from "@/lib/storyblok/useTaggedImage";

import UI from "./UI";

const Tag = ({
  tag,
  infoPosition,
}: {
  tag: TaggedImageType;
  infoPosition: InfoPosition;
}) => {
  return <UI tag={tag} infoPosition={infoPosition} />;
};

export default Tag;
