import Link from "next/link";
import { useRouter } from "next/router";

import styles from "./previewBanner.module.scss";

const PreviewBanner = () => {
  const { isPreview } = useRouter();
  if (!isPreview) return null;
  return (
    <div className={styles.container}>
      <p>Du er i Preview mode</p>
      <Link href="/api/exit-preview" prefetch={false}>
        Forlat Preview mode
      </Link>
    </div>
  );
};

export default PreviewBanner;
