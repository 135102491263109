import _ from "lodash";
import { useState } from "react";

import type { ICentraFilter } from "@/types/centra";

import useQueryState, { useSetQueryState } from "./useQueryState";
export type IFilterValues = ReturnType<typeof useFilterValue>;

export const useFilterValue = (
  name: string,
  field: string,
  labelField?: string,
  required?: boolean,
  callback?: (values: ICentraFilter[]) => ICentraFilter[]
) => {
  const [values, setValues] = useState<string[]>([]);

  const setQueryState = useSetQueryState();

  useQueryState(name, setValues);

  const modifyValue = (value: string, add: boolean, remove: boolean) => {
    const filteredValues = values.filter((v: string) => !_.isEqual(v, value));

    if (filteredValues.length < values.length) {
      if (remove) {
        setValues(filteredValues);
        setQueryState(name, filteredValues);
      }
      return;
    }
    if (add) {
      setValues([...values, value]);
      setQueryState(name, [...values, value]);
    }
  };
  const toggle = (value: string) => modifyValue(value, true, true);

  const add = (value: string) => modifyValue(value, true, false);

  const remove = (value: string) => modifyValue(value, false, true);

  const clear = () => {
    setValues([]);
  };

  return {
    name,
    field,
    required,
    callback,
    values: values.length > 0 ? values : undefined,
    toggle,
    add,
    remove,
    clear,
    labelField,
  };
};
