import type { ISbStoryData, SbBlokData } from "@storyblok/react";
import { getStoryblokApi } from "@storyblok/react";

const getGlobalResolveRelations = () => [
  "curatedArticles.articles",
  "curatedGrid.content",
];

const getStory = async <T = SbBlokData>(props: {
  slug: string;
  folder?: string;
  preview?: boolean;
  revalidate?: boolean;
  locale?: string;
}) => {
  const {
    slug,
    folder,
    preview = false,
    revalidate = false,
    locale = "en",
  } = props;
  const storyblokApi = getStoryblokApi();
  if (revalidate) {
    storyblokApi.setCacheVersion(0);
  }
  // eslint-disable-next-line no-console

  try {
    const url = folder ? `${folder}/${slug}` : slug;
    const response = await storyblokApi.get(`cdn/stories/${url}`, {
      version: preview ? "draft" : "published",
      resolve_links: "url",
      resolve_relations: [...getGlobalResolveRelations()],
      language: locale,
    });
    const { data } = response;

    const story = (await data.story) as ISbStoryData<T>;
    return story;
  } catch (e) {
    return undefined;
  }
};

export default getStory;
