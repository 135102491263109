import { useRouter } from "next/router";

import config from "@/config";
import type { ILanguage } from "@/types/centra";

import useChangeLocation from "./useChangeLocation";
import useModal from "./useModal";
import useSelection from "./useSelection";

export type CountryType = {
  changeLocation: (stateId?: string) => void;
  code: string;
  currency: string;
  default: boolean;
  country: string;
  language: string;
  name: string;
  pricelist: string;
  states?: {
    state: string;
    name: string;
  }[];
};

const useLocation = () => {
  const { changeLocation } = useChangeLocation();
  const { data, isLoading } = useSelection();
  const router = useRouter();
  const modal = useModal("locationMenu", () => {});
  const { locale } = router;
  if (isLoading) return { countries: [], pricelist: `${config.priceListId}` };
  const languages = data?.languages;
  const countries = data?.countries;
  const currency = data?.selection?.currency;
  const location = data?.location?.country;
  const country = data?.location?.name;

  const pricelist = data?.location?.pricelist || `${config.priceListId}`;
  const language = data?.languages
    .find((lang: ILanguage) => lang.language === locale || lang.default)
    ?.language.toUpperCase();
  const newCountries = countries?.map((country) => {
    return {
      ...country,
      changeLocation: (stateId?: string) => {
        changeLocation.mutate(
          stateId
            ? { locationId: country.country, stateId }
            : { locationId: country.country }
        );
        modal.closeModal();
      },
      selected: country.country === location,
    };
  });
  const currentCountry = newCountries?.find((country) => country.selected);
  const states = currentCountry?.states;
  const state = data?.location?.state;

  return {
    location,
    country,
    languages,
    countries: newCountries || undefined,
    currency,
    language,
    modal,
    pricelist,
    states,
    currentCountry,
    state,
  };
};

export default useLocation;
