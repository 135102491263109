import type { ArticleStory } from "@/bloks/ArticleCard/ArticleCard";

import UI from "./UI";

interface DesignerGridProps {
  designers?: ArticleStory[];
}

const DesignerGrid = ({ designers }: DesignerGridProps) => {
  const designersFormatted = designers?.map((designer) => ({
    ...designer,
    content: {
      ...designer.content,
      subHeader: "",
    },
  }));
  return <UI designers={designersFormatted} />;
};

export default DesignerGrid;
