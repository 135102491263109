import { useEffect } from "react";

import MinusSvg from "@/public/svg/24minus.svg";
import PlusSvg from "@/public/svg/24plus.svg";

import styles from "./accordion.module.scss";
interface UIProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  accordionRef: React.MutableRefObject<HTMLDetailsElement | null>;
  title: string;
}

const UI = ({ children, title, isOpen, setIsOpen, accordionRef }: UIProps) => {
  useEffect(() => {
    if (!accordionRef?.current) return;
    accordionRef?.current?.addEventListener("toggle", (e) =>
      setIsOpen((e.target as HTMLDetailsElement).open)
    );

    return () => {
      accordionRef?.current?.removeEventListener("toggle", (e) =>
        setIsOpen((e.target as HTMLDetailsElement).open)
      );
    };
  }, [accordionRef?.current]);

  return (
    <details
      className={styles.container}
      style={{
        overflow: "hidden",
        animation: "all 0.3s ease-in-out",
      }}
      ref={accordionRef}
    >
      <summary className={`${styles.title} ${isOpen && styles.active}`}>
        {title}
        <span>
          {isOpen ? (
            <MinusSvg aria-label="close" />
          ) : (
            <PlusSvg aria-label="open" />
          )}
        </span>
      </summary>
      <div>{children}</div>
    </details>
  );
};

export default UI;
