import type { SbBlokData } from "@storyblok/react";
import axios from "axios";
import { useRouter } from "next/router";
import { useState } from "react";

import useInput from "@/lib/hooks/useInput";
import useModal from "@/lib/hooks/useModal";
import useStore from "@/lib/stateManagement/store";
import useStaticStory from "@/lib/storyblok/useStaticStory";

import UI from "./UI";

interface NewsletterSignupProps {
  previewData?: {
    [key: string]: SbBlokData;
  };
}

const NewsletterSignup = ({ previewData }: NewsletterSignupProps) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const preview = useStore((state) => state.preview);
  const modal = useModal("newsletter-signup");
  const email = useInput("email");
  const apiUrl = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

  const handleEmailSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    try {
      const res = await axios.post(
        `${apiUrl}/newsletter-subscription/${email.value}`,
        {}
      );

      if (res.status === 201) {
        setSuccess(true);
        email.clear();
        return;
      }
      setError(true);
      return;
    } catch (err) {
      setError(true);
      return;
    }
  };
  const { locale } = useRouter();
  const { blok } = useStaticStory({
    slug: "newsletter-signup",
    preview,
    folder: "statics",
    locale,
    previewData,
  });

  if (!blok || !blok?.showOnPage) return null;

  setTimeout(() => {
    if (previewData || !preview) {
      modal.openModal();
    }
  }, 7000);

  return (
    <UI
      blok={blok}
      modal={modal}
      email={email}
      handleSubmit={handleEmailSubmit}
      success={success}
      error={error}
    />
  );
};

export default NewsletterSignup;
