import useDevice from "@/lib/hooks/useDevice";
import type { IVariantCard } from "@/types/product";

import UI from "./UI";

const SingleProductCard = ({ product }: { product: IVariantCard }) => {
  const { desktop } = useDevice();

  return <UI {...product} desktop={desktop} />;
};

export default SingleProductCard;
