import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

import StoryblokLink from "@/bloks/StoryblokLink/StoryblokLink";
import type { SubmenuType } from "@/lib/storyblok/useSubmenu";
import type { IStoryBlokMenu } from "@/types/storyblok";

import styles from "./storyblokMenu.module.scss";

const UI = ({
  blok,
  submenu,
}: {
  blok: IStoryBlokMenu;
  submenu: SubmenuType;
}) => {
  const label = blok?.label[0];
  if (!label) return null;

  return (
    <div
      key={blok?._uid}
      {...storyblokEditable(blok)}
      className={styles.container}
    >
      <span className={styles.label}>
        <StoryblokLink blok={label} key={label?._uid} />
      </span>
      <div className={styles.submenu}>
        {submenu.showSubmenu &&
          submenu?.links.map((link) => (
            <Link
              href={link.link}
              key={link?._uid}
              className={`${link.selected && styles.selected}`}
            >
              {link.text}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default UI;
