import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

export interface PageProps {
  blok: Blok;
}

export interface Blok extends SbBlokData {
  body: SbBlokData[];
  metadata: {
    title: string;
    description: string;
    og_title: string;
    og_description: string;
    og_image: string;
  };
}

const Page = ({ blok }: PageProps) => {
  return (
    <main key={blok?._uid} {...storyblokEditable(blok)}>
      {blok.body
        ? blok.body.map((currentBlok) => (
            <StoryblokComponent blok={currentBlok} key={currentBlok?._uid} />
          ))
        : null}
    </main>
  );
};
export default Page;
