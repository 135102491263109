import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import type { StoryblokLinkBlok } from "@/bloks/StoryblokLink/StoryblokLink";
import Accordion from "@/components/Accordion";
import type { DeviceType } from "@/lib/hooks/useDevice";
import type { ISubMenu } from "@/types/storyblok";

import styles from "./subMenu.module.scss";

interface UIProps {
  blok: ISubMenu;
  device: DeviceType;
}

type MenuItem = StoryblokLinkBlok;

const UI = ({ blok, device }: UIProps) => {
  if (device.mobile) {
    return (
      <div
        key={blok?._uid}
        {...storyblokEditable(blok)}
        className={styles.container}
      >
        <Accordion title={blok.title}>
          <div className={styles.itemsContainer}>
            {blok?.items?.map((item: MenuItem) => (
              <StoryblokComponent
                className={styles.link}
                blok={item}
                key={item?._uid}
                {...storyblokEditable(item)}
              />
            ))}
          </div>
        </Accordion>
      </div>
    );
  }
  return (
    <div
      key={blok?._uid}
      {...storyblokEditable(blok)}
      className={styles.container}
    >
      {blok.title && <h2 className={styles.title}>{blok.title}</h2>}
      {blok?.items?.map((item: MenuItem) => (
        <StoryblokComponent
          className={styles.link}
          blok={item}
          key={item?._uid}
          {...storyblokEditable(item)}
        />
      ))}
    </div>
  );
};

export default UI;
