import UI from "./UI";
interface ButtonProps {
  children: React.ReactNode;
  onClick?: ((e?: unknown) => void) | undefined;
  className?: string;
  type?: "button" | "submit" | "reset";
}

const Button = ({ children, onClick, className, type }: ButtonProps) => {
  return (
    <UI className={className} onClick={onClick} type={type}>
      {children}
    </UI>
  );
};

export default Button;
