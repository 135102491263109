import dynamic from "next/dynamic";

import Footer from "@/components/Layout/Footer";
import type { ICart } from "@/types/centra";

import MobileTag from "../MobileTag";
import NewsletterSignup from "../NewsletterSignup";
import Header from "./Header";
import styles from "./layout.module.scss";
import PreviewBanner from "./PreviewBanner/PreviewBanner";
const DynamicCart = dynamic(() => import("@/components/Cart"));
type Props = {
  children?: React.ReactNode;
  cart: ICart;
  cookie: string | null;
};

const UI: React.FC<Props> = ({ children, cart, cookie }) => {
  return (
    <>
      <PreviewBanner />
      {/* <Snackbar /> there is no snackbar in design */}
      {cookie && <></> /*Popup newsletter modal*/}
      <NewsletterSignup />
      <Header />
      <MobileTag />
      {cart.shouldRender && <DynamicCart />}
      <div id="site-content" className={`site-content ${styles.wrapper}`}>
        {children}
      </div>
      <Footer />
    </>
  );
};

export default UI;
