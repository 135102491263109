import type { StateCreator } from "zustand";

import type { IState } from "../store";

export interface IModalState {
  modalId: string;
  setModal: (modalId: string, modalCallback: () => void) => void;
  clearModal: () => void;
  modalCallback: () => void;
  shownModals: string[];
  updateShownModals: (shownModals: string[]) => void;
  addShownModal: (modalId: string) => void;
}

const createModalSlice: StateCreator<IState, [], [], IModalState> = (
  set,
  _get
) => ({
  modalId: "",
  modalCallback: () => {},
  setModal: (modalId: string, modalCallback) =>
    set((oldState) => {
      oldState.modalCallback();
      return { ...oldState, modalId, modalCallback };
    }),
  clearModal: () =>
    set((oldState) => {
      oldState.modalCallback();
      return { ...oldState, modalCallback: () => {}, modalId: "" };
    }),
  shownModals: [],
  updateShownModals: (shownModals: string[]) =>
    set((oldState) => ({ ...oldState, shownModals })),
  addShownModal: (modalId: string) =>
    set((oldState) => ({
      ...oldState,
      shownModals: [...oldState.shownModals, modalId],
    })),
});

export default createModalSlice;
