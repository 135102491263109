import ArchiveGrid from "@/components/ArchiveGrid";

import ArticleCard from "../ArticleCard";
import type { ArticleStory } from "../ArticleCard/ArticleCard";

type UIProps = {
  content: ArticleStory[];
};

const UI = ({ content }: UIProps) => {
  return (
    <ArchiveGrid>
      {content?.map((item) => (
        <ArticleCard story={item} key={item.content?._uid} />
      ))}
    </ArchiveGrid>
  );
};

export default UI;
