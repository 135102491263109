import { useRouter } from "next/router";

import type { IProductCardInfo } from "@/lib/api/formatting/rorosTweed/productCard";
import getPriceList from "@/lib/centra/getPriceList";
import useDevice from "@/lib/hooks/useDevice";
import useLocation from "@/lib/hooks/useLocation";

import UI from "./UI";

export type IProductCardDetails = IProductCardInfo & {
  desktop: boolean;
};

const ProductCardDetails = ({
  name,
  variantName,
  type,
  variantCount,
  swatches,
  prices,

  wishlist,
}: IProductCardDetails) => {
  const { pricelist } = useLocation();
  const { desktop } = useDevice();
  const { pathname } = useRouter();
  const favourites = pathname === "/favourites";

  const price =
    prices && pricelist
      ? getPriceList(prices, +pricelist).price
      : "No price found";
  const uiProps = {
    name,
    variantName,
    type,
    variantCount,
    swatches,
    price,

    desktop,
    wishlist,
    favourites,
  };
  return <UI {...uiProps} />;
};

export default ProductCardDetails;
