import type { SbBlokData } from "@storyblok/react";
import { useEffect, useState } from "react";

import useCart from "@/lib/hooks/useCart";
import useDevice from "@/lib/hooks/useDevice";
import useMobileMenu from "@/lib/hooks/useMobileMenu";

import UI from "./UI";

interface HeaderProps {
  previewData?: {
    [key: string]: SbBlokData;
  };
}

const Header = ({ previewData }: HeaderProps) => {
  const device = useDevice();
  const cart = useCart();
  const mobileMenu = useMobileMenu(previewData);

  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [newScrollPosition, setNewScrollPosition] = useState<number>(0);
  const [hide, setHide] = useState(false);
  const handleScroll = () => {
    setNewScrollPosition(window.scrollY);
  };
  useEffect(() => {
    setHide(newScrollPosition > scrollPosition);
    setScrollPosition(newScrollPosition);
  }, [newScrollPosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <UI
      cart={cart}
      previewData={previewData}
      device={device}
      mobileMenu={mobileMenu}
      hide={hide}
    />
  );
};

export default Header;
