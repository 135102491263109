import { isFullProductCard } from "@/types/narrowingFunctions";
import type {
  IProductCard,
  IVariantCard,
  ProductCardTypes,
} from "@/types/product";

import FullProductCard from "./FullProductCard";
import SingleProductCard from "./SingleProductCard";

const ProductCard = <T extends ProductCardTypes = "full">({
  product,
  className,
}: {
  type?: T;
  product: T extends "single" ? IVariantCard : IProductCard;
  className?: string;
}) => {
  if (isFullProductCard(product)) {
    return <FullProductCard product={product} className={className} />;
  }
  return <SingleProductCard product={product} />;
};

export default ProductCard;
