import UI from "./UI";

interface UIProps {
  filter: {
    options: string[];
    active: string;
    callback: (value: string) => void;
  };
}

const RetailersFilter = ({ filter }: UIProps) => {
  return <UI filter={filter} />;
};

export default RetailersFilter;
