import config from "@/config";
import type { IProductCard } from "@/types/product";

import useStore from "../stateManagement/store";

export type ProductCardWishlist = {
  active: boolean;
  toggle: () => void;
};
type SingleVariantCard = {
  id?: string;
  info?: {
    wishlist?: ProductCardWishlist;
    [index: string]: unknown;
  };
};

type MultiVariantCard = {
  id?: string;
  variants: {
    default: string;
    variants: {
      [index: string]: {
        id?: string;
        info?: {
          wishlist?: ProductCardWishlist;
          [index: string]: unknown;
        };
      };
    };
  };
};

type UseWishlist = typeof useWishlistLocal;
type AddWishlist = (products: IProductCard[]) => IProductCard[];

const variantCardWishlistBuilder =
  <T extends SingleVariantCard[] | undefined>(
    includes: (id: string) => boolean,
    toggle: (id: string) => () => void
  ) =>
  (products: T) =>
    products
      ? products?.map((product) =>
          !product?.id || !product?.info
            ? product
            : {
                ...product,
                info: {
                  ...product.info,
                  wishlist: {
                    active: includes(product.id),
                    toggle: toggle(product.id),
                  },
                },
              }
        )
      : undefined;

const productCardWishlistBuilder =
  <T extends MultiVariantCard[]>(
    includes: (id: string) => boolean,
    toggle: (id: string) => () => void
  ) =>
  (products: T) =>
    products?.map((product) => {
      Object?.keys(product?.variants?.variants || {}).forEach((variant) => {
        let v = product?.variants?.variants[variant];
        if (v && v.id && v.info) {
          v.info.wishlist = { active: includes(v.id), toggle: toggle(v.id) };
        }
      });
      return product;
    });

const useWishlistLocal = () => {
  const ids = useStore((store) => store.wishlistIds);
  const removedIds = useStore((store) => store.wishlistRemoved);
  const addToWishlist = useStore((store) => store.wishlistAddId);
  const removeFromWishlist = useStore((store) => store.wishlistRemoveId);
  const restoreLastRemoved = useStore(
    (store) => store.wishlistRestoreLastRemoved
  );
  const clearAll = useStore((store) => store.wishlistClearAll);
  const clearRemoved = useStore((store) => store.wishlistClearRemoved);

  const includes = (id: string) => {
    const inWishlist = ids.includes(id);
    if (inWishlist) {
      return inWishlist;
    }
    return inWishlist;
  };

  const toggle = (id: string) =>
    includes(id) ? () => removeFromWishlist(id) : () => addToWishlist(id);

  const add = (id: string) =>
    includes(id) ? () => {} : () => addToWishlist(id);

  const addWishlistToVariantCards = variantCardWishlistBuilder(
    includes,
    toggle
  );

  const addWishlistToProductCards = productCardWishlistBuilder(
    includes,
    toggle
  );

  return {
    ids,
    removedIds,
    includes,
    toggle,
    add,
    restoreLastRemoved,
    clearAll,
    clearRemoved,
    addWishlistToVariantCards,
    addWishlistToProductCards:
      config.variantsPerDisplay === "multi"
        ? (addWishlistToProductCards as unknown as AddWishlist)
        : (addWishlistToVariantCards as unknown as AddWishlist),
  };
};

const useWishlistCentra = () => {};

const useWishlist =
  config.wishlist === "centra"
    ? (useWishlistCentra as UseWishlist)
    : useWishlistLocal;

export default useWishlist;
