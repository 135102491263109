import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import Animated from "@/components/Animation/Animated";
import ProductCard from "@/components/Product/ProductCard";
import type { IStoryBlokMedia } from "@/components/StoryblokMedia/StoryblokMedia";
import type { IProductCard } from "@/types/product";

import type { SideBySideProductBlok } from "./SideBySideProduct";
import styles from "./sideBySideProduct.module.scss";

interface UIProps {
  blok: SideBySideProductBlok;
  getStyles: (styles: SbBlokData, baseStyles?: string) => string;
  baseStyles: (string | undefined)[];
  containerStyles: string | undefined;
  product?: IProductCard;
  media?: IStoryBlokMedia;
}
const UI = ({
  blok,
  getStyles,
  baseStyles,
  containerStyles,

  product,
  media,
}: UIProps) => {
  if (!blok || !media) return null;
  return (
    <div
      className={`${containerStyles}`}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      <Animated size="fit">
        <div className={baseStyles[0]}>
          <div className={getStyles(media)}>
            <StoryblokComponent blok={media} />
          </div>
        </div>
      </Animated>
      {product && (
        <div className={baseStyles[1]}>
          <Animated delay={2} size="fill">
            <ProductCard product={product} className={styles.productCard} />
          </Animated>
        </div>
      )}
    </div>
  );
};

export default UI;
