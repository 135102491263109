import styles from "./button.module.scss";

interface UIProps {
  children: React.ReactNode;
  onClick?: ((e?: unknown) => void) | undefined;
  className?: string;
  type?: "button" | "submit" | "reset";
}

const UI = ({ children, onClick, className, type }: UIProps) => {
  return (
    <button
      onClick={onClick}
      className={`${className || styles.button}`}
      type={type}
    >
      {children}
    </button>
  );
};

export default UI;
