import type { SbBlokData } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react";

import type { IModal } from "@/lib/hooks/useModal";
import stopPropagation from "@/lib/utils/stopPropagation";
import type { InputHook } from "@/types/utility";

import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import styles from "./newsletterSignup.module.scss";

interface NewsletterSignupBlok extends SbBlokData {
  title: string;
  text: string;
}

interface UIProps {
  blok: NewsletterSignupBlok;
  modal: IModal;
  email: InputHook;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  success: boolean;
  error: boolean;
}

const UI = ({ blok, modal, email, handleSubmit, success, error }: UIProps) => {
  if (!blok) return null;
  return (
    <Modal isOpen={modal.isOpen} close={modal.setAsShown}>
      <div className={styles.wrapper}>
        <form
          onSubmit={handleSubmit}
          className={styles.container}
          {...storyblokEditable(blok)}
          onClick={stopPropagation}
        >
          <h2 className={styles.title}>{blok.title}</h2>
          <p className={styles.text}>{blok.text}</p>
          <Input
            {...email}
            placeholder="Email"
            message={
              success ? "Thank you for subscribing!" : "Something went wrong"
            }
            required
            success={success}
            validate={error}
            disabled={success}
            isValid={success}
          />
          <Button type="submit">Submit</Button>
          <button className={styles.close} onClick={modal.setAsShown}>
            Close
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default UI;
