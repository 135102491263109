import type { SbBlokData } from "@storyblok/react";

import generateSBStyles from "@/lib/storyblok/generateSBStyles";

import styles from "./highlightedMedia.module.scss";
import UI from "./UI";

export interface HighlightedMediaBlok extends SbBlokData {
  media: SbBlokData[];
  color: {
    color: string;
  };
  gap: "small" | "medium" | "large";
}

export interface HighlightedMediaProps {
  blok: HighlightedMediaBlok;
}

const HighlightedMedia = ({ blok }: HighlightedMediaProps) => {
  const { getStyles } = generateSBStyles(styles);

  let baseStyles =
    blok.media.length > 1
      ? [styles.firstImage, styles.secondImage]
      : [styles.bigImage];

  if (blok.flip) {
    baseStyles = baseStyles.reverse();
    baseStyles[0] = `${baseStyles[0]} ${styles.flip}`;
  }

  const containerStyles =
    blok.media.length > 1 ? styles.container : styles.bigImageContainer;

  const margin = blok.color.color === "#0000" ? styles.small : styles.large;

  return (
    <UI
      blok={blok}
      getStyles={getStyles}
      baseStyles={baseStyles}
      margin={margin}
      containerStyles={containerStyles}
    />
  );
};

export default HighlightedMedia;
