import { verifyHexCodeNoAlpha } from "@/lib/utils/hexCodes";

import type {
  ICentraProduct,
  ICentraProductNoRelations,
} from "../rorosTweed/productPage";
import { getDisplayItemId } from "./productInfo";

export const buildVariantWithColorCodeWoodling = (
  product: ICentraProductNoRelations,
  active: boolean = false
) => {
  const variant = {
    colourCode: product.pr_swatch?.code
      ? verifyHexCodeNoAlpha(product.pr_swatch.code)
      : "",
    uri: product.uri,
    colourName: product.pr_swatch?.name || "",
    active,
  };

  return variant;
};

export const getVariantsWithColorCodeWoodling = (product: ICentraProduct) => {
  const activeVariant = buildVariantWithColorCodeWoodling(product, true);
  const inactiveVariants = product.relatedProducts
    ?.filter((relProduct) => relProduct.relation === "variant")
    .map((relProduct) => buildVariantWithColorCodeWoodling(relProduct));

  const variants = inactiveVariants
    ? [activeVariant, ...inactiveVariants]
    : [activeVariant];

  return variants.sort((a, b) => {
    if (a.uri > b.uri) return 1;
    return -1;
  });
};

export const getRorosUri = (product: ICentraProductNoRelations) => {
  const variant = product.variantName.toLowerCase().replace(/\W/g, "-");

  return `${product.uri}?variant=${variant}`;
};

export const buildVariantWithColorCodeRoros = (
  product: ICentraProductNoRelations,
  active: boolean = false
) => {
  const variant = {
    id: getDisplayItemId(product),
    colourCode: product.pr_swatch?.code
      ? verifyHexCodeNoAlpha(product.pr_swatch.code)
      : "",
    uri: getRorosUri(product),
    colourName: product.variantName || "",
    active,
  };

  return variant;
};

export type RorosVariants = (ReturnType<
  typeof buildVariantWithColorCodeRoros
> & {
  setActive?: () => void;
})[];

export const getVariantsWithColorCodeRoros = (product: ICentraProduct) => {
  const activeVariant = buildVariantWithColorCodeRoros(product);
  const inactiveVariants = product.relatedProducts
    ?.filter((relProduct) => relProduct.relation === "variant")
    .filter((relProduct) => !relProduct.dis_main_display_boolean)
    .map((relProduct) => buildVariantWithColorCodeRoros(relProduct));

  const variants = inactiveVariants
    ? [activeVariant, ...inactiveVariants]
    : [activeVariant];

  return variants.sort((a, b) => {
    if (a.uri > b.uri) return 1;
    return -1;
  });
};

export const getCurrentColorName = (product: ICentraProduct) => {
  const activeVariant = getVariantsWithColorCodeRoros(product).find(
    (variant) => variant.active
  );

  return activeVariant?.colourName || "";
};
