import type {
  CentraMediaType,
  ICentraProductAllPrices,
  ICentraProductSinglePrices,
  ProductPricesWithDiscount,
} from "@/types/product";

import type { ICentraProduct } from "../api/formatting/rorosTweed/productPage";
import getPriceList from "../centra/getPriceList";
import type { ISelectionItemProduct } from "../centra/selection/selectionFormatter";

export const getPrices = (
  product: ICentraProductSinglePrices,
  pricelistId?: number
) => {
  const allPrices = {
    price: product.price,
    priceBeforeDiscount: product.priceBeforeDiscount,
    priceBeforeDiscountAsNumber: product.priceBeforeDiscountAsNumber,
    priceAsNumber: product.priceAsNumber,
    productOnSale: product.showAsOnSale,
    discountPercent: product.discountPercent,
  } as ProductPricesWithDiscount;
  const prices = getPriceList(allPrices, pricelistId);
  return prices;
};

export const getProductId = (product: ICentraProduct) => {
  return product.product;
};

export const getMedia = (
  product: ICentraProduct | ISelectionItemProduct,
  type: CentraMediaType
) => {
  const media = product.media[type];
  const formattedMedia = media?.map((media) => {
    return {
      uri: media,
      alt: `${product?.name}-${product?.variantName}`,
    };
  });
  return formattedMedia;
};

export const getProductName = (product: ICentraProduct) => {
  return product.name;
};
export const getThumbnails = (
  product: ICentraProduct | ISelectionItemProduct
) => {
  return getMedia(product, "thumb-4x5") || [];
};

export const getUri = (product: ICentraProduct) => {
  return product.uri;
};

export const getSizes = (product: ICentraProduct) => {
  const sizes = product.items;
  const formattedSizes = sizes?.map((size) => {
    return {
      name: size.name,
      id: size.item,
      stock: size.stock === "yes" ? true : false,
    };
  });
  return formattedSizes;
};

export const getVariantName = (product: ICentraProduct) => {
  return product.variantName;
};

export const getSwatch = (product: ICentraProduct) => {
  return {
    name: product.pr_swatch?.name,
    code: product.pr_swatch?.code,
  };
};

export const getPackshotCount = (product: ICentraProduct) => {
  return product.pr_packshots_count_value || 3;
};

const getAllPrices = (product: ICentraProductAllPrices) => {
  const formattedPrices: ProductPricesWithDiscount[] = [];

  Object.entries(product.prices).forEach((priceIndex: [string, any]) => {
    const pricelistId = priceIndex[0];
    const defaultPrice = {
      pricelistId: "0",
      price: "",
      priceAsNumber: -1,
      priceBeforeDiscount: "",
      priceBeforeDiscountAsNumber: -1,
      discountPercent: 0,
      showAsOnSale: false,
    };
    const unformattedPrice = product.prices[pricelistId];
    const formattedPrice = {
      pricelistId: unformattedPrice ? pricelistId : defaultPrice.pricelistId,
      price: unformattedPrice?.price || defaultPrice.price,
      priceAsNumber:
        unformattedPrice?.priceAsNumber || defaultPrice.priceAsNumber,
      priceBeforeDiscount:
        unformattedPrice?.priceBeforeDiscount ||
        defaultPrice.priceBeforeDiscount,
      priceBeforeDiscountAsNumber:
        unformattedPrice?.priceBeforeDiscountAsNumber ||
        defaultPrice.priceBeforeDiscountAsNumber,
      discountPercent:
        unformattedPrice?.discountPercent || defaultPrice.discountPercent,
      productOnSale:
        unformattedPrice?.showAsOnSale || defaultPrice.showAsOnSale,
    };
    formattedPrices.push(formattedPrice);
  });

  return formattedPrices;
};

export const getMainCategory = (product: ICentraProduct) => {
  return product.categoryName[product.categoryName.length - 1];
};
export const generateBasicProductDetails = (product: ICentraProduct) => {
  const name = getProductName(product);
  const uri = getUri(product);
  const sizes = getSizes(product);
  const variantName = getVariantName(product);
  const packshotCount = getPackshotCount(product);
  const mainCategory = getMainCategory(product);

  return {
    name,
    uri,
    sizes,
    variantName,
    packshotCount,
    mainCategory,
  };
};

export const generateServerProductDetails = (
  product: ICentraProductAllPrices
) => {
  const prices = getAllPrices(product);
  const productDetails = generateBasicProductDetails(product);

  return {
    ...productDetails,
    prices,
  };
};

export const generateProductDetails = (
  product: ICentraProductSinglePrices,
  pricelistId?: number
) => {
  const prices = getPrices(product, pricelistId);
  const basicDetails = generateBasicProductDetails(product);

  return {
    ...basicDetails,
    prices,
  };
};
export const generateTrimmedCentraProductCard = (
  product: ICentraProductAllPrices
) => {
  const { name, uri, variantName, prices, mainCategory } =
    generateServerProductDetails(product);
  const id = getProductId(product);
  const media = getThumbnails(product);
  return {
    name,
    uri,
    variantName,
    prices,
    id,
    media,
    mainCategory,
  };
};

export const getRelatedProducts = (product: ICentraProduct) => {
  const relatedProducts = product?.relatedProducts?.map(
    (relatedProduct: any) => {
      return generateTrimmedCentraProductCard(relatedProduct);
    }
  );

  return relatedProducts;
};

export const getProductVariants = (product: ICentraProduct) => {
  let relatedProducts = product?.relatedProducts
    ?.filter((relatedProduct: any) => relatedProduct.relation === "variant")
    .map((relatedProduct: any) => {
      const id = getProductId(relatedProduct);
      const productDetails = generateProductDetails(relatedProduct);
      const media = getThumbnails(relatedProduct);
      const swatch = getSwatch(relatedProduct);
      return { productDetails, id, media, swatch };
    });

  const id = getProductId(product);
  const productDetails = generateProductDetails(
    product as ICentraProductSinglePrices
  );
  const media = getThumbnails(product);
  const swatch = getSwatch(product);
  relatedProducts?.unshift({ productDetails, id, media, swatch });

  return relatedProducts;
};

export const generateCentraProductCard = (
  product: ICentraProductSinglePrices
) => {
  const productDetails = generateProductDetails(product);
  const id = getProductId(product);
  const media = getThumbnails(product);
  const variants = getProductVariants(product);
  const swatch = getSwatch(product);
  return {
    productDetails,
    id,
    media,
    swatch,
    variants,
  };
};

export const generateFullCentraProductCard = (
  product: ICentraProductAllPrices
) => {
  const productDetails = generateServerProductDetails(product);
  const id = getProductId(product);
  const media = getThumbnails(product);
  const variants = getRelatedProducts(product);
  return {
    ...productDetails,
    id,
    media,
    variants,
  };
};
