import { useTranslations } from "next-intl";

import styles from "./retailersList.module.scss";

interface UIProps {
  content: any[] | undefined;
}

const UI = ({ content }: UIProps) => {
  const t = useTranslations();

  return (
    <div className={styles.list}>
      <div className={styles.header}>
        <div className={styles.cell}>{t("store")}</div>
        <div className={styles.cell}>{t("country")}</div>
        <div className={styles.cell}>{t("city")}</div>
        <div className={styles.cell}>{t("address")}</div>
        <div className={styles.cell}>{t("contact")}</div>
      </div>
      {content?.map((retailer) => (
        <div className={styles.retailer} key={retailer.id}>
          <div className={styles.cell}>{t(retailer.content.name)}</div>
          <div className={styles.cell}>{t(retailer.content.country)}</div>
          <div className={styles.cell}>{t(retailer.content.city)}</div>
          <div className={styles.cell}>
            <p>{t(retailer.content.address)}</p>
            <p>
              {t(retailer.content.zip)} {t(retailer.content.city)}
            </p>
            <p>
              <a
                href={retailer.content?.mapLink?.url}
                target="_blank"
                rel="noreferrer"
              >
                {t("viewOnMap")}
              </a>
            </p>
          </div>
          <div className={styles.cell}>
            <p>{t(retailer.content.phone)}</p>
            <p>
              <a href={`mailto:${retailer.content?.email?.url}`}>
                {t(retailer.content.email?.email)}
              </a>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UI;
