import type { ISelection } from "@/types/centra";
import type { ICartItem } from "@/types/product";

const optimisticUpdate = (
  data: any,
  variables: { newQuantity: number; line: string }
): ISelection => {
  const item = data?.selection?.items?.findIndex(
    (item: ICartItem) => item.id === variables.line
  );
  if (item === -1) return data;
  const formattedItem = {
    ...data.selection.items[item],
    quantity: variables.newQuantity,
  };

  let newItems = data.selection.items;
  newItems[item] = formattedItem;

  const formattedData = {
    ...data,
    selection: {
      ...data.selection,
      items: newItems,
    },
  };

  return formattedData;
};

export default optimisticUpdate;
