import type {
  ICentraProduct,
  ICentraProductNoRelations,
} from "../rorosTweed/productPage";

export const getDisplayItemId = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    return product.product;
  } catch (e: any) {
    throw new Error(`Could not find the product ID: ${e.message}`);
  }
};

export const getDisplayUri = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    return product.uri;
  } catch (e: any) {
    throw new Error(`Could not find the display URI: ${e.message}`);
  }
};

export const getProductName = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    return product.name;
  } catch (e: any) {
    throw new Error(`Could not find the product name: ${e.message}`);
  }
};

export const getVariantName = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    return product.variantName;
  } catch (e: any) {
    throw new Error(`Could not find the product variant name: ${e.message}`);
  }
};

export const getProductSku = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    return product.sku;
  } catch (e: any) {
    throw new Error(`Could not find the product sku: ${e.message}`);
  }
};

export const getProductBreadcrumbs = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    const fullCategoryUri = product.categoryUri;
    const categoryUris = fullCategoryUri.split("/");
    const categoryNames = product.categoryName;

    if (categoryNames.length !== categoryUris.length) {
      throw new Error("Mismatch in length of category names and sub paths");
    }

    const breadcrumbs = categoryUris.map((_, i, arr) => ({
      categoryName: categoryNames[i] || "",
      categoryUri: arr.slice(0, i + 1).join("/"),
    }));
    return breadcrumbs;
  } catch (e: any) {
    throw new Error(`Could not create breadcrumbs: ${e.message}`);
  }
};

export const getCategoryTitleAndLink = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    const categoryTitle = product.categoryName;
    const categoryUri = `/category/${product.categoryUri}`;
    const category = { categoryTitle, categoryUri };
    return category;
  } catch (e: any) {
    throw new Error(
      `Could not build the category title and link: ${e.message}`
    );
  }
};

export const getProductSizes = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  const sizeLabels = product.tableMappings?.Label.x;
  const sizes = product.items.map((item, i) => {
    try {
      return {
        name: item.name,
        label: sizeLabels ? sizeLabels[item.name] : item.name,
        stock: item.stock !== "no" && item.stock !== 0,
        itemId: item.item,
      };
    } catch (e) {
      throw new Error(
        `Problem formatting size at index: ${i}, with name ${item.name}`
      );
    }
  });
  return sizes;
};

export const getSingleProductSize = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  const sizes = getProductSizes(product);

  return sizes[0];
};

export const getItemId = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  const itemId = product.items[0]?.item;

  if (!itemId) throw new Error("Could not find an item id");
  return itemId;
};

export const getItemStock = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  const item = product.items[0];
  if (!item) throw new Error("Could not find an item");

  const stock = item.stock !== "no" && item.stock !== 0;

  return stock;
};

export const getCollectionName = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.collectionName;
};

export const getCollectionUri = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.collectionUri;
};

export const getProductBrand = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.brandName;
};

export const getComingSoon = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  try {
    return product.pr_promo_coming_soon === "1";
  } catch (e) {
    throw new Error(`Problem retrieving coming soon: ${e}`);
  }
};

export const getProductDescriptionHTML = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.descriptionHtml;
};

export const getProductDescription = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.description;
};

export const getProductVariantDescription = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.pr_variant_description_text;
};

export const getAdviceSize = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.pr_advice_size;
};

export const getAdviceFit = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.pr_advice_fit;
};

export const getCompositionText = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.pr_composition_description_text;
};

export const getCareInstructionsWithImage = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.pr_care?.map((careItem) => ({
    text: careItem.label,
    illustrationUri: careItem.icon?.url,
  }));
};

export const getCountryOfOrigin = (
  product: ICentraProduct | ICentraProductNoRelations,
  lang: string
) => {
  const countryCode = product.countryOrigin;
  const countryNames = new Intl.DisplayNames([lang], { type: "region" });
  return countryNames.of(countryCode);
};

export const getFactoryOfOrigin = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.pr_traceability_factory;
};

export const getVendorOfOrigin = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.pr_traceability_vendor;
};

export const getMeasurementChart = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  return product.measurementChart;
};

export const buildSizeFromsingleChart = (
  product: ICentraProduct | ICentraProductNoRelations
) => {
  const chart = product.measurementChart;

  const unit = chart.unit;

  const dimensions = chart.contents
    .filter((field) => field.x === 0)
    .map((field) => field.content)
    .filter((dimension) => dimension);

  if (dimensions.length > 1) {
    return `${dimensions.join("x")} ${unit}`;
  }

  if (dimensions.length === 1) {
    return `${dimensions[0]} ${unit}`;
  }
  return undefined;
};
