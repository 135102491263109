import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import type { StoryblokLinkBlok } from "@/bloks/StoryblokLink/StoryblokLink";

import getLink from "./getLink";

const useSubmenu = (subMenu: StoryblokLinkBlok[]) => {
  const router = useRouter();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const { asPath, locale } = router;

  const pathWithoutQuery = "/" + locale + asPath.replace(/\?.*/, "");

  const routerQuery = asPath.includes("?") ? asPath.replace(/.*\?/, "?") : "";
  const query = new URLSearchParams(routerQuery);
  if (query.has("search")) {
    query.set("search", "");
  }

  useEffect(() => {
    if (!subMenu) return;

    const show = subMenu?.find(
      (item) =>
        (pathWithoutQuery.includes(getLink(item?.link)) ||
          (getLink(item?.link).endsWith("/") &&
            pathWithoutQuery.includes(getLink(item?.link).slice(0, -1)))) &&
        getLink(item?.link) !== "/"
    );

    setShowSubmenu(show ? true : false);
  }, [pathWithoutQuery, subMenu]);

  if (!subMenu) return { showSubmenu: false, links: [] };

  const links = subMenu?.map((item) => {
    if (item.component === "space") {
      return {
        space: true,
        link: "",
      };
    }
    const link = getLink(item?.link);
    return {
      link:
        getLink(item?.link) + (query.toString() ? `?${query.toString()}` : ""),
      text: item?.text,
      selected:
        pathWithoutQuery.includes(link) ||
        pathWithoutQuery.includes(link.slice(0, -1)),
      _uid: item?._uid,
      featured: item?.featured,
    };
  });

  return { showSubmenu, links };
};

export default useSubmenu;

export type SubmenuType = ReturnType<typeof useSubmenu>;
