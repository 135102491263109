import type { SbBlokData } from "@storyblok/react";
import { useRouter } from "next/router";

import useStore from "@/lib/stateManagement/store";

import useStaticStory from "../storyblok/useStaticStory";
import useSubmenu from "../storyblok/useSubmenu";
import useModal from "./useModal";

const useMobileMenu = (
  previewData:
    | {
        [key: string]: SbBlokData;
      }
    | undefined
) => {
  const { locale } = useRouter();
  const preview = useStore((state) => state.preview);
  const { blok } = useStaticStory({
    slug: "mobile-navigation",
    preview,
    folder: "statics",
    previewData,
    locale,
  });

  let secondaryMenu: any[] = [];
  blok?.menus?.map((menu: any) => {
    menu.subMenu.map((subMenu: any) => {
      if (subMenu.featured) secondaryMenu.push(subMenu);
    });
  });
  const secondaryContent = useSubmenu(secondaryMenu);
  return {
    modal: useModal("mobile-menu", () => {}),
    primaryContent: blok,
    secondaryContent,
  };
};

export type IMobileMenu = ReturnType<typeof useMobileMenu>;

export default useMobileMenu;
