import type { SbBlokData } from "@storyblok/react";

import useStoryblokMedia from "@/lib/storyblok/Media/useStoryblokMedia";
import type { IStoryblokImage } from "@/types/storyblok";

import UI from "./UI";

export interface IStoryBlokMedia extends SbBlokData {
  mobileMedia: IStoryblokImage;
  desktopMedia: IStoryblokImage;
}

export interface StoryblokMediaProps {
  blok: IStoryBlokMedia;
  sizes?: string;
}

const StoryblokMedia = ({ blok, sizes }: StoryblokMediaProps) => {
  const { desktopMedia, mobileMedia, _uid: id } = blok;
  const media = useStoryblokMedia({
    desktop: desktopMedia,
    mobile: mobileMedia,
    id,
  });

  return <UI media={media} sizes={sizes} />;
};

export default StoryblokMedia;
