import type { SbBlokData } from "@storyblok/react";

import generateSBStyles from "@/lib/storyblok/generateSBStyles";
import getNumberStyles from "@/lib/utils/getNumberStyles";

import type { StoryblokLinkBlok } from "../StoryblokLink/StoryblokLink";
import { getDefaultStyle } from "./functions";
import styles from "./sideBySide.module.scss";
import UI from "./UI";

export interface SideBySideBlok extends SbBlokData {
  text: string;
  media: SbBlokData[];
  cta: StoryblokLinkBlok[];
}

export interface SideBySideProps {
  blok: SideBySideBlok;
}

const SideBySide = ({ blok }: SideBySideProps) => {
  const { getStyles } = generateSBStyles(styles);
  const numberStyles = getNumberStyles(blok.media.length, styles);
  const defaultStyle = getDefaultStyle();
  return (
    <UI
      blok={blok}
      getStyles={getStyles}
      defaultStyle={defaultStyle}
      numberStyles={numberStyles}
    />
  );
};

export default SideBySide;
