import type { SbBlokData } from "@storyblok/react";

import UI from "./UI";

export interface QuoteBlok extends SbBlokData {
  text: string;
  name: string;
  role: string;
}
export interface QuoteProps {
  blok: QuoteBlok;
}

const Quote = ({ blok }: QuoteProps) => {
  return <UI blok={blok} />;
};

export default Quote;
