import Player from "@vimeo/player";
import { useEffect, useRef, useState } from "react";

import type { IPlayerInterface, StoryBlokMedia } from "@/types/storyblok";

import getVideoId from "./getVideoId";
import useAspect from "./useAspect";
import useThumbnail from "./useThumbnail";

const useVideo = (video: StoryBlokMedia) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<IPlayerInterface | null>(null);
  const thumbnail = useThumbnail(video);
  const playerId = `video-player-${video.id}`;
  const videoId = getVideoId(video);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined" && playerRef.current && videoId) {
      setPlayer(
        new Player(playerId, {
          id: videoId,
          controls: false,
          muted: true,
          responsive: true,
          background: true,
          playsinline: true,
          autoplay: true,
          loop: true,
        })
      );
    }
  }, [playerRef.current]);
  const { wrapperRef, aspectStyle } = useAspect(player);

  useEffect(() => {
    player?.on("playing", () => setLoaded(true));

    return () => {
      setLoaded(false);
    };
  }, [player]);

  const style = {
    ...aspectStyle,
  };

  return {
    playerRef,
    wrapperRef,
    style,
    thumbnail,
    playerId,
    loaded,
  };
};

export default useVideo;
