import type { SbBlokData } from "@storyblok/react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffect } from "react";

export interface ArticlePageProps {
  blok: Blok;
}

export interface Blok extends SbBlokData {
  body: SbBlokData[];
  metadata?: {
    title: string;
    description: string;
    og_title: string;
    og_description: string;
    og_image: string;
  };
}

const Article = ({ blok }: ArticlePageProps) => {
  useEffect(() => {
    const colorBlok = blok.body.find(
      (blok) =>
        !!(
          blok?.color as {
            color: string;
          }
        )?.color
    );
    function setColor(newColor: string) {
      document.documentElement.style.setProperty("--header-color", newColor);
    }

    setColor(
      (
        colorBlok?.color as {
          color: string;
        }
      )?.color || ""
    );
    return () => {
      setColor("");
    };
  }, [blok]);
  return (
    <main key={blok?._uid} {...storyblokEditable(blok)}>
      {blok.body
        ? blok.body.map((currentBlok) => (
            <StoryblokComponent blok={currentBlok} key={currentBlok?._uid} />
          ))
        : null}
    </main>
  );
};
export default Article;
