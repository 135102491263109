import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

import Animated from "@/components/Animation/Animated";
import getLink from "@/lib/storyblok/getLink";

import ArticleCard from "../ArticleCard";
import type { StoryblokLinkBlok } from "../StoryblokLink/StoryblokLink";
import type { CuratedArticlesBlok } from "./CuratedArticles";
import styles from "./curatedArticles.module.scss";

interface UIProps {
  blok: CuratedArticlesBlok;
  numberStyles: string;
  link?: StoryblokLinkBlok;
}
const UI = ({ blok, numberStyles, link }: UIProps) => {
  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      <Animated>
        {link ? (
          <Link href={getLink(link.link)} className={styles.title}>
            {blok.title}
          </Link>
        ) : (
          <h2 className={styles.title}>{blok.title}</h2>
        )}
      </Animated>
      <div className={`${styles.wrapper} ${numberStyles} `}>
        {blok?.articles?.map((article, i) => (
          <ArticleCard
            story={article}
            index={i}
            key={i + "-article"}
            parentLength={blok?.articles?.length}
          />
        ))}
      </div>
    </div>
  );
};

export default UI;
