import { storyblokEditable } from "@storyblok/react";

import ProductList from "@/components/ProductList";
import type { IResolvedProductCard } from "@/types/product";

import type { FeaturedProductsBlok } from "./FeaturedProducts";
import styles from "./featuredProducts.module.scss";

interface UIProps {
  products: IResolvedProductCard[];
  blok: FeaturedProductsBlok;
  link?: string;
}

const UI = ({ products, blok, link }: UIProps) => {
  if (!products) return null;
  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      <ProductList products={products} title={blok?.title} link={link} />
    </div>
  );
};

export default UI;
