import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import type { HeroWithTextBlok } from "./HeroWithText";
import styles from "./heroWithText.module.scss";

interface UIProps {
  blok: HeroWithTextBlok;
}

const UI = ({ blok }: UIProps) => {
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      <div className={`${styles.mainContent}  ${styles[blok?.position]}`}>
        <p className={styles.text}>{blok.text}</p>
        {blok.link.map((link) => (
          <StoryblokComponent
            key={link?._uid}
            blok={link}
            className={styles.link}
          />
        ))}
      </div>
      <div className={styles.imageWrapper}>
        {blok.media && blok.media[0] && (
          <StoryblokComponent blok={blok?.media[0]} />
        )}
      </div>
    </div>
  );
};

export default UI;
