import Link from "next/link";

import CentraImage from "@/components/CentraImage";
import getPriceList from "@/lib/centra/getPriceList";
import useDevice from "@/lib/hooks/useDevice";
import useSelection from "@/lib/hooks/useSelection";
import type { InfoPosition } from "@/lib/hooks/useTagPosition";
import type { TaggedImageType } from "@/lib/storyblok/useTaggedImage";
import MinusSvg from "@/public/svg/State=Minus.svg";
import PlusSvg from "@/public/svg/State=Plus.svg";

import styles from "./tag.module.scss";
const UI = ({
  tag,
  infoPosition,
}: {
  tag: TaggedImageType;
  infoPosition: InfoPosition;
}) => {
  const device = useDevice();
  const { product, position } = tag;
  const { priceList } = useSelection();
  const prices = getPriceList(product?.info?.prices || [], +priceList);
  const selected = infoPosition(tag);
  if (!product) return null;
  const { info, media } = product;
  return (
    <>
      <div
        onClick={tag.select}
        className={`${styles.tag} ${tag.selected && styles.selected} ${
          tag.inactive && styles.inactive
        }`}
        style={{
          top: `${position.y}%`,
          left: `${position.x}%`,
        }}
      >
        <PlusSvg
          className={`${styles.plus}  ${tag.selected && styles.selected}`}
        />
        <MinusSvg
          className={`${styles.minus}  ${tag.selected && styles.selected}`}
        />

        {device.desktop && tag.selected && (
          <Link
            href={`/product/${product?.uri}`}
            className={styles.info}
            style={{
              left: selected.x,
              top: selected.y,
            }}
          >
            <div className={styles.tagWrapper}>
              <CentraImage
                src={media?.packShot?.uri}
                alt={media?.packShot?.alt}
              />
            </div>
            <div className={styles.infoWrapper}>
              <div>
                <div className={styles.nameContainer}>
                  <p>{info?.name}</p>
                  <p className={styles.mainCategory}>{info?.type}</p>
                </div>
                <p className={styles.variantName}>{info?.variantName}</p>
              </div>

              <div className={styles.price}>
                {prices.productOnSale && (
                  <p className={styles.discountPrice}>
                    {prices?.priceBeforeDiscount}
                  </p>
                )}
                <div>
                  <p>{prices?.price}</p>
                  <p className={styles.arrow}>↑</p>
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

export default UI;
