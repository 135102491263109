import { useTranslations } from "next-intl";

import type { IProductCardDetails } from "./ProductCardDetails";
import styles from "./productCardDetails.module.scss";

type UIProps = Omit<IProductCardDetails, "prices"> & {
  price: string;
  favourites: boolean;
};

const UI = ({
  name,
  variantName,
  type,
  variantCount,
  swatches,
  price,

  desktop,
  wishlist,
  favourites,
}: UIProps) => {
  const t = useTranslations();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={`${styles.nameContainer} pc-details-container`}>
          <h3 className={styles.productName}>{name}</h3>
          <h4 className={styles.productType}>{type}</h4>
        </div>
        <p
          className={`${styles.productPrice} ${!desktop ? styles.mobile : ""}`}
        >
          {price}
        </p>
        {swatches && (
          <p className={`${styles.countContainer} pc-count-container`}>
            {swatches.length > 1
              ? `${variantCount} ${t("colorsSmall")}`
              : variantName}
          </p>
        )}

        <div className={`${styles.swatchesContainer} pc-swatches-container`}>
          {swatches &&
            swatches.length > 1 &&
            swatches.map((swatch) => (
              <button
                key={`product-card-${swatch.uri}`}
                className={styles.swatchContainer}
                onClick={(e) => {
                  if (swatch.setActive) swatch.setActive();
                  e.preventDefault();
                }}
              >
                {swatch.active ? (
                  <div className={styles.swatchActive}>
                    <div
                      className={styles.swatch}
                      style={{ backgroundColor: swatch.colourCode }}
                    >
                      {""}
                    </div>
                  </div>
                ) : (
                  <div
                    className={styles.swatch}
                    style={{ backgroundColor: swatch.colourCode }}
                  >
                    {""}
                  </div>
                )}
              </button>
            ))}
          {swatches && swatches.length === 1 && swatches[0] && (
            <>
              <div className={styles.swatchActive}>
                <div
                  className={styles.swatch}
                  style={{ backgroundColor: swatches[0].colourCode }}
                >
                  {""}
                </div>
              </div>
              <p className={styles.variantName}>{variantName}</p>
            </>
          )}
        </div>

        {wishlist && (desktop || favourites) && (
          <div
            className={`${styles.wishlist} ${
              wishlist.active ? styles.remove : ""
            }
            pc-wishlist-container
            `}
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                wishlist.toggle();
              }}
              aria-label={t("addToWishlist")}
            >
              {wishlist?.active && favourites && t("remove")}
              {!wishlist?.active && t("addToWishlist")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UI;
