import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useTranslations } from "next-intl";

import formatSelection from "../centra/formatSelection";
import optimisticUpdate from "../centra/optimisticUpdate";
import type {
  CartItem,
  CentraAddress,
  CentraTotals,
  ISelectionSummary,
} from "../centra/selection/selectionFormatter";
import useStore from "../stateManagement/store";
import type { CountryType } from "./useLocation";

declare global {
  interface Window {
    CentraCheckout: any;
  }
}

export const getSelection = async (
  token: string,
  setToken: (token: string) => void
) => {
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const res = await axios.get(`${api}/selection/`, {
    headers: { "API-token": token || "" },
  });

  if (res.data === null) throw Error("Error getting selection");
  setToken(res.data.token);
  const formattedData = formatSelection(res.data);
  return formattedData;
};

type Language = {
  language: string;
  name: string;
  default: boolean;
};

export type Selection = {
  countries: CountryType[];
  languages: Language[];
  selection: {
    items: CartItem[];
    summary: ISelectionSummary;
    currency: string;
    totals: CentraTotals;
    address: CentraAddress;
  };
  location: {
    country: string;
    name: string;
    pricelist: string;
    state: string;
  };
};

const useSelection = () => {
  const setToken = useStore((state) => state.setToken);
  const selection = useQuery<Selection>(["selection"], () =>
    getSelection(useStore.getState().token, setToken)
  );

  return {
    data: selection.data,

    items: selection.data?.selection.items as CartItem[],
    summary: selection.data?.selection.summary as ISelectionSummary,
    address: selection?.data?.selection?.address,
    location: selection.data?.location?.country,
    languages: selection.data?.languages,
    countries: selection.data?.countries,
    error: selection.error,
    isLoading: selection.isLoading,
    refetch: selection.refetch,
    priceList: selection?.data?.location?.pricelist as string,
  };
};

const setQuantity = async (
  variables: {
    line: string;
    newQuantity: number;
  },
  customerToken: string
) => {
  if (window.CentraCheckout) window.CentraCheckout.suspend();
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const res = fetch(
    `${api}/lines/${variables.line}/quantity/${variables.newQuantity}`,
    {
      method: "PUT",
      headers: { "API-token": customerToken || "" },
    }
  )
    .then((res) => {
      if (!res.ok) throw Error("Error updating quantity");
      return res.json();
    })
    .then((data) => {
      if (data === null) throw Error("Error updating quantity");
      return data;
    })
    .catch((err) => {
      throw err;
    });

  return res;
};

export const useChangeQuantity = () => {
  const queryClient = useQueryClient();
  const customerToken = useStore((state) => state.token);
  const changeQuantity = useMutation(
    (variables: { line: string; newQuantity: number }) =>
      setQuantity(variables, customerToken),
    {
      onMutate: async (variables) => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        await queryClient.cancelQueries({ queryKey: ["selection"] });

        // Snapshot the previous value
        const oldData = queryClient.getQueryData(["selection"]);

        // Optimistically update to the new value
        queryClient.setQueryData(["selection"], (oldCart: any) =>
          optimisticUpdate(oldCart, variables)
        );

        // Return a context object with the snapshotted value
        return { oldData };
      },
      onError: (error, _variables, context) => {
        if (context?.oldData) {
          queryClient.setQueryData(["selection"], context?.oldData);
          console.error(error);
        }
      },
      onSettled: () => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        queryClient.invalidateQueries({ queryKey: ["selection"] });
      },
    }
  );

  return { changeQuantity };
};

const setPromo = async (
  variables: { code: string; remove: boolean },
  customerToken: string,
  invalidVoucher: string
) => {
  const api = process.env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;
  const res = fetch(`${api}/vouchers`, {
    method: !variables.remove ? "POST" : "DELETE",
    headers: { "API-token": customerToken || "" },
    body: JSON.stringify({
      voucher: variables.code,
    }),
  })
    .then((res) => {
      if (!res.ok) throw Error(invalidVoucher || "Invalid promo code");
      return res.json();
    })
    .then((data) => {
      if (data === null || Object.prototype.hasOwnProperty.call(data, "errors"))
        throw Error(invalidVoucher || "Invalid promo code");
      return data;
    })
    .catch((err) => {
      throw err;
    });

  return res;
};

export const usePromoMutation = () => {
  const t = useTranslations();
  const queryClient = useQueryClient();
  const customerToken = useStore((state) => state.token);
  const promo = useMutation(
    (variables: { code: string; remove: boolean }) =>
      setPromo(variables, customerToken, t("invalidVoucher")),
    {
      onError: async (error: Error) => {
        return error;
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(["selection"], formatSelection(data));
      },
    }
  );

  return { promo };
};

export default useSelection;
