import animations from "@/lib/utils/animations";

import AnimationWrapper from "../Animation/AnimationWrapper";
import type { animationDirections } from "../Animation/AnimationWrapper/AnimationWrapper";
import styles from "./modal.module.scss";

interface UIProps {
  isOpen: boolean | undefined;
  children: React.ReactNode;
  close: (() => void) | undefined;
  shouldRender: boolean;
  animation?: animationDirections;
}

const UI = ({ isOpen, children, close, shouldRender, animation }: UIProps) => {
  if (!shouldRender) return null;
  return (
    <div
      className={`${styles.container} ${isOpen ? styles.open : ""} `}
      onClick={close}
      style={animations("fade", !!isOpen)}
    >
      <AnimationWrapper
        isVisible={isOpen}
        shouldRender={shouldRender}
        direction={animation}
      >
        {children}
      </AnimationWrapper>
    </div>
  );
};

export default UI;
