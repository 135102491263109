import useVideo from "@/lib/storyblok/Media/useVideo";
import type { StoryBlokMedia } from "@/types/storyblok";

import UI from "./UI";

interface VideoProps {
  media: StoryBlokMedia;
}

const Video = ({ media }: VideoProps) => {
  const video = useVideo(media);
  return <UI video={video} />;
};

export default Video;
