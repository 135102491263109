import { create } from "zustand";

interface FooterState {
  visible: boolean;
  setVisible: (state: boolean) => void;
}

const useFooterVisible = create<FooterState>((set) => ({
  visible: false,
  setVisible: (state: boolean) => set({ visible: state }), // clears the entire store, actions included
}));

export default useFooterVisible;
