import type { ProductCardWishlist } from "@/lib/hooks/useWishlist";
import { getSizes } from "@/lib/product/productsFormatters";
import type { ErrorObject, FormattingError } from "@/types/utility";

import { getDisplayPricesWithSale } from "../atomics/prices";
import {
  getDisplayItemId,
  getProductName,
  getVariantName,
} from "../atomics/productInfo";
import type { RorosVariants } from "../atomics/productVariants";
import {
  buildVariantWithColorCodeRoros,
  getRorosUri,
  getVariantsWithColorCodeRoros,
} from "../atomics/productVariants";
import { atomicHandler, atomizeData, dataErrorBuilder } from "../helpers";
import { getProductType } from "./atomics";
import { getEnvironmentShot, getPackShot } from "./media";
import type { ICentraProduct, ICentraProductNoRelations } from "./productPage";

export type IProductCardData = ReturnType<typeof getProductCard>;
export type IProductCard = IProductCardData["data"];
export type IProductCardVariantData = ReturnType<typeof getProductCardVariant>;
export type IProductCardVariant = IProductCardVariantData["data"];
export type IProductCardMedia = ReturnType<typeof getProductCardMedia>["data"];
export type IProductCardInfo = ReturnType<typeof getProductCardInfo>["data"] & {
  wishlist?: ProductCardWishlist;
};

export type IProductCardsData = ReturnType<typeof getProductCards>;

const getProductCardMedia = (product: ICentraProductNoRelations) => {
  const packShot = atomicHandler(getPackShot, 2)(product);
  const environmentShot = atomicHandler(getEnvironmentShot, 1)(product);

  const media = dataErrorBuilder({
    packShot,
    environmentShot,
  });
  return media;
};

const getProductCardInfo = (
  product: ICentraProductNoRelations,
  productSwatches: RorosVariants
) => {
  const name = atomicHandler(getProductName, 2)(product);
  const variantName = atomicHandler(getVariantName, 2)(product);
  const type = atomicHandler(getProductType, 2)(product);
  const variantCount = atomizeData(`${productSwatches.length}`);
  const swatches = atomizeData(productSwatches);
  const prices = atomicHandler(getDisplayPricesWithSale, 2)(product);
  const sizes = atomicHandler(getSizes, 2)(product);

  const info = dataErrorBuilder({
    name,
    variantName,
    type,
    variantCount,
    swatches,
    prices,
    sizes,
  });

  return info;
};

const getProductCardVariant = (
  product: ICentraProductNoRelations,
  variantSwatches: RorosVariants
) => {
  const id = atomicHandler(getDisplayItemId, 2)(product);
  const uri = atomicHandler(getRorosUri, 2)(product);
  const media = getProductCardMedia(product);

  const swatches = variantSwatches.map((swatch) =>
    swatch.uri === uri ? { ...swatch, active: true, uri: "#" } : swatch
  );
  const info = getProductCardInfo(product, swatches);

  const variant = dataErrorBuilder({
    id,
    uri,
    media,
    info,
  });

  return variant;
};

const getProductCardVariants = (product: ICentraProduct) => {
  const swatches = getVariantsWithColorCodeRoros(product);
  const defaultVariantId = getDisplayItemId(product);
  const relatedVariants = product.relatedProducts?.filter(
    (related) => related.relation === "variant"
  );

  const defaultVariantData = getProductCardVariant(product, swatches);

  const defaultVariant = {
    default: defaultVariantId,
    variants: {
      [defaultVariantId]: defaultVariantData.data,
    },
  };
  const variantErrors: ErrorObject = {};

  if (defaultVariantData.error) {
    variantErrors[defaultVariantId] = defaultVariantData.error;
  }

  const variants = relatedVariants?.reduce((prev, curr) => {
    const newVariants = prev;
    const newVariant = getProductCardVariant(curr, swatches);
    if (curr.product) {
      newVariants.variants[curr.product] = newVariant.data;
    }
    if (newVariant.error) {
      variantErrors[curr.product] = newVariant.error;
    }
    return newVariants;
  }, defaultVariant);

  if (variants)
    return {
      data: variants,
      error: variantErrors,
    };

  return {
    error: variantErrors,
  };
};

export const getProductCard = (product: ICentraProduct) => {
  const id = atomicHandler(getDisplayItemId, 2)(product);
  const variants = getProductCardVariants(product);

  const card = dataErrorBuilder({
    id,
    variants,
  });

  return card;
};

export const getSingleVariantCard = (product: ICentraProduct) => {
  const swatches = [buildVariantWithColorCodeRoros(product)];
  const variant = getProductCardVariant(product, swatches);

  return variant;
};

export type ProductCard = ReturnType<typeof getProductCard>;

export const getProductCards = (products: ICentraProduct[]) => {
  const productCards = products.map(getProductCard).reduce(
    (
      prev: {
        data: IProductCard[];
        error: Exclude<ErrorObject, FormattingError>;
      },
      curr
    ) => {
      const newCards = prev;
      if (curr.data) newCards.data.push(curr.data);
      if (curr.data.id && curr.error) newCards.error[curr.data.id] = curr.error;

      return newCards;
    },
    { data: [], error: {} }
  );

  return productCards;
};
