import useSubmenu from "@/lib/storyblok/useSubmenu";
import type { IStoryBlokMenu } from "@/types/storyblok";

import UI from "./UI";

const StoryblokMobileMenu = ({ blok }: { blok: IStoryBlokMenu }) => {
  const submenu = useSubmenu(blok.subMenu);

  return <UI blok={blok} submenu={submenu} />;
};

export default StoryblokMobileMenu;
