import type { SbBlokData } from "@storyblok/react";

import UI from "./UI";

export interface RichTextBlok extends SbBlokData {
  text: string;
}

export interface RichTextProps {
  blok: RichTextBlok;
  productPage?: boolean;
}

const RichText = ({ blok, productPage }: RichTextProps) => {
  const { text } = blok;

  return <UI text={text} blok={blok} productPage={productPage} />;
};

export default RichText;
