import React from "react";

import styles from "./fadeInBottom.module.scss";

export type TimingFunction =
  | "ease"
  | "ease-in"
  | "ease-out"
  | "ease-in-out"
  | "linear"
  | "step-start"
  | "step-end";
interface UIProps {
  children: React.ReactNode;
  shouldRender: boolean;
  isVisible: boolean | undefined;
  duration: number;
  delay?: number;
  timing?: TimingFunction;
}

const UI = ({
  children,
  shouldRender,
  isVisible,
  duration,
  delay = 0,
  timing = "ease-out",
}: UIProps) => {
  return (
    <div
      className={styles.container}
      style={{
        animation: `${
          isVisible ? "fadeInBottom" : "fadeOutBottom"
        }  ${duration}s ${delay}s ${timing} both`,
        opacity: isVisible ? 1 : 0,
      }}
    >
      {shouldRender && children}
    </div>
  );
};

export default UI;
