import styles from "./fadeInRight.module.scss";

interface UIProps {
  children: React.ReactNode;
  shouldRender: boolean;
  isVisible: boolean | undefined;
  duration: number;
  delay: number;
}

const UI = ({
  children,
  shouldRender,
  isVisible,
  duration,
  delay,
}: UIProps) => {
  return (
    <div
      className={styles.container}
      style={{
        animation: `${isVisible ? "fadeInRight" : "fadeOutRight"} ${
          duration + delay
        }s`,
        opacity: shouldRender ? 1 : 0,
      }}
    >
      {shouldRender && children}
    </div>
  );
};

export default UI;
