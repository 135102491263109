import { storyblokEditable } from "@storyblok/react";
import { useTranslations } from "next-intl";

import LanguageSelector from "@/components/Layout/Snackbar/LanguageCurrencySelector/LanguageSelector";
import type { NavigationBlok } from "@/types/storyblok";

import StoryblokMobileMenu from "../StoryblokMobileMenu";
import styles from "./mobileNavigation.module.scss";

export interface UIProps {
  blok: NavigationBlok;
  showMobileMenu: boolean;
  toggleModal: () => void;
  currentSubmenu: any;
  toggleSubmenu: (id: string) => void;
}

const UI = ({
  blok,
  showMobileMenu,
  toggleModal,
  currentSubmenu,
  toggleSubmenu,
}: UIProps) => {
  const t = useTranslations();
  if (!blok || !showMobileMenu || !currentSubmenu) return null;

  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
      onClick={toggleModal}
    >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <div className={styles.close} onClick={toggleModal}>
          {t("close")}
        </div>
        <div className={styles.labels}>
          {blok?.menus?.map((menu, key) => (
            <span
              className={`${styles.label} ${
                menu?._uid === currentSubmenu?._uid ||
                (currentSubmenu?._uid === "" && key === 0)
                  ? styles.current
                  : ""
              }`}
              key={menu.label[0]?._uid}
              onClick={() => toggleSubmenu(menu?._uid!)}
            >
              {menu.label[0].text}
            </span>
          ))}
        </div>
        <StoryblokMobileMenu
          blok={currentSubmenu}
          key={currentSubmenu?._uid}
          {...storyblokEditable(currentSubmenu)}
        />
        <div className={styles.locationSelector}>
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

export default UI;
