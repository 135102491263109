import config from "@/config";

const animations = (
  type: "fade" | "right" | "left" | "bottom" | "top",
  visible: boolean
) => {
  if (type === "fade") {
    return {
      animation: `${visible ? "fadeIn" : "fadeOut"} ${
        config.animationDurationModal
      }s
      both
      `,
    };
  }
  if (type === "right") {
    return {
      animation: `${visible ? "fadeInRight" : "fadeOutRight"} ${
        config.animationDurationModal
      }s
      both`,
    };
  }
  if (type === "left") {
    return {
      animation: `${visible ? "fadeInLeft" : "fadeOutLeft"} ${
        config.animationDurationModal
      }s
      both`,
    };
  }
  if (type === "top") {
    return {
      animation: `${visible ? "fadeInTop" : "fadeOutTop"} ${
        config.animationDurationModal
      }s
        both`,
    };
  }
  if (type === "bottom") {
    return {
      animation: `${visible ? "fadeInBottom" : "fadeOutBottom"} ${
        config.animationDurationModal
      }s
       both`,
    };
  }
  return {};
};

export default animations;
