import type { CentraMediaType } from "@/types/product";

import type {
  ICentraProduct,
  ICentraProductNoRelations,
} from "../rorosTweed/productPage";

export const getMediaBySize = (
  product: ICentraProductNoRelations,
  size: CentraMediaType
) => {
  const name = `${product.name} - ${product.variantName}`;
  return product.media[size]?.map((image, i) => ({
    uri: image,
    alt: `${name} image #${i + 1}`,
  }));
};

export const getSingleMediaBySize = (
  product: ICentraProductNoRelations,
  size: CentraMediaType,
  index: number
) => {
  const media = getMediaBySize(product, size);

  if (media && media.length >= index) {
    return media[index];
  }

  throw new Error("index out of bounds or no images found in that size");
};

export const getMediaBySizeWoodling = (
  product: ICentraProduct,
  size: CentraMediaType
) => {
  return getMediaBySize(product, size)?.map((image) => ({
    uri: image.uri,
    altText: image.alt,
  }));
};

export const getMediaFull23Woodling = (product: ICentraProduct) => {
  return getMediaBySizeWoodling(product, "full-2x3");
};

export const getMediaThumb23Woodling = (product: ICentraProduct) => {
  return getMediaBySizeWoodling(product, "thumb-2x3");
};
