import config from "@/config";
import type { ProductPricesWithDiscount } from "@/types/product";

const defaultPriceList = config.priceListId;

const emptyPrice: ProductPricesWithDiscount = {
  price: "",
  priceAsNumber: 0,
  priceBeforeDiscount: "",
  priceBeforeDiscountAsNumber: 0,
  discountPercent: 0,
  productOnSale: false,
};

const getPriceList = (
  prices: ProductPricesWithDiscount | ProductPricesWithDiscount[],
  priceList: number = defaultPriceList
) => {
  if (!Array.isArray(prices)) {
    return prices;
  }

  if (prices.length === 1 && prices[0]) {
    return prices[0];
  }

  const foundPriceList = prices.find(
    (p) => p.pricelistId === priceList.toString()
  );

  return foundPriceList ? foundPriceList : emptyPrice;
};

export default getPriceList;
