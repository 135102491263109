import FadeIn from "../FadeIn";
import FadeInBottom from "../FadeInBottom";
import FadeInLeft from "../FadeInLeft";
import FadeInRight from "../FadeInRight";
import FadeInTop from "../FadeInTop";
import type { animationDirections } from "./AnimationWrapper";

interface UIProps {
  children: React.ReactNode;
  direction?: animationDirections;
  isVisible: boolean | undefined;
  shouldRender?: boolean;
  duration: number;
  delay: number;
}

const UI = ({
  direction,
  children,
  isVisible,
  shouldRender = true,
  duration,
  delay,
}: UIProps) => {
  if (direction === "none") {
    return <>{children}</>;
  }
  if (direction === "bottom") {
    return (
      <FadeInBottom
        isVisible={isVisible}
        shouldRender={shouldRender}
        duration={duration}
        delay={delay}
      >
        {children}
      </FadeInBottom>
    );
  }
  if (direction === "left")
    return (
      <FadeInLeft
        isVisible={isVisible}
        shouldRender={shouldRender}
        duration={duration}
        delay={delay}
      >
        {children}
      </FadeInLeft>
    );
  if (direction === "right")
    return (
      <FadeInRight
        isVisible={isVisible}
        shouldRender={shouldRender}
        duration={duration}
        delay={delay}
      >
        {children}
      </FadeInRight>
    );
  if (direction === "top")
    return (
      <FadeInTop
        isVisible={isVisible}
        shouldRender={shouldRender}
        duration={duration}
        delay={delay}
      >
        {children}
      </FadeInTop>
    );

  return (
    <FadeIn
      isVisible={isVisible}
      shouldRender={shouldRender}
      duration={duration}
      delay={delay}
    >
      {children}
    </FadeIn>
  );
};

export default UI;
