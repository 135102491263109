import Link from "next/link";

import type { RichTextBlok } from "@/bloks/RichText/RichText";
import RichText from "@/bloks/RichText/RichText";
import type { Subcategory } from "@/pages/api/storefront/subcategories";

import RetailersFilter from "../RetailersFilter";
import styles from "./pageIntro.module.scss";

interface UIProps {
  content?: RichTextBlok;
  categories: Subcategory[];
  filter?: {
    options: string[];
    active: string;
    callback: (value: string) => void;
  };
}

const UI = ({ content, categories, filter }: UIProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {content && <RichText blok={content} />}

        {filter && filter.options?.length > 0 && (
          <RetailersFilter filter={filter} />
        )}
        {categories?.length > 0 && (
          <div className={styles.categoryContainer}>
            {categories.map((category, i) =>
              category.active ? (
                <p key={`subcategory-list-${i}`} className={styles.subcategory}>
                  {category.label}
                </p>
              ) : (
                <Link
                  key={`subcategory-list-${i}`}
                  href={category.uri}
                  className={`${styles.subcategory} ${styles.link}`}
                  legacyBehavior
                >
                  {category.label}
                </Link>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UI;
