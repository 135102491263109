import type { SbBlokData } from "@storyblok/react";

import type { IStoryBlokMedia } from "@/components/StoryblokMedia/StoryblokMedia";
import generateSBStyles from "@/lib/storyblok/generateSBStyles";
import useResolvedProductCards from "@/lib/storyblok/useResolvedProductCard";

import styles from "./sideBySideProduct.module.scss";
import UI from "./UI";

export interface SideBySideProductBlok extends SbBlokData {
  media: IStoryBlokMedia[];
  flip: boolean;
  product: number;
}

export interface SideBySideProductProps {
  blok: SideBySideProductBlok;
}

const SideBySideProduct = ({ blok }: SideBySideProductProps) => {
  const { getStyles } = generateSBStyles(styles);

  let baseStyles = [styles.media, styles.product];

  if (blok.flip) {
    baseStyles.forEach((style, index) => {
      baseStyles[index] = `${style} ${styles.flip}`;
    });
  }
  const productArray = useResolvedProductCards(
    [blok?.product],
    "full",
    blok?._uid!
  );

  const containerStyles = styles.container;
  const media = blok?.media[0];
  const product = productArray && productArray[0] ? productArray[0] : undefined;
  return (
    <UI
      blok={blok}
      getStyles={getStyles}
      baseStyles={baseStyles}
      containerStyles={containerStyles}
      product={product}
      media={media}
    />
  );
};

export default SideBySideProduct;
