import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import type { TextBlok } from "./TextHeader";
import styles from "./textHeader.module.scss";
interface UIProps {
  blok: TextBlok;
  style: string;
}
const UI = ({ blok, style }: UIProps) => {
  if (!blok) return null;

  return (
    <div
      className={styles.container}
      key={blok?._uid}
      {...storyblokEditable(blok)}
    >
      <div className={style}>
        {blok.text && blok.text[0] && (
          <StoryblokComponent blok={blok?.text[0]} />
        )}
      </div>
    </div>
  );
};

export default UI;
