import type { ArticleStory } from "../ArticleCard/ArticleCard";
import UI from "./UI";

type CuratedGridProps = {
  blok: CuratedGridBlok;
};

type CuratedGridBlok = {
  content: ArticleStory[];
};

const CuratedGrid = ({ blok }: CuratedGridProps) => {
  const content = blok?.content;
  return <UI content={content} />;
};

export default CuratedGrid;
