import "@/styles/animations.scss";
import "@/styles/giftcard.scss";
import "@/styles/global.scss";
import "@/styles/interaction.scss";
import "@/styles/product-card.scss";

import { apiPlugin, storyblokInit } from "@storyblok/react";
import type { DehydratedState } from "@tanstack/react-query";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { NextPageContext } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
// eslint-disable-next-line import/no-extraneous-dependencies
import type { AbstractIntlMessages } from "next-intl";
import { NextIntlClientProvider } from "next-intl";
import { DefaultSeo } from "next-seo";
import React from "react";

import { components } from "@/bloks/Bloks";
import Layout from "@/components/Layout";
import Device from "@/components/tools/Device";
import Toolkit from "@/components/tools/Toolkit";
import Analytics from "@/lib/Analytics";

type PageProps = {
  dehydratedState?: DehydratedState;
  messages?: AbstractIntlMessages;
};

type ExtendedAppProps<P = {}> = {
  err?: NextPageContext["err"];
} & AppProps<P>;

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components,
});

const timeZone = "Europe/Stockholm";

function App({ Component, pageProps }: ExtendedAppProps<PageProps>) {
  const [queryClient] = React.useState(() => new QueryClient());
  const router = useRouter();

  if (
    router.route === "/statics/[static]" ||
    router.route === "/no/statics/[static]" ||
    router.route === "/en/statics/[static]"
  ) {
    return (
      <NextIntlClientProvider
        messages={pageProps.messages}
        locale={router.locale}
        timeZone={timeZone}
      >
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <DefaultSeo
              titleTemplate="Røros Tweed | %s"
              defaultTitle="Røros Tweed"
            />
            <Toolkit>
              <Device>
                <Component {...pageProps} />
              </Device>
            </Toolkit>
          </Hydrate>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </NextIntlClientProvider>
    );
  }

  return (
    <NextIntlClientProvider
      messages={pageProps.messages}
      locale={router.locale}
      timeZone={timeZone}
    >
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <DefaultSeo
            titleTemplate="Røros Tweed | %s"
            defaultTitle="Røros Tweed"
          />
          <Toolkit>
            <Device>
              <Analytics />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </Device>
          </Toolkit>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </NextIntlClientProvider>
  );
}

export default App;
