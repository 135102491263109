import type { SbBlokData } from "@storyblok/react";

import type { StoryblokLinkBlok } from "../StoryblokLink/StoryblokLink";
import UI from "./UI";

export interface HeroWithTextProps {
  blok: HeroWithTextBlok;
}

export interface HeroWithTextBlok extends SbBlokData {
  text: string;
  position: string;
  link: StoryblokLinkBlok[];
  media: SbBlokData[];
}

const HeroWithText = ({ blok }: HeroWithTextProps) => {
  return <UI blok={blok} />;
};

export default HeroWithText;
