import { useTranslations } from "next-intl";

import styles from "./retailersFilter.module.scss";

interface UIProps {
  filter: {
    options: string[];
    active: string;
    callback: (value: string) => void;
  };
}

const UI = ({ filter }: UIProps) => {
  const t = useTranslations();
  return (
    <div className={styles.filter}>
      <select name="country" onChange={(e) => filter.callback(e.target.value)}>
        <option value="all">{t("allCountries")}</option>
        {filter?.options?.map((option, key) =>
          option?.toLowerCase() === filter?.active?.toLowerCase() ? (
            <option key={key} value={option} selected={true}>
              {option}
            </option>
          ) : (
            <option key={key} value={option}>
              {option}
            </option>
          )
        )}
      </select>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M12 6.5L8 10.5L4 6.5"
          stroke="#292929"
          strokeLinejoin="bevel"
        />
      </svg>
    </div>
  );
};

export default UI;
