import type { SbBlokData } from "@storyblok/react";
import { useRouter } from "next/router";

import useStore from "@/lib/stateManagement/store";
import useStaticStory from "@/lib/storyblok/useStaticStory";
import type { NavigationBlok } from "@/types/storyblok";

import UI from "./UI";

export interface NavigationProps {
  previewData?: {
    [key: string]: SbBlokData;
  };
}

const SecondaryNavigation = ({ previewData }: NavigationProps) => {
  const preview = useStore((state) => state.preview);
  const { locale } = useRouter();

  const { blok } = useStaticStory({
    slug: "secondary-navigation",
    preview,
    folder: "statics",
    previewData,
    locale,
  });

  return <UI blok={blok as NavigationBlok} />;
};

export default SecondaryNavigation;
