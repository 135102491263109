/* eslint-disable import/no-extraneous-dependencies */
import { useClickAway } from "@uidotdev/usehooks";
import { useState } from "react";

import useLanguageCurrency from "@/lib/hooks/useLanguageCurrency";
import ArrowSvg from "@/public/svg/Arrow.svg";
import CheckSvg from "@/public/svg/check.svg";

import styles from "./locationSelector.module.scss";

const LanguageSelector = () => {
  const [open, setOpen] = useState(false);

  const {
    languages,
    setLocale: setLanguage,
    selectedLanguage,
    openLang,
    toggleOpenLang,
  } = useLanguageCurrency();

  const ref = useClickAway<HTMLDialogElement>(() => setOpen(false));

  return (
    <div className={styles.container}>
      <div className={styles.sortMenu}>
        <button
          onClick={() => setOpen(true)}
          className={`${styles.button}  ${
            (openLang && styles.activeMenu) || ""
          }`}
        >
          {selectedLanguage.locale.toLocaleUpperCase()} <ArrowSvg />
        </button>

        <dialog
          ref={ref}
          open={open}
          className={`${styles.dropdown} ${openLang ? styles.activeMenu : ""}`}
        >
          {languages?.map((l) => (
            <button
              key={l.locale}
              onClick={() => {
                setLanguage(l.locale);
                toggleOpenLang();
              }}
              className={`${styles.item} ${l.isSelected && styles.selected}`}
            >
              {l.name} {l.isSelected && <CheckSvg />}
            </button>
          ))}
        </dialog>
      </div>
    </div>
  );
};

export default LanguageSelector;
