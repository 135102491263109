import type { ISbStoryData } from "@storyblok/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { getStories } from "./getStories";
import storyReducer from "./storyReducer";

export const useStories = <T>(storyType: string, preview?: boolean) => {
  const { locale } = useRouter();
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery(
      [storyType, locale],
      (pageParams) =>
        getStories<ISbStoryData<T>>(storyType, { pageParams }, preview, locale),
      {
        getNextPageParam: (lastPageData, pages) =>
          lastPageData.totalStories > pages.length
            ? pages.length + 1
            : undefined,
        refetchOnWindowFocus: false,
      }
    );

  const stories = data ? storyReducer<ISbStoryData<T>>(data) : undefined;

  return { stories, hasNextPage, isFetchingNextPage, fetchNextPage };
};
