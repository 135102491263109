import type {
  ISelectionItem,
  ISelectionSummary,
} from "./selection/selectionFormatter";
import {
  generateCartItem,
  generateCartSummary,
} from "./selection/selectionFormatter";

const formatSelection = (data: any) => {
  const formattedItems = data.selection.items.map((item: ISelectionItem) =>
    generateCartItem(item)
  );
  const summary = generateCartSummary(data.selection) as ISelectionSummary;

  const formattedData = {
    ...data,
    selection: {
      ...data.selection,
      items: formattedItems,
      summary,
    },
  };

  return formattedData;
};

export default formatSelection;
