import { useTranslations } from "next-intl";
import { useState } from "react";

import { useGetQuerySearch, useSetQueryState } from "./useQueryState";

export const useSearch = () => {
  const t = useTranslations();
  const [value, setValue] = useState<string>("");
  const setQueryState = useSetQueryState();

  useGetQuerySearch("search", setValue);

  const setSearch = (value: string) => {
    setValue(value);
    setQueryState("search", value ? [value] : []);
  };

  const clearValue = () => {
    setSearch("");
  };

  return {
    value: value ? value : undefined,
    onChange: (e: any) => setSearch(e.target.value),
    clearValue,
    type: "text",
    placeholder: t("searchHere"),
  };
};

export type SearchHook = ReturnType<typeof useSearch>;
