import { useInfiniteQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";

import config from "@/config";
import type { IProductCard } from "@/lib/api/formatting/rorosTweed/productCard";
import useLocation from "@/lib/hooks/useLocation";
import useWishlist from "@/lib/hooks/useWishlist";
import useStore from "@/lib/stateManagement/store";
import type { IFilterGroup } from "@/types/centra";

import filterFormatter from "./filterFormatter";
import { useFilterValue } from "./filterHooks/useFilterValue";
import { useSearch } from "./filterHooks/useSearch";
import { useSortOrder } from "./filterHooks/useSortOrder";
import { getProducts } from "./getProducts";
import productReducer from "./productReducer";
export interface IFilterResponse {
  token: string;
  products: any[];
  productCount: number;
  filter: any[];
}

export const useProductFilter = (
  categoryIds?: string[],
  filterGroups?: IFilterGroup[],
  productIds: string[] | undefined = undefined
) => {
  const { location } = useLocation();
  const { addWishlistToProductCards } = useWishlist();
  // Setting up filters
  const filters = filterGroups
    ? // TODO: Fix useFilterValue so its not a hook anymore
      filterGroups.map((filter) =>
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useFilterValue(
          filter.name,
          filter.field,
          filter.labelField,
          filter.required,
          filter.callback
        )
      )
    : undefined;
  const activeFilters = filters?.reduce(
    (count, filter): number =>
      filter.values ? (count += filter.values.length) : count,
    0
  )
    ? filters.map((filter) => ({ name: filter.name, values: filter.values }))
    : undefined;

  // Setting up search
  const search = useSearch();

  // Setting up sort order
  const sortOrder = useSortOrder();

  // Building function to clear all filters
  const clearFilters = () => {
    filters?.forEach((filter) => filter.clear());
  };

  // Getting token
  const token = useStore((store) => store.token);
  const { locale } = useRouter();

  // Getting products per page
  const productsPerPage = config.productsPerPage;

  // Building infiniteQuery
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    [
      "products",
      {
        categoryIds,
        productIds,
        activeFilters,
        sortOrder: sortOrder.sortOrder,
        search: search.value,
      },
      location,
    ],
    ({ pageParam }) =>
      getProducts({
        categoryIds,
        filters,
        productIds,
        sortOrder: sortOrder.sortOrder,
        search: search.value,
        productsPerPage,
        page: pageParam,
        token,
        language: locale || "en",
      }),
    {
      getNextPageParam: (lastPageData, pages) =>
        lastPageData.productCount > productsPerPage * pages.length
          ? pages.length
          : undefined,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  // Reducing products to single array and adding wishlist status
  // const productsWithoutWishlist = data
  //   ? productReducer<IProductCard>(data)
  //   : undefined;
  const products = data
    ? addWishlistToProductCards(productReducer<IProductCard>(data))
    : undefined;

  const productCount = data?.pages[0] ? data.pages[0].productCount : undefined;

  const filterObjects = data?.pages[0]
    ? filterFormatter(data?.pages[0].filter, filters)
    : undefined;

  const isReady = !isLoading && products && filterObjects;

  return {
    products,
    productCount,
    filterObjects,
    search,
    sortOrder,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    clearFilters,
    isReady,
  };
};
