export const verifyHexCodeNoAlpha = (rawCode: string) => {
  const hexRegex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
  const poundRegex = /^#/i;

  const code = poundRegex.test(rawCode) ? rawCode : `#${rawCode}`;

  if (code.length !== 4 && code.length !== 7)
    throw new Error(`Code: "${code}" does not have a valid length`);
  if (!hexRegex.test(code))
    throw new Error(`Code: "${code}" is not a valid hex code (without alpha)`);

  return code;
};
