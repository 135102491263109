import config from "@/config";
import useDelayUnmount from "@/lib/hooks/useDelayUnmount";
import useScrollLock from "@/lib/hooks/useScrollLock";

import type { animationDirections } from "../Animation/AnimationWrapper/AnimationWrapper";
import UI from "./UI";

interface ModalProps {
  isOpen: boolean | undefined;
  children: React.ReactNode;
  close: (() => void) | undefined;
  animation?: animationDirections;
}

const Modal = ({ isOpen, children, close, animation }: ModalProps) => {
  useScrollLock(isOpen);
  const animationDuration = config.animationDurationModal * 500;
  const shouldRender = useDelayUnmount(isOpen || false, animationDuration);
  return (
    <UI
      isOpen={isOpen}
      close={close}
      animation={animation}
      shouldRender={shouldRender}
    >
      {children}
    </UI>
  );
};

export default Modal;
