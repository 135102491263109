import { useState } from "react";

export interface IEvent {
  target: {
    value: string;
  };
}

type inputType = "text" | "email" | "password";

const validEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i;

const validateField = (type: string, value: string, required: boolean) => {
  const isValid = () => {
    if (!required) return true;
    switch (type) {
      case "text":
        return value?.length > 0;
      case "email":
        return value?.length > 0 && validEmailRegex.test(value);
      case "password":
        return value?.length > 5;
      default:
        return value?.length > 0;
    }
  };

  const Message = () => {
    switch (type) {
      case "text":
        return "Please enter a value";
      case "email":
        return "Please enter a valid email";
      case "password":
        return "Please enter a valid password";
      default:
        return "Please enter a value";
    }
  };

  return {
    isValid: isValid(),
    message: Message(),
  };
};

const useInput = (
  type: inputType,
  override?: { isValid: boolean; message: string }
) => {
  const [value, setValue] = useState("" as string);
  const [required, setRequired] = useState(false as boolean);

  const onChange = (event: IEvent) => {
    setValue(event.target.value);
  };

  const validation = override || validateField(type, value, required);

  const require = () => {
    if (required === false) {
      setRequired(true);
    }
    return true;
  };

  return {
    setValue,
    isValid: validation.isValid,
    message: validation.message,
    type,
    value,
    onChange,
    clear: () => setValue(""),
    required,
    require,
  };
};
export default useInput;
