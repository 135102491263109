import type { SbBlokData } from "@storyblok/react";

import type { IStoryBlokMedia } from "@/components/StoryblokMedia/StoryblokMedia";
import generateSBStyles from "@/lib/storyblok/generateSBStyles";

import type { RichTextBlok } from "../RichText/RichText";
import styles from "./details.module.scss";
import UI from "./UI";

export interface DetailsBlok extends SbBlokData {
  title: string;
  subtitle: string;
  text: RichTextBlok[];
  media: IStoryBlokMedia[];
  color: {
    color: string;
  };
  flip: boolean;
}

export interface DetailsProps {
  blok: DetailsBlok;
}

const Details = ({ blok }: DetailsProps) => {
  const { getStyles } = generateSBStyles(styles);
  const media = blok.media && blok?.media[0];
  const richText = blok.text && blok?.text[0];
  const { title, subtitle, flip } = blok;
  const orderStyle = !flip
    ? [styles.first, styles.second]
    : [styles.second, styles.first];
  return (
    <UI
      blok={blok}
      getStyles={getStyles}
      orderStyle={orderStyle}
      media={media}
      richText={richText}
      title={title}
      subtitle={subtitle}
    />
  );
};

export default Details;
