import styles from "./sideBySide.module.scss";

export const getDefaultStyle = () => {
  const defaultStyle = [
    styles.firstImage,
    styles.secondImage,
    styles.thirdImage,
    styles.fourthImage,
  ];
  return defaultStyle;
};
