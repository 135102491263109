import { useRouter } from "next/router";
import { useLayoutEffect, useState } from "react";

import useStore from "@/lib/stateManagement/store";

import styles from "./device.module.scss";

type Props = {
  children?: React.ReactNode;
};

const Device: React.FC<Props> = ({ children }) => {
  const { setIsMobile, setIsDesktop, setHasWindow, setPreview, environment } =
    useStore((state) => ({
      setIsMobile: state.setIsMobile,
      setIsDesktop: state.setIsDesktop,
      setHasWindow: state.setHasWindow,
      setPreview: state.setPreview,
      environment: state.environment,
    }));
  const [lastWindowState, setLastWindowState] = useState(false);
  const router = useRouter();

  const useBrowserLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : () => {};

  if (
    typeof window !== "undefined" &&
    (typeof window !== "undefined") !== lastWindowState
  ) {
    setHasWindow(typeof window !== "undefined");
    setLastWindowState(typeof window !== "undefined");
  }

  const maxMobileSize = styles.maxMobilesize || "1024px";
  const mobileSizeMatch = maxMobileSize.match(/^d+/);
  const mobileSizeNumber =
    mobileSizeMatch && mobileSizeMatch[0] ? +mobileSizeMatch[0] : 1024;

  useBrowserLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileSizeNumber);
      setIsDesktop(window.innerWidth > mobileSizeNumber);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useBrowserLayoutEffect(() => {
    if (router.isPreview) {
      setPreview(true);
    }
    if (!router.isPreview) {
      setPreview(false);
    }
  }, [router.isPreview, environment]);

  return <>{children}</>;
};

export default Device;
