import { useInView } from "react-intersection-observer";

import config from "@/config";

import type { animationDirections } from "../AnimationWrapper/AnimationWrapper";
import UI from "./UI";

interface AnimatedProps {
  children: React.ReactNode;
  delay?: number;
  size?: "fit" | "fill";
  direction?: animationDirections;
}

const Animated = ({
  children,
  delay = 0,
  size = "fill",
  direction = "bottom",
}: AnimatedProps) => {
  const observer = useInView(config.observerOptions);
  const duration = config.animationDurationContent;
  delay = delay * config.animationDelay;

  return (
    <UI
      observer={observer}
      duration={duration}
      delay={delay}
      direction={direction}
      size={size}
    >
      {children}
    </UI>
  );
};

export type InViewHook = ReturnType<typeof useInView>;

export default Animated;
