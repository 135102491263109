import styles from "./skeleton.module.scss";

interface UIProps {
  height: string;
}

const UI = ({ height }: UIProps) => {
  return (
    <div
      className={styles.skeleton}
      style={{
        animation: "skeleton-loading 1s linear infinite alternate",
        height,
      }}
    ></div>
  );
};

export default UI;
