import { useDevStore } from "@/lib/stateManagement/devState/devStore";

import styles from "../toolkit.module.scss";

type Props = {};

const OverlayMenu: React.FC<Props> = () => {
  const {
    overlayDisplay,
    overlayDisplayToggle,
    overlayInvert,
    overlayInvertToggle,
    overlayEdge,
    overlayEdgeToggle,
  } = useDevStore();

  return (
    <div className={styles.subMenu}>
      <button
        className={`${styles.toggle} ${overlayDisplay ? styles.active : ""}`}
        onClick={overlayDisplayToggle}
      >
        <p>Display Overlay</p>
        <div className={styles.checkbox}></div>
      </button>
      <button
        className={`${styles.toggle} ${overlayInvert ? styles.active : ""}`}
        onClick={overlayInvertToggle}
      >
        <p>Invert Overlay</p>
        <div className={styles.checkbox}></div>
      </button>
      <button
        className={`${styles.toggle} ${overlayEdge ? styles.active : ""}`}
        onClick={overlayEdgeToggle}
      >
        <p>Edge Overlay</p>
        <div className={styles.checkbox}></div>
      </button>
    </div>
  );
};

export default OverlayMenu;
