import styles from "./overlay.module.scss";

type Props = {
  visible?: boolean;
  invert?: boolean;
  edge?: boolean;
  desktop?: boolean;
  opacity?: number;
};

const Overlay: React.FC<Props> = (props) => {
  const {
    visible = true,
    invert = false,
    edge = true,
    desktop = true,
    opacity = 0.1,
  } = props;

  const desktopColumnCount = +(styles.desktopColumns as string);
  const mobileColumnCount = +(styles.mobileColumns as string);
  const columnCount = desktop ? desktopColumnCount : mobileColumnCount;

  const columns = Array.from({ length: columnCount * 2 - 1 }, (_v, i) => {
    if (!edge) {
      return i % 2 === 0 ? !invert : invert;
    }
    return i % 2 === 0 ? true : false;
  });

  return (
    <div className={styles.container}>
      {visible && (
        <>
          <div
            className={`${styles.outOfBounds} ${edge ? styles.edge : ""}`}
            style={edge ? {} : { opacity }}
          ></div>
          <div className={styles.grid}>
            {columns.map((column, i) => (
              <div
                className={`${styles.column} ${
                  column ? styles.overlay : styles.empty
                } ${edge ? styles.edge : ""}`}
                style={edge ? {} : { opacity }}
                key={`overlay-column-${i}`}
              ></div>
            ))}
          </div>
          <div
            className={`${styles.outOfBounds} ${edge ? styles.edge : ""}`}
            style={edge ? {} : { opacity }}
          ></div>
        </>
      )}
    </div>
  );
};

export default Overlay;
