import useStore from "@/lib/stateManagement/store";

import styles from "../toolkit.module.scss";

type Props = {};

const EnvironmentMenu: React.FC<Props> = () => {
  const { fakeProduction, fakeProductionToggle } = useStore((state) => ({
    fakeProduction: state.fakeProduction,
    fakeProductionToggle: state.fakeProductionToggle,
  }));

  return (
    <div className={styles.subMenu}>
      <button
        className={`${styles.toggle} ${fakeProduction ? styles.active : ""}`}
        onClick={fakeProductionToggle}
      >
        <p>Fake Production Environment</p>
        <div className={styles.checkbox}></div>
      </button>
    </div>
  );
};

export default EnvironmentMenu;
