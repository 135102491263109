import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { IEnvironmentState } from "./slices/environment";
import createEnvironmentSlice from "./slices/environment";
import type { IMediaState } from "./slices/media";
import createMediaSlice from "./slices/media";
import type { IModalState } from "./slices/modal";
// eslint-disable-next-line import/no-cycle
import createModalSlice from "./slices/modal";
// eslint-disable-next-line import/no-cycle
import type { PaymentResultState } from "./slices/paymentResult";
import createPaymentResultSlice from "./slices/paymentResult";
import type { ISelectionState } from "./slices/selection";
import createSelectionSlice from "./slices/selection";
import type { IWishlistState } from "./slices/wishlist";
import createWishlistSlice from "./slices/wishlist";

export type IState = ISelectionState &
  IEnvironmentState &
  IModalState &
  IWishlistState &
  IMediaState &
  PaymentResultState;

const useStore = create<IState>()(
  persist(
    (...a) => ({
      ...createSelectionSlice(...a),
      ...createEnvironmentSlice(...a),
      ...createModalSlice(...a),
      ...createWishlistSlice(...a),
      ...createMediaSlice(...a),
      ...createPaymentResultSlice(...a),
    }),
    {
      name: "persisted-store",
    }
  )
);

export default useStore;
