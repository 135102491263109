import { storyblokEditable } from "@storyblok/react";

import Accordion from "@/components/Accordion";

import type { AccordionBlok } from "./Accordion";
import styles from "./accordionBlok.module.scss";
interface UIProps {
  blok: AccordionBlok;
}

const UI = ({ blok }: UIProps) => {
  return (
    <Accordion title={blok.title} {...storyblokEditable(blok)}>
      <p className={styles.text}>{blok.text}</p>
    </Accordion>
  );
};

export default UI;
