import Link from "next/link";
import { useTranslations } from "next-intl";

import getPriceList from "@/lib/centra/getPriceList";
import useDevice from "@/lib/hooks/useDevice";
import useSelection from "@/lib/hooks/useSelection";
import type { MobileTagType } from "@/lib/storyblok/useTaggedImage";

import Button from "../Button";
import CentraImage from "../CentraImage";
import Modal from "../Modal";
import styles from "./mobileTag.module.scss";

interface UIProps {
  tag?: MobileTagType | null;
  addToCart: (e: unknown) => void;
  addToWishlist: (e: unknown) => void;
  isWishlisted: boolean;
}

const UI = ({ tag, addToCart, addToWishlist, isWishlisted }: UIProps) => {
  const { priceList } = useSelection();
  const { mobile } = useDevice();
  const t = useTranslations();
  if (!tag || !mobile) return null;
  const { product } = tag;
  const info = product?.info;
  const media = product?.media;

  const prices = getPriceList(product?.info?.prices || [], +priceList);

  return (
    <Modal isOpen={tag.selected} close={tag.select}>
      <div className={styles.info}>
        <Button onClick={tag.select} className={styles.closeButton}>
          Close
        </Button>

        <div className={styles.mainWrapper}>
          <Link
            href={`/product/${product?.uri}`}
            className={styles.imageCarousel}
          >
            <div className={styles.tagWrapper}>
              <CentraImage
                src={media?.packShot?.uri}
                alt={media?.packShot?.alt}
              />
            </div>
            <div className={styles.tagWrapper}>
              <CentraImage
                src={media?.environmentShot?.uri}
                alt={media?.environmentShot?.alt}
              />
            </div>
          </Link>
          <div className={styles.infoWrapper}>
            <div className={styles.firstRow}>
              <div className={styles.nameContainer}>
                <p>{info?.name}</p>
                <p className={styles.mainCategory}>{info?.type}</p>
              </div>
              <div className={styles.price}>
                <p>{prices?.price}</p>
                {prices.productOnSale && (
                  <p className={styles.discountPrice}>
                    {prices?.priceBeforeDiscount}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.subContainer}>
              <p className={styles.variantName}>{info?.variantName}</p>
              {!isWishlisted && (
                <button
                  onClick={addToWishlist}
                  className={styles.wishlistButton}
                >
                  {t("addToFavourites")}
                </button>
              )}
            </div>
          </div>
        </div>

        {!!info?.sizes?.[0]?.stock && (
          <Button onClick={addToCart} className={styles.button}>
            {t("addToCart")}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default UI;
