import type { StoryBlokMedia } from "@/types/storyblok";

const getVideoId = (media: StoryBlokMedia) => {
  const videoIdRegex = /vimeo.com\/(\d+)/i;

  const videoIdMatch = media.file.match(videoIdRegex);
  const videoId =
    videoIdMatch && videoIdMatch[1] ? +videoIdMatch[1] : undefined;
  return videoId;
};

export default getVideoId;
