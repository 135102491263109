import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";
import { useTranslations } from "next-intl";

import Animated from "@/components/Animation/Animated";
import EmptyCard from "@/components/EmptyCard/EmptyCard";
import StoryblokMedia from "@/components/StoryblokMedia";
import type { IStoryBlokMedia } from "@/components/StoryblokMedia/StoryblokMedia";

import type { ArticleCardBlok } from "./ArticleCard";
import styles from "./articleCard.module.scss";

interface UIProps {
  blok: ArticleCardBlok;
  link: string;
  newStyles: string;
  media?: IStoryBlokMedia;
  index: number;
}

const UI = ({ blok, link, newStyles, media, index }: UIProps) => {
  const t = useTranslations();
  if (!blok)
    return (
      <div className={styles.container}>
        <EmptyCard className={`${styles.mediaWrapper} `} />
      </div>
    );
  return (
    <Link href={link} legacyBehavior>
      <div
        aria-label=""
        role={"link"}
        className={`${styles.container} ${newStyles}`}
        key={blok?._uid}
        {...storyblokEditable(blok)}
      >
        <Animated delay={index / 2}>
          {media && (
            <div className={`${styles.mediaWrapper} ${newStyles}`}>
              <div className={styles.media}>
                <StoryblokMedia
                  blok={media}
                  sizes="(max-width: 1024px) 50vw, (max-width: 2560px) 33vw"
                />
              </div>
            </div>
          )}
          <h3 className={styles.subHeader}>{blok?.subHeader}</h3>
          <h2 className={styles.header}>{blok?.header}</h2>
          <p className={styles.abstract}>{blok?.abstract}</p>
          <p className={styles.link}>{t("readMore")}</p>
        </Animated>
      </div>
    </Link>
  );
};

export default UI;
