import type { SbBlokData } from "@storyblok/react";

import type { IStoryBlokMedia } from "@/components/StoryblokMedia/StoryblokMedia";
import getIndexStyles from "@/lib/utils/getIndexStyles";
import getNumberStyles from "@/lib/utils/getNumberStyles";

import styles from "./articleCard.module.scss";
import UI from "./UI";
export interface ArticleCardBlok extends SbBlokData {
  portrait: IStoryBlokMedia[];
  landscape: IStoryBlokMedia[];
  subHeader: string;
  header: string;
  abstract: string;
}

export type ArticleStory = {
  content: ArticleCardBlok;
  full_slug: string;
};

interface ArticleCardProps {
  story: ArticleStory;
  index?: number;
  parentLength?: number;
}

import type { IStoryblokImage } from "@/types/storyblok";

const ArticleCard = ({
  story,
  index = 0,
  parentLength = 0,
}: ArticleCardProps) => {
  const link = story?.full_slug;
  const indexStyles = getIndexStyles(index, styles);
  const numberStyles = getNumberStyles(parentLength, styles);
  const newStyles = `${numberStyles} ${indexStyles}`;

  const media =
    parentLength === 2 &&
    (!!story?.content?.landscape?.[0]
      ?.desktopMedia as unknown as IStoryblokImage)
      ? story?.content?.landscape?.[0]
      : story?.content?.portrait?.[0];

  return (
    <UI
      blok={story.content}
      link={link}
      newStyles={newStyles}
      media={media}
      index={index}
    />
  );
};

export default ArticleCard;
