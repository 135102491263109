import config from "@/config";

import { getSingleMediaBySize } from "../atomics/media";
import type { ICentraProductNoRelations } from "./productPage";

const { productCardImageSize } = config;

export const getPackShot = (product: ICentraProductNoRelations) => {
  return getSingleMediaBySize(product, productCardImageSize, 0);
};

export const getEnvironmentShot = (product: ICentraProductNoRelations) => {
  return getSingleMediaBySize(product, productCardImageSize, 1);
};
// export const getEnvironmentShot = (product: ICentraProductNoRelations) => {
// const packShotCount = getPackshotCount(product);
// let errors: string[] = [];
// try {
//   const environmentShot = getSingleMediaBySize(
//     product,
//     productCardImageSize,
//     packShotCount
//   );
//   if (environmentShot) return environmentShot;
//   throw new Error();
// } catch (e) {
//   errors.push(`Image at index: ${packShotCount} does not exist`);
// }
// try {
//   const environmentShot = getSingleMediaBySize(
//     product,
//     productCardImageSize,
//     1
//   );
//   if (environmentShot) return environmentShot;
// } catch (e) {
//   errors.push("Image at index: 1 does not exist");
// }

// throw new Error(errors.join(", "));
// };
